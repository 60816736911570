export const ADDEDIT_SERVICEACCOUNT_BEGIN = "ADDEDIT_SERVICEACCOUNT_BEGIN";
export const ADDEDIT_SERVICEACCOUNT_SUCCESS = "ADDEDIT_SERVICEACCOUNT_SUCCESS";
export const ADDEDIT_SERVICEACCOUNT_FAIL = "ADDEDIT_SERVICEACCOUNT_FAIL";

export const LOAD_SERVICEACCOUNT_BEGIN = "LOAD_SERVICEACCOUNT_BEGIN";
export const LOAD_SERVICEACCOUNT_SUCCESS = "LOAD_SERVICEACCOUNT_SUCCESS";
export const LOAD_SERVICEACCOUNT_FAIL = "LOAD_SERVICEACCOUNT_FAIL";

export const DELETE_SERVICEACCOUNT_BEGIN = "DELETE_SERVICEACCOUNT_BEGIN";
export const DELETE_SERVICEACCOUNT_SUCCESS = "DELETE_SERVICEACCOUNT_SUCCESS";
export const DELETE_SERVICEACCOUNT_FAIL = "DELETE_SERVICEACCOUNT_FAIL";

export const CREATE_SASTOKEN_BEGIN = "CREATE_SASTOKEN_BEGIN";
export const CREATE_SASTOKEN_SUCCESS = "CREATE_SASTOKEN_SUCCESS";
export const CREATE_SASTOKEN_FAIL = "CREATE_SASTOKEN_FAIL";

