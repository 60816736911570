export const UPLOAD_DATASOURCE_COMMUNITY_START = "UPLOAD_DATASOURCE_COMMUNITY_START";
export const UPLOAD_DATASOURCE_COMMUNITY_SUCCESS = "UPLOAD_DATASOURCE_COMMUNITY_SUCCESS";
export const UPLOAD_DATASOURCE_COMMUNITY_FAIL = "UPLOAD_DATASOURCE_COMMUNITY_FAIL";

export const UPLOAD_DASHBOARD_COMMUNITY_START = "UPLOAD_DASHBOARD_COMMUNITY_START";
export const UPLOAD_DASHBOARD_COMMUNITY_SUCCESS = "UPLOAD_DASHBOARD_COMMUNITY_SUCCESS";
export const UPLOAD_DASHBOARD_COMMUNITY_FAIL = "UPLOAD_DASHBOARD_COMMUNITY_FAIL";

export const UPLOAD_FIELDS_COMMUNITY_START = "UPLOAD_FIELDS_COMMUNITY_START";
export const UPLOAD_FIELDS_COMMUNITY_SUCCESS = "UPLOAD_FIELDS_COMMUNITY_SUCCESS";
export const UPLOAD_FIELDS_COMMUNITY_FAIL = "UPLOAD_FIELDS_COMMUNITY_FAIL";