export const FETCH_DASHBOARDS_BEGIN = "FETCH_DASHBOARDS_BEGIN";
export const FETCH_DASHBOARDS_SUCCESS = "FETCH_DASHBOARDS_SUCCESS";
export const FETCH_DASHBOARDS_FAIL = "FETCH_DASHBOARDS_FAIL";
export const DASHBOARD_SELECTION_CHANGED = "DASHBOARD_SELECTION_CHANGED";

export const FETCH_DASHBOARD_LINK_BEGIN = "FETCH_DASHBOARD_LINK_BEGIN";
export const FETCH_DASHBOARD_LINK_SUCCESS = "FETCH_DASHBOARD_LINK_SUCCESS";
export const FETCH_DASHBOARD_LINK_FAIL = "FETCH_DASHBOARD_LINK_FAIL";

export const CLEAR_ALL_DASHBOARDSELECTIONDATA = "CLEAR_ALL_DASHBOARDSELECTIONDATA";

export const CREATE_DASHBOARD_LINK_BEGIN = "CREATE_DASHBOARD_LINK_BEGIN";
export const CREATE_DASHBOARD_LINK_SUCCESS = "CREATE_DASHBOARD_LINK_SUCCESS";
export const CREATE_DASHBOARD_LINK_FAIL = "CREATE_DASHBOARD_LINK_FAIL";