import React from "react";
import { connect } from "react-redux";
import { Column } from "devextreme-react/data-grid";
import PromineoGrid from "../Common/Controls/PromineoGridComponent";
import * as actions from "../../store/actions/communityDataSourceActions";

class CommunityDataSourceGrid extends React.Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.community &&
      (!prevProps.community ||
        this.props.community.id !==
          prevProps.community.id)
    ) {
      this.props.onLoadCommunityDataSources(this.props.community);
    }
  }

  handleSelectionChanged = selectedData => {
    if (selectedData && selectedData.selectedRowsData) {
      this.props.handleDataSourceSelectionChanged(selectedData.selectedRowsData);
    }
  };

  render() {
    const { dataSources } = this.props;
    return (
      <PromineoGrid
        allowMultiSelect={true}
        keyExpression={"id"}
        enablePaging={true}
        dataSource={dataSources}
        displayFilterRow={true}
        onSelectionChanged={this.handleSelectionChanged}
      >
        <Column dataField={"name"} width={"30%"}/>
        <Column dataField={"description"} width={"70%"} />
      </PromineoGrid>
    );
  }
}

const mapStateToProps = state => {
  return {
    dataSources: state.communityData.availableDataSources
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLoadCommunityDataSources: community =>
      dispatch(actions.loadCommunityDataSources(community)),
    handleDataSourceSelectionChanged: selectedDataSources =>
      dispatch(actions.dataSourceSelectionChanged(selectedDataSources))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunityDataSourceGrid);
