import { toast } from "react-toastify";

export const toastSuccess = message => {
  toast.success(message);
};

export const toastError = message => {
    toast.error(message);
};

export const toastException = (message, error) => {
    const errorMessage = error.data
    ? error.data.ExceptionMessage
      ? error.data.ExceptionMessage
      : ""
    : "";
    toast.error(message + errorMessage);
};