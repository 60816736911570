import React from "react";
import { connect } from "react-redux";
import { Button } from "devextreme-react/button";
import * as communityActions from "../../store/actions/communityActions";
import * as datasourceActions from "../../store/actions/datasourceActions";
import * as communityUploadActions from "../../store/actions/communityUploadActions";
import {
  PromineoSelectBox,
  PromineoGrid
} from "../Common/Controls/ControlList";
import { Column } from "devextreme-react/data-grid";

class CommunityDataSourceUpload extends React.Component {
 constructor(props){
   super(props);

   this.state = {
    selectedCommunity: null
   }
 }

  componentDidMount() {
    this.props.onLoadCommunities();
    this.props.onLoadAllDataSources();
  }

  onInputValueChanged = (controlName, value) => {
    this.setState({
      [controlName]: value
    });
  };

  handleSelectionChanged = selectedData => {
    if (selectedData && selectedData.selectedRowKeys.length > 0) {
      this.setState({
        selectedDataSourceId: selectedData.selectedRowKeys[0]
      });
    }
  };

  uploadDataSource = (e, dataSource) => {
    e.event.stopPropagation();

    this.props.onUploadDataSource(this.state.selectedCommunity, dataSource);
  };

  cellRenderUpload = data => {
    return (
      <Button
        key={data.value}
        icon="upload"
        onClick={e => this.uploadDataSource(e, data.data)}
      />
    );
  };

  render() {
    const { communities, dataSources } = this.props;
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <PromineoSelectBox
              dataSource={communities}
              id="selectedCommunity"
              label="Select Community"
              displayExpr="name"
              valueExpr="id"
              onValueChanged={this.onInputValueChanged}
            />
          </div>
        </div>
        <div className="row">
          <div className="">
            <PromineoGrid
              allowMultiSelect={false}
              enablePaging={true}
              defaultPageSize={15}
              dataSource={dataSources}
              displayFilterRow={true}
              onSelectionChanged={this.handleSelectionChanged}
            >
              <Column dataField={"Name"} width={"30%"} sortOrder={"asc"}/>
              <Column dataField={"Description"} width={"70%"} />
              <Column
                dataField={"PrimKey"}
                caption={""}
                width={100}
                alignment={"center"}
                allowSorting={false}
                allowFiltering={false}
                cellRender={this.cellRenderUpload}
              />
            </PromineoGrid>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    communities: state.communityData.communities,
    dataSources: state.dashboardData.dataSources
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLoadCommunities: () => dispatch(communityActions.loadCommunities()),
    onLoadAllDataSources: () => dispatch(datasourceActions.loadAllDataSources()),
    onUploadDataSource: (communityId, dataSource) => dispatch(communityUploadActions.uploadDataSourceToCommunity(communityId, dataSource))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunityDataSourceUpload);
