import React, { useState } from "react";
import PromineoPopUp from "../Common/Controls/PromineoPopUpComponent";
import PromineoSelectBox from "./../Common/Controls/PromineoSelectBoxComponent";

export default function AuthenticationProviderPopup(props) {
  const { providers, isVisible, onOkClick, onCloseClick } = props;
  const [selectedProvider, setSelectedProvider] = useState();

  const handleProviderSelectionChange = (controlName, value) => {
    const selectedProvider = (providers || []).find(p => p.id === value);  
    setSelectedProvider(selectedProvider);
  };

  const handleOkClick = () => {
    if (onOkClick && selectedProvider) {
      onOkClick(selectedProvider);
    }
  };

  return (
    <PromineoPopUp
      visible={isVisible}
      title={"Select Authentication Provider"}
      width={300}
      height={180}
      handleOkClick={handleOkClick}
      handleCancelClick={onCloseClick}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col">
            <PromineoSelectBox
              dataSource={providers}
              id="providers"
              label="Provider"
              displayExpr="name"
              valueExpr="id"
              onValueChanged={handleProviderSelectionChange}
              defaultValue={(selectedProvider && selectedProvider.id) ? selectedProvider.id : ""}
            />
          </div>
        </div>
      </div>
    </PromineoPopUp>
  );
}
