import { updateObject } from "../../shared/Utility/commonUtility";
import * as dashboardActionTypes from "../actions/ActionType/dashboardActionTypes";
import * as datasourceActionTypes from "../actions/ActionType/datasourceActionTypes";

const initialState = {
  loading: false,
  error: null,
  dashboards: [],
  dataSources: [],
  selectedDashboard: null,
  selectedDatasourceId: null,
  dataSource: [],
  filters: [],
  filterItem: [],
  parameter: [],
  selectedFilterId: null,
  filterParameterDefinition: [],
  filterParameters: {},
  dashboardLinkFilterParameters: {},
  loadingLinkInfoDone: false,
  lookUpDefinition:{}
};

const loadDashboardsBegin = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const loadDashboardsSuccess = (state, action) => {
  return updateObject(state, { loading: false, dashboards: action.dashboards });
};

const loadDashboardsFail = (state, action) => {
  console.log(action.error);
  return updateObject(state, { error: action.error, loading: false });
};

const dashboardSelected = (state, action) => {
  return updateObject(state, {
    selectedDashboard: action.selectedDashboard,
    selectedDatasourceId: action.selectedDashboard
      ? action.selectedDashboard.DataSourceRef
      : null
  });
};


const loadAllDatasourcesBegan = state => {
  return updateObject(state, { error: null, loading: true });
};

const loadAllDatasourcesSucceeded = (state, action) => {
  return updateObject(state, {
    loading: false,
    dataSources: action.dataSources
  });
};

const loadAllDatasourcesFailed = (state, action) => {
  console.log(action.error);
  return updateObject(state, { error: action.error, loading: false });
};

///

const loadDatasourceBegan = state => {
  return updateObject(state, { error: null, loading: true });
};

const loadDatasourceSucceeded = (state, action) => {
  return updateObject(state, {
    loading: false,
    dataSource: action.data[0].$values,
    filters: action.data[1].$values,
    filterItem: action.data[2].$values,
    parameter: action.data[3].$values
  });
};

const loadDatasourceFailed = (state, action) => {
  console.log(action.error);
  return updateObject(state, { error: action.error, loading: false });
};

const filterSelected = (state, action) => {
  let letUpdatedSelectedFilterId = null;
  if (action.selectedFilter) {
    letUpdatedSelectedFilterId = action.selectedFilter.PrimKey;
  }

  return updateObject(state, {
    selectedFilterId: letUpdatedSelectedFilterId,
    filterParameterDefinition: []
  });
};

const loadFilterParameterDefinitionBegan = state => {
  return updateObject(state, { error: null, loading: true });
};

const loadFilterParameterDefinitionSucceeded = (state, action) => {
  return updateObject(state, {
    loading: false,
    filterParameterDefinition: action.data
  });
};

const loadFilterParameterDefinitionFailed = (state, action) => {
  console.log(action.error);
  return updateObject(state, { error: action.error, loading: false });
};

const clearFilterParameterData = (state, action) => {
  return updateObject(state, {
    filterParameterDefinition: [],
    dataSource: [],
    filters: [],
    filterItem: [],
    parameter: [],
    filterParameters: {},
    selectedFilterId: null,
    lookUpDefinition:{}
  });
};

const clearParameterData = (state, action) => {
  return updateObject(state, {
    filterParameterDefinition: [],
    filterParameters: {},
    selectedFilterId: null,
  });
};

const clearDashboardSelectionData = state => {
  return updateObject(state, {
    filterParameterDefinition: [],
    filterParameters: {},
    dataSource: [],
    filters: [],
    filterItem: [],
    parameter: [],
    selectedFilterId: null,
    selectedDashboard: null,
    selectedDatasourceId: null,
    lookUpDefinition:{}
  });
};

const updateParameter = (state, action) => {
  return updateObject(state, { filterParameters: {
    ...state.filterParameters,
    [action.id] : action.value
  }});
};

const loadDashboardLinkBegan = state => {
  return updateObject(state, {
    error: null,
    loading: true,
    loadingLinkInfoDone: false
  });
};

const loadDashboardLinkSucceeded = (state, action) => {
  const filterParameters = action.dashboardLink.FilterParamValues
  ? JSON.parse(action.dashboardLink.FilterParamValues)
  : {};

  // dashboardLinkFilterParameters is used to display selected field values(selected values while creating dashboard link) 
  // while openning dashboard from link
  return updateObject(state, {
    loading: false,
    loadingLinkInfoDone: true,
    selectedDashboard: action.dashboard,
    selectedFilterId: action.dashboardLink.FilterRef,
    dashboardLinkFilterParameters: filterParameters,
    filterParameters: filterParameters
  });
};

const loadDashboardLinkFailed = (state, action) => {
  console.log(action.error);
  return updateObject(state, { error: action.error, loading: false });
};

const loadFilterLookUpDefinitionBegan = state => {
  return updateObject(state, { error: null, loading: true });
};

const loadFilterLookUpDefinitionSucceeded = (state, action) => {
  return updateObject(state, {
      lookUpDefinition: {
          ...state.lookUpDefinition,
          [action.data.columnRef]: action.data
      },
      loading: false
  });
};

const loadFilterLookUpDefinitionFailed = (state, action) => {
  console.log(action.error);
  return updateObject(state, { error: action.error, loading: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case dashboardActionTypes.FETCH_DASHBOARDS_BEGIN:
      return loadDashboardsBegin(state, action);
    case dashboardActionTypes.FETCH_DASHBOARDS_SUCCESS:
      return loadDashboardsSuccess(state, action);
    case dashboardActionTypes.FETCH_DASHBOARDS_FAIL:
      return loadDashboardsFail(state, action);
    case dashboardActionTypes.DASHBOARD_SELECTION_CHANGED:
      return dashboardSelected(state, action);
    case dashboardActionTypes.FETCH_DASHBOARD_LINK_BEGIN:
      return loadDashboardLinkBegan(state, action);
    case dashboardActionTypes.FETCH_DASHBOARD_LINK_SUCCESS:
      return loadDashboardLinkSucceeded(state, action);
    case dashboardActionTypes.FETCH_DASHBOARD_LINK_FAIL:
      return loadDashboardLinkFailed(state, action);
    case dashboardActionTypes.CLEAR_ALL_DASHBOARDSELECTIONDATA:
      return clearDashboardSelectionData(state, action);
    case datasourceActionTypes.FETCH_DATASOURCE_BEGIN:
      return loadDatasourceBegan(state, action);
    case datasourceActionTypes.FETCH_DATASOURCE_SUCCESS:
      return loadDatasourceSucceeded(state, action);
    case datasourceActionTypes.FETCH_DATASOURCE_FAIL:
      return loadDatasourceFailed(state, action);
      case datasourceActionTypes.FETCH_ALL_DATASOURCE_BEGIN:
      return loadAllDatasourcesBegan(state, action);
    case datasourceActionTypes.FETCH_ALL_DATASOURCE_SUCCESS:
      return loadAllDatasourcesSucceeded(state, action);
    case datasourceActionTypes.FETCH_ALL_DATASOURCE_FAIL:
      return loadAllDatasourcesFailed(state, action);
    case datasourceActionTypes.FILTER_SELECTION_CHANGE:
      return filterSelected(state, action);
    case datasourceActionTypes.CLEAR_PARAMETERDATA:
        return clearParameterData(state, action);
    case datasourceActionTypes.FETCH_FILTERPARAMETERDEFINITION_BEGIN:
      return loadFilterParameterDefinitionBegan(state, action);
    case datasourceActionTypes.FETCH_FILTERPARAMETERDEFINITION_SUCCESS:
      return loadFilterParameterDefinitionSucceeded(state, action);
    case datasourceActionTypes.FETCH_FILTERPARAMETERDEFINITION_FAIL:
      return loadFilterParameterDefinitionFailed(state, action);
    case datasourceActionTypes.CLEAR_FILTERPARAMETERDATA:
      return clearFilterParameterData(state, action);
    case datasourceActionTypes.UPDATE_PARAMETER:
      return updateParameter(state, action);
    case datasourceActionTypes.FETCH_FILTERLOOKUPDEFINITION_BEGIN:
      return loadFilterLookUpDefinitionBegan(state, action);
    case datasourceActionTypes.FETCH_FILTERLOOKUPDEFINITION_SUCCESS:
      return loadFilterLookUpDefinitionSucceeded(state, action);
    case datasourceActionTypes.FETCH_FILTERLOOKUPDEFINITION_FAIL:
      return loadFilterLookUpDefinitionFailed(state, action);

    default:
      return state;
  }
};

export default reducer;
