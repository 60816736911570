import React from "react";
import { Link as RouterLink } from "react-router-dom";

export default function Link(props) {
  const { className, ignoreDefaultStyle, ...rest } = props;

  const getClassNames = () => {
    const defaultClasses =
      "text-sm font-semibold underline font-inter active:text-primary-red hover:text-gray-2";

    if (className && ignoreDefaultStyle) {
      return className;
    }

    if (className) {
      return `${defaultClasses} ${className}`;
    }

    return defaultClasses;
  };

  return <RouterLink {...rest} className={getClassNames()} />;
}

Link.defaultProps = {
  ignoreDefaultStyle: false,
};
