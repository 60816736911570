import * as request from "../../shared/request";

export const saveData = data => {
  return (dispatch, getState) => {
    const state = getState();

    const saveRequest = request.apiRequest(
      {
        url: "/save",
        method: "POST",
        data
      },
      state.authData
    );

    if (saveRequest) {
      return saveRequest
        .then(response => {
          return response;
        })
        .catch(error => {
          throw error;
        });
    }
  };
};
