import React from "react";
import { connect } from "react-redux";
import CommunityList from "./CommunityListComponent";
import CommunityDashboardsGrid from "./CommunityDashboardGridComponent";
import PeriodizerInfoPopUp from "./PeriodizerInfoPopUpComponent";
import { Button } from "devextreme-react/button";
import * as actions from "../../store/actions/communityDashboardActions";

class CommunityDashboardSettings extends React.Component {
  onDownloadClicked = () => {
    this.props.downloadCommunityDashboards();
  };

  handleCommunitySelection = community => {
    this.props.onCommunitySelectionChanged(community);
  };

  onPeriodizerInfoSelected = continueDownload => {
    this.props.periodizerSettingDisplayConfigChanged(false);
    if (continueDownload) {
      this.props.forceDownloadDashboardAndDataSource(
        this.props.tempDashboardDetail
      );
    }
  };

  render() {
    const {
      selectedCommunity,
      dashboardPeriodizerInfoModalDisplay
    } = this.props;
    return (
      <div className="container pt-3">
        <div className="row">
          <div className="col-sm-3">
            <CommunityList onSelectionChanged={this.handleCommunitySelection} />
          </div>
          <div className="col-sm-9">
            <CommunityDashboardsGrid community={selectedCommunity} />
          </div>
        </div>
        <div className="row pt-3 pb-3">
          <div className="col text-center">
            <Button
              className="text-center"
              text="Download Selected Fields"
              type="default"
              onClick={this.onDownloadClicked}
            />
          </div>
        </div>
        <PeriodizerInfoPopUp
          visible={dashboardPeriodizerInfoModalDisplay}
          handlePeriodizerInfoSelected={this.onPeriodizerInfoSelected}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    selectedCommunity: state.communityData.selectedCommunityForDashboard,
    dashboardPeriodizerInfoModalDisplay:
      state.communityData.dashboardPeriodizerInfoModalDisplay,
    tempDashboardDetail: state.communityData.tempDashboardDetail
  };
};

const mapDispatchToProps = dispatch => {
  return {
    downloadCommunityDashboards: () =>
      dispatch(actions.downloadCommunityDashboards()),
    onCommunitySelectionChanged: data => {
      dispatch(actions.communitySelectionChanged(data));
    },
    forceDownloadDashboardAndDataSource: data =>
      dispatch(actions.forceDownloadDashboardAndDataSource(data)),
    periodizerSettingDisplayConfigChanged: display =>
      dispatch(actions.periodizerSettingDisplayConfigChanged(display))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunityDashboardSettings);
