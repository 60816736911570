import React from "react";
import Badge from "../Common/BadgeComponent";

const TypeBadge = props => {
  return (
    <Badge
      type={props.text === "Public" ? "primary" : "info"}
      text={props.text}
    />
  );
};

export default TypeBadge;
