import * as request from "../../shared/request";
import { toastError, toastSuccess } from "../../shared/Utility/toastUtility";
import { isSuccessApiResponse } from "../../shared/Utility/dataUtility";
import { getLatestUserAuthenticationByEmail } from "./portalAuthenticationActions";
import { createSecurityUser } from "./userActions";
import { GetNewId } from "../../shared/Utility/uidUtility";
import * as actionTypes from "./ActionType/portalTenantActionTypes";
import { getApiCompatibleModelType } from "../../shared/Utility/dataUtility";

export const createPortalAndTenantUserFromEmail = email => {
  return (dispatch, getState) => {
    dispatch(createTenantUserBegin());
    const state = getState();
    if (email) {
      dispatch(getLatestUserAuthenticationByEmail(email))
        .then(authenticationData => {
          if (authenticationData) {
            const userData = {
              Id: GetNewId(),
              TenantId: state.authData.selectedTenant.TenantId,
              UserName: authenticationData.preferredUserName,
              NameIdentifier: authenticationData.nameIdentifier,
              AdminLevel: 2
            };
            dispatch(createTenantUser(userData)).then(portalUser => {
              if (portalUser) {
                const user = [
                  {
                    $type: getApiCompatibleModelType("Security", "User"),
                    RecordState: 2,
                    PrimKey: GetNewId(),
                    Login: email,
                    Email: email
                  }
                ];
                dispatch(createSecurityUser(user));
              }
            });
          } else {
            dispatch(createTenantUserFail(null));
          }
        })
        .catch(error => {
          dispatch(createTenantUserFail(error));
        });
    } else {
      toastError("Invalid E-mail address");
    }
  };
};

export const createTenantUser = user => {
  return (dispatch, getState) => {
    dispatch(createTenantUserBegin());
    const state = getState();
    const createTenantUserRequest = request.portalRequest(
      {
        url: "/TenantUsers",
        method: "POST",
        data: user
      },
      state.authData
    );

    if (createTenantUserRequest) {
      return createTenantUserRequest
        .then(response => {
          if (isSuccessApiResponse(response)) {
            dispatch(createTenantUserSuccess());
            return response.data;
          }
        })
        .catch(error => {
          dispatch(createTenantUserFail(error));
        });
    }
  };
};

const createTenantUserBegin = () => {
  return {
    type: actionTypes.CREATE_TENANT_USER_BEGIN
  };
};

const createTenantUserSuccess = () => {
  toastSuccess("Successfully create user in portal.")
  return {
    type: actionTypes.CREATE_TENANT_USER_SUCCESS
  };
};

const createTenantUserFail = error => {
  if (error) {
    console.error(error);
    toastError("Failed to create user in portal. Error : ", error);
  }

  return {
    type: actionTypes.CREATE_TENANT_USER_FAIL,
    error
  };
};
