import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import ContinuousLoadingComponent from "../Common/ContinuousLoadingCompoment";
import AuthService from "../../services/auth.service";
import {
  getRedirectionUrlPathOnTenantSelection,
  checkIfUserHasValidPromineoToken,
} from "../../shared/Utility/commonUtility";
import { toastError } from "../../shared/Utility/toastUtility";
import * as actions from "../../store/actions";
import AuthenticationProviderPopup from "./AuthenticationProviderPopupComponent";
import { AuthProvider, AuthContext } from "./OidcAuthContext";
import {
  saveSelectedAuthenticationProvider,
  getSavedAuthenticationProvider,
  authenticateUsingOidc,
} from "../../shared/Utility/authUtility";
import {
  silentAuthenticationFinish,
  logOutFinish,
} from "../../store/actions/authActions";
import PromineoButton from "../Common/Controls/PromineoButton";

class AuthComponent extends Component {
  constructor(props) {
    super(props);
    this.authService = new AuthService();
    this.handleSilentSignIn = this.handleSilentSignIn.bind(this);
    this.state = {
      isAuthenticationProviderSelectorVisible: false,
      aunthenticateUsingOidc: false,
      oidcConfig: {
        onSignIn: this.handleOidcAuthenticationSuccess,
        onSilentSignIn: this.handleSilentSignIn,
        silentSignInErrorCallback: this.handleSigleSignInError,
        onSignOut: this.props.finishLogout,
        autoSignIn: true,
        redirectUri: window.location.origin,
        post_logout_redirect_uri: window.location.origin,
        loadUserInfo: false,
        automaticSilentRenew: true,
      },
      providerConfig: this.props.savedSelectedProvider,
    };
  }

  checkIfSameOidcConfig(config1, config2) {
    const isSame =
      config1 === config2 ||
      (config1 &&
        config2 &&
        config1.authority === config2.authority &&
        config1.clientId === config2.clientId &&
        config1.scope === config2.scope);
    return isSame;
  }

  componentDidUpdate(props) {
    if (
      props.savedSelectedProvider &&
      !this.checkIfSameOidcConfig(
        props.savedSelectedProvider,
        this.state.providerConfig
      )
    ) {
      this.setProviderConfig(props.savedSelectedProvider);
    }
  }

  handleSilentSignIn = (userData) => {
    this.props.finishSilentAuthentication();
    this.handleOidcAuthenticationSuccess(userData);
  };

  handleSigleSignInError = () => {
    this.props.finishSilentAuthentication();
  };

  handleOidcAuthenticationSuccess = (userData) => {
    authenticateUsingOidc(true);
    const idToken = userData.id_token;
    this.handleAuthenticationSuccess(idToken);
    this.setState({
      ...this.state,
      aunthenticateUsingOidc: false,
    });
  };

  setProviderConfig = (config) => {
    this.setState({
      ...this.state,
      providerConfig: config,
    });
  };

  updateAuthenticationProviderPopupState = (isVisible) => {
    this.setState({
      ...AuthComponent.state,
      isAuthenticationProviderSelectorVisible: isVisible,
    });
  };

  loadProviders = () => {
    this.props.onLoadAuthenticationProviders();
  };

  loginWithOIdc = () => {
    if (this.props.savedSelectedProvider) {
      this.setState({
        ...this.state,
        aunthenticateUsingOidc: true,
      });
    } else {
      this.loadProviders();
      this.updateAuthenticationProviderPopupState(true);
    }
  };

  handleSelectedAuthenticationProviderChange = (provider) => {
    saveSelectedAuthenticationProvider(provider);
    this.setProviderConfig(provider);
    this.updateAuthenticationProviderPopupState(false);
    this.setState({
      ...this.state,
      aunthenticateUsingOidc: true,
    });
  };

  handleCancelAuthenticationProviderSelectionClick = () => {
    this.updateAuthenticationProviderPopupState(false);
  };

  handleAuthenticationSuccess = (idToken) => {
    let previouslySelectedTenantId = localStorage.getItem("tenantId");
    this.props.onAuthenticateToPromineoPortal(
      idToken,
      previouslySelectedTenantId
    );
    this.props.onsetAuthRedirectPath(this.props.redirectOnAuthenticate);
  };

  login = () => {
    this.authService.login().then(
      (idT) => {
        if (idT) {
          this.handleAuthenticationSuccess(idT);
        } else {
          this.props.onAuthenticationFailed(
            new Error("Failed to authenticate with Microsoft")
          );
        }
      },
      (error) => {
        console.error(error);
        toastError("Error occured while login - ", error);
      }
    );
  };

  render() {
    if (this.props.hasValidPromineoToken) {
      if (this.props.selectedTenant) {
        // user is logged in and previously selected tenant is found
        return <Redirect to={this.props.redirectOnAuthenticate} />;
      } else {
        // ask user to select tenant
        return <Redirect to={"/tenants"} />;
      }
    } else if (this.props.isLoginLoading) {
      return <ContinuousLoadingComponent />;
    } else {
      return (
        <div className="flex justify-center sm:justify-start gap-y-4 flex-wrap sm:flex-nowrap">
          <PromineoButton
            className="mr-2"
            onClick={this.loginWithOIdc}
            radiusSize={"Medium"}
          >
            Login with{" "}
            {this.props.savedSelectedProvider
              ? this.props.savedSelectedProvider.name
              : this.state.providerConfig ? this.state.providerConfig.name
              : "OIDC"}
          </PromineoButton>
          <PromineoButton onClick={this.login} radiusSize={"Medium"}>
            Login with Microsoft
          </PromineoButton>
          <AuthenticationProviderPopup
            providers={this.props.authenticationProviders}
            isVisible={this.state.isAuthenticationProviderSelectorVisible}
            onOkClick={this.handleSelectedAuthenticationProviderChange}
            onCloseClick={this.handleCancelAuthenticationProviderSelectionClick}
          />

          {this.state.providerConfig && (
            <AuthProvider
              {...this.state.oidcConfig}
              {...this.state.providerConfig}
            >
              <AuthContext.Consumer>
                {(context) => {
                  if (this.state.aunthenticateUsingOidc) {
                    context.signInPopup();
                  } else if (this.props.silentAuthenticate) {
                    context.silentSignIn();
                  } else if (this.props.loggingOut) {
                    context.signOut();
                  }
                  return <></>;
                }}
              </AuthContext.Consumer>
            </AuthProvider>
          )}
        </div>
      );
    }
  }
}

const mapStateToProps = (state, props) => {
  return {
    hasValidPromineoToken: checkIfUserHasValidPromineoToken(state.authData),
    selectedTenant: state.authData.selectedTenant,
    isLoginLoading: state.authData.loading,
    redirectOnAuthenticate: getRedirectionUrlPathOnTenantSelection(props),
    authenticationProviders: state.authData.authenticationProviders,
    savedSelectedProvider: getSavedAuthenticationProvider(),
    silentAuthenticate: state.authData.silentAuthenticate,
    loggingOut: state.authData.loggingOut,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuthenticateToPromineoPortal: (token, tenantId) =>
      dispatch(actions.authenticateToPromineoPortal(token, tenantId)),
    onsetAuthRedirectPath: (path) =>
      dispatch(actions.setAuthRedirectPath(path)),
    onAuthenticationFailed: (error) => dispatch(actions.authFail(error)),
    onLoadAuthenticationProviders: () => {
      dispatch(actions.loadAuthenticationProviders());
    },
    finishSilentAuthentication: () => {
      dispatch(silentAuthenticationFinish());
    },
    finishLogout: () => {
      authenticateUsingOidc(false);
      dispatch(logOutFinish());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthComponent);
