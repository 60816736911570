import React from "react";
import ReactMarkdown from "react-markdown";
import Moment from "react-moment";
import WikiTagsInBadge from "./WikiTagsBadgeComponent";
import TypeBadge from "./TypeBadgeComponent";
import * as utility from "../../shared/Utility/commonUtility";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { logActivity } from "../../store/actions/activityLogActions";
import { activityTypes } from "../../shared/Enums/activityTypes";

class WikiViewer extends React.Component {
  copyLinkToClipboard(articleId) {
    let wikiUrl = window.location.origin + "/wiki/" + articleId;
    utility.copyTextToClipboard(wikiUrl);

    toast.success("Wiki link copied to clipboard.");
  }

  componentDidMount() {
    const wiki = this.props.wiki
      ? this.props.wiki
      : this.props.history.location.state.wiki;
    this.props.logActivity(activityTypes.WikiArticleOpened,`WikiId:${wiki.ArticleRef},Title:${wiki.Title}`);
  }

  render() {
    const wiki = this.props.wiki
      ? this.props.wiki
      : this.props.history.location.state.wiki;

    return (
      <div className="container">
        <div className="row border-bottom">
          <div className="col">
            <div>
              <button
                className="btn btn-primary float-right mt-2"
                title="Copy wiki link to clipboard"
                onClick={() => this.copyLinkToClipboard(wiki.ArticleRef)}
              >
                Copy Link
              </button>
              <blockquote className="blockquote text-center">
                <h2>{wiki.Title}</h2>
              </blockquote>
            </div>
            <div className="row">
              <div className="col">
                <WikiTagsInBadge Tags={wiki.ArticleTags} />
              </div>
              <div className="col">
                <blockquote className="blockquote text-right">
                  <footer className="blockquote-footer">
                    Author: {wiki.CreatedBy}{" "}
                    <TypeBadge text={wiki.ArticleType} />
                  </footer>
                  <footer className="blockquote-footer">
                    Last updated on{" "}
                    <Moment format="LLLL">{wiki.CreatedOn}</Moment>
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <ReactMarkdown source={wiki.Markdown} escapeHtml={false} />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logActivity: (action,details) => {
      dispatch(logActivity(action,details));
    }
  }
}

export default connect(
  null,
  mapDispatchToProps
)(WikiViewer);
