import * as request from "../../shared/request";
import { toastError } from "../../shared/Utility/toastUtility";
import { isSuccessApiResponse } from "../../shared/Utility/dataUtility";

// Get latest user authentication data by Email
export const getLatestUserAuthenticationByEmail = email => {
  return (dispatch, getState) => {
    const state = getState();
    if (email) {
      const latestUserAuthenticationRequest = request.portalRequest(
        {
          url: `/Authentications/LatestByEmail/${email}`,
          method: "GET"
        },
        state.authData
      );

      if (latestUserAuthenticationRequest) {
        return latestUserAuthenticationRequest
          .then(response => {
            if (isSuccessApiResponse(response)) {
              return response.data;
            } else {
              return null;
            }
          })
          .catch(error => {
            console.error(error);
            toastError("Failed to load authentication data. Error - " + error.data);
          });
      }
    }
  };
};
