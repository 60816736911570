import { updateObject } from "../../shared/Utility/commonUtility";
import * as actionTypes from "../actions/ActionType/securityActionTypes";

const initialState = {
  loading: false,
  error: null
};

const createUserBegin = state => {
  return updateObject(state, { error: null, loading: true });
};

const createUserSuccess = (state, action) => {
  return updateObject(state, { error: null, loading: false });
};

const createUserFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

const securityReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_USER_BEGIN:
      return createUserBegin(state);
    case actionTypes.CREATE_USER_SUCCESS:
      return createUserSuccess(state, action);
    case actionTypes.CREATE_USER_FAIL:
      return createUserFail(state, action);
    default:
      return state;
  }
};

export default securityReducer;
