import React from "react";

const WikiTagsBadge = props => {
  const tags = props.Tags;
  const tagsInBadge = tags
    ? tags.map(tag => (
        <span className="badge badge-info mr-1" key={tag.PrimKey}>
          {tag.Title}
        </span>
      ))
    : "";

  return <div>{tagsInBadge}</div>;
};

export default WikiTagsBadge;
