import * as actionTypes from "./ActionType/wikiActionTypes";
import * as request from "../../shared/request";
import * as criteriaBuilder from "../../shared/criteria/criteriaBuilder";
import { updateObject } from "../../shared/Utility/commonUtility";
import { toastSuccess, toastException } from "../../shared/Utility/toastUtility";
import { isSuccessApiResponse } from "../../shared/Utility/dataUtility";

export const loadWikisBegin = () => {
  return {
    type: actionTypes.FETCH_WIKIS_BEGIN
  };
};

export const loadWikisSuccess = wikis => {
  return {
    type: actionTypes.FETCH_WIKIS_SUCCESS,
    wikis
  };
};

export const loadWikisFail = error => {
  toastException("Failed to load wikis. ", error);

  return {
    type: actionTypes.FETCH_WIKIS_FAIL,
    error
  };
};

export const loadWikis = searchText => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(loadWikisBegin());

    let titleCriteria = criteriaBuilder.getLikeCriteria(
      "Title",
      "%" + searchText + "%"
    );
    let contentCritera = criteriaBuilder.getLikeCriteria(
      "Markdown",
      "%" + searchText + "%"
    );
    let titleOrContentCriteria = criteriaBuilder.getOrCriteria(
      titleCriteria,
      contentCritera
    );
    let criteria = criteriaBuilder.getExistCriteria(
      "t_Support_ArticleRevision",
      "PrimKey",
      "ArticleRevisionRef",
      titleOrContentCriteria
    );

    const getWikiListRequest = request.apiRequest(
      {
        url: "/select/t_Support_Article/ArticleRevision",
        method: "POST",
        data: criteria
      },
      state.authData
    );

    if (getWikiListRequest) {
      getWikiListRequest
        .then(response => {
          if (isSuccessApiResponse(response) && response.data.length > 0) {
            let articles = response.data[0].$values;
            let revisions = response.data[1].$values;

            // Merge Revision and Article info
            let revisionWithArticle = revisions.map(curRev => {
              let curArticle = articles.find(article => {
                return article.ArticleRevisionRef === curRev.PrimKey;
              });

              return curArticle
                ? updateObject(curRev, {
                    ArticleRef: curArticle.PrimKey,
                    IsPublished: curArticle.IsPublished,
                    ArticleType: curArticle.ArticleType
                  })
                : curRev;
            });

            dispatch(loadWikisSuccess(revisionWithArticle));
          }
        })
        .catch(error => {
          console.log(error);
          dispatch(loadWikisFail(error));
        });
    }
  };
};

// Tag actions
export const loadWikiTagsBegin = () => {
  return {
    type: actionTypes.FETCH_WIKI_TAGS_BEGIN
  };
};

export const loadWikiTagsSuccess = (allTags, articleTags) => {
  return {
    type: actionTypes.FETCH_WIKI_TAGS_SUCCESS,
    allTags: allTags,
    articleTags
  };
};

export const loadWikiTagsFail = error => {
  toastException("Failed to load tags. ", error);
  return {
    type: actionTypes.FETCH_WIKI_TAGS_FAIL,
    error
  };
};

export const loadWikiTags = () => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(loadWikiTagsBegin());

    const getWikiTagsRequest = request.apiRequest(
      {
        url: "/select/t_Support_Tag/ArticleTag",
        method: "POST"
      },
      state.authData
    );

    if (getWikiTagsRequest) {
      getWikiTagsRequest
        .then(response => {
          if (isSuccessApiResponse(response) && response.data.length > 0) {
            let allTags = response.data[0].$values;
            let articleTags = response.data[1].$values;
            dispatch(loadWikiTagsSuccess(allTags, articleTags));
          }
        })
        .catch(error => {
          console.log(error);
          dispatch(loadWikiTagsFail(error));
        });
    }
  };
};

export const tagSelectionChanged = data => {
  return {
    type: actionTypes.TAG_SELECTION_CHANGED,
    selectedTags: data.value
  };
};

export const setSearchText = searchText => {
  return {
    type: actionTypes.SET_SEARCH_TEXT,
    searchText
  };
};

export const createOrUpdateWikiBegin = () => {
  return {
    type: actionTypes.CREATEORUPDATE_WIKI_BEGIN
  };
};

export const createOrUpdateWikiSuccess = () => {
  toastSuccess("Wiki save operation is successful");
  return {
    type: actionTypes.CREATEORUPDATE_WIKI_SUCCESS
  };
};

export const createOrUpdateWikiFail = error => {
  toastException("Failed to save wiki. ", error);
  return {
    type: actionTypes.CREATEORUPDATE_WIKI_FAIL,
    error
  };
};

export const createOrUpdateWiki = (wikiArticle, props) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(createOrUpdateWikiBegin());

    const postWikiRequest = request.apiRequest(
      {
        url: "/save",
        method: "POST",
        data: wikiArticle
      },
      state.authData
    );

    if (postWikiRequest) {
      postWikiRequest
        .then(response => {
          if (isSuccessApiResponse(response)) {
            dispatch(createOrUpdateWikiSuccess());
            props.history.push("/wiki");
          }
        })
        .catch(error => {
          console.log(error);
          dispatch(createOrUpdateWikiFail(error));
        });
    }
  };
};

export const createOrUpdateTagBegin = () => {
  return {
    type: actionTypes.CREATEORUPDATE_TAG_BEGIN
  };
};

export const createOrUpdateTagSuccess = () => {
  toastSuccess("Tag save operation is successful");
  return {
    type: actionTypes.CREATEORUPDATE_TAG_SUCCESS
  };
};

export const createOrUpdateTagFail = error => {
  toastException("Failed to save tag. ", error);

  return {
    type: actionTypes.CREATEORUPDATE_TAG_FAIL,
    error
  };
};

export const createOrUpdateTag = (tag, props) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(createOrUpdateTagBegin());

    const postTagRequest = request.apiRequest(
      {
        url: "/save",
        method: "POST",
        data: tag
      },
      state.authData
    );

    if (postTagRequest) {
      postTagRequest
        .then(response => {
          if (isSuccessApiResponse(response)) {
            dispatch(createOrUpdateTagSuccess());
            props.history.push("/wiki");
          }
        })
        .catch(error => {
          console.log(error);
          dispatch(createOrUpdateTagFail(error));
        });
    }
  };
};

// Wiki link actions
export const loadWikiLinkBegin = () => {
  return {
    type: actionTypes.FETCH_WIKI_LINK_BEGIN
  };
};

export const loadWikiLinkSuccess = wiki => {
  return {
    type: actionTypes.FETCH_WIKI_LINK_SUCCESS,
    wiki
  };
};

export const loadWikiLinkFail = error => {
  toastException("Failed to load wiki link. ", error);

  return {
    type: actionTypes.FETCH_WIKI_LINK_FAIL,
    error
  };
};

export const loadWikiLink = articleId => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(loadWikiLinkBegin());

    var data = criteriaBuilder.getEqualsCriteria("PrimKey", articleId);

    const articleRequest = request.apiRequest(
      {
        url: "/select/t_Support_Article/ArticleRevision,ArticleTag.Tag",
        method: "POST",
        data
      },
      state.authData
    );

    if (articleRequest) {
      articleRequest
        .then(response => {
          if (isSuccessApiResponse(response) && response.data.length === 4) {
            let article = response.data[0].$values[0];
            let revision = response.data[1].$values[0];
            let articleDetails = updateObject(revision, {
              ArticleRef: article.PrimKey,
              IsPublished: article.IsPublished,
              ArticleType: article.ArticleType,
              ArticleTags: response.data[3].$values
            });

            dispatch(loadWikiLinkSuccess(articleDetails));
          }
        })
        .catch(error => {
          console.log(error);
          dispatch(loadWikiLinkFail(error));
        });
    }
  };
};

// Delete Wiki

export const deleteWikiBegin = () => {
  return {
    type: actionTypes.DELETE_WIKI_BEGIN
  };
};

export const deleteWikiSuccess = deletedWiki => {
  toastSuccess("Successfully deleted wiki.");
  return {
    type: actionTypes.DELETE_WIKI_SUCCESS,
    deletedWiki: deletedWiki
  };
};

export const deleteWikiFail = error => {
  toastException("Failed to delete wiki. ", error);

  return {
    type: actionTypes.DELETE_WIKI_FAIL,
    error
  };
};

export const deleteWiki = wiki => {
  return (dispatch, getState) => {
    dispatch(deleteWikiBegin());
    const state = getState();
    const deleteWikiRequest = request.apiRequest(
      {
        url: "/save",
        method: "POST",
        data: wiki
      },
      state.authData
    );

    if (deleteWikiRequest) {
      deleteWikiRequest
        .then(response => {
          if (isSuccessApiResponse(response)) {
            dispatch(deleteWikiSuccess(wiki[0]));
          }
        })
        .catch(error => {
          dispatch(deleteWikiFail(error));
        });
    }
  };
};

// Delte Tag
export const deleteTagBegin = () => {
  return {
    type: actionTypes.DELETE_TAG_BEGIN
  };
};

export const deleteTagSuccess = deletedTag => {
  toastSuccess("Successfully deleted tag.");
  return {
    type: actionTypes.DELETE_TAG_SUCCESS,
    deletedTag: deletedTag
  };
};

export const deleteTagFail = error => {
  toastException("Failed to delete tag. ", error);

  return {
    type: actionTypes.DELETE_TAG_FAIL,
    error
  };
};

export const deleteTag = tag => {
  return (dispatch, getState) => {
    dispatch(deleteTagBegin());
    const state = getState();
    const deleteTagRequest = request.apiRequest(
      {
        url: "/save",
        method: "POST",
        data: tag
      },
      state.authData
    );

    if (deleteTagRequest) {
      deleteTagRequest
        .then(response => {
          if (isSuccessApiResponse(response)) {
            dispatch(deleteTagSuccess(tag[0]));
          }
        })
        .catch(error => {
          dispatch(deleteTagFail(error));
        });
    }
  };
};
