import * as actionTypes from "./ActionType/dashboardActionTypes";
import * as request from "../../shared/request";
import * as criteriaBuilder from "../../shared/criteria/criteriaBuilder";
import { isSuccessApiResponse } from "../../shared/Utility/dataUtility";
import { toastSuccess, toastError } from "../../shared/Utility/toastUtility";
import { copyTextToClipboard } from "../../shared/Utility/commonUtility";

export const loadDashboards = () => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(loadDashboardsBegin());

    const dashboardRequest = request.apiRequest(
      {
        url: "/select/vc_Reporting_DashboardWithoutLayout",
        method: "POST"
      },
      state.authData
    );

    if (dashboardRequest) {
      dashboardRequest
        .then(response => {
          if (isSuccessApiResponse(response) && response.data.length > 0) {
            dispatch(loadDashboardsSuccess(response.data[0].$values));
          }
        })
        .catch(error => {
          console.log(error);
          dispatch(loadDashboardsFail(error));
        });
    }
  };
};

export const loadDashboardsBegin = () => {
  return {
    type: actionTypes.FETCH_DASHBOARDS_BEGIN
  };
};

export const loadDashboardsSuccess = dashboards => {
  return {
    type: actionTypes.FETCH_DASHBOARDS_SUCCESS,
    dashboards
  };
};

export const loadDashboardsFail = error => {
  toastError("Loading dashboards failed!");

  return {
    type: actionTypes.FETCH_DASHBOARDS_FAIL,
    error
  };
};

export const dashboardSelectionChanged = data => {
  return {
    type: actionTypes.DASHBOARD_SELECTION_CHANGED,
    selectedDashboard: data.addedItems[0]
  };
};

// Dashboard link actions
export const loadDashboardLinkBegin = () => {
  return {
    type: actionTypes.FETCH_DASHBOARD_LINK_BEGIN
  };
};

export const loadDashboardLinkSuccess = (dashboardLink,dashboard) => {
  return {
    type: actionTypes.FETCH_DASHBOARD_LINK_SUCCESS,
    dashboardLink,
    dashboard
  };
};

export const loadDashboardLinkFail = error => {
  toastError("Loading dashboard link information failed!");

  return {
    type: actionTypes.FETCH_DASHBOARD_LINK_FAIL,
    error
  };
};

export const loadDashboardLink = dashboardLinkId => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(loadDashboardLinkBegin());

    var data = criteriaBuilder.getEqualsCriteria("PrimKey", dashboardLinkId);

    const datasourceRequest = request.apiRequest(
      {
        url: "/select/t_Reporting_DashboardLink/Dashboard",
        method: "POST",
        data
      },
      state.authData
    );
    if (datasourceRequest) {
      datasourceRequest
        .then(response => {
          if (
            isSuccessApiResponse(response) &&
            response.data.length > 1 &&
            response.data[0].$values.length > 0 &&
            response.data[1].$values.length > 0
          ) {
            const dashboardLink = response.data[0].$values[0];
            const dashboard = response.data[1].$values[0];
            dispatch(loadDashboardLinkSuccess(dashboardLink, dashboard));
          }
        })
        .catch(error => {
          console.log(error);
          dispatch(loadDashboardLinkFail(error));
        });
    }
  };
};

export const clearDashboardSelectionData = () => {
  return {
    type: actionTypes.CLEAR_ALL_DASHBOARDSELECTIONDATA
  };
};

// Dashboard link generation
export const createDashboardLinkBegin = () => {
  return {
    type: actionTypes.CREATE_DASHBOARD_LINK_BEGIN
  };
};

export const createDashboardLinkSuccess = dashboardLinkData => {
  // Generate link
  const wikiUrl =
    window.location.origin + "/dashboard/" + dashboardLinkData.PrimKey;

  copyTextToClipboard(wikiUrl);

  toastSuccess("Link copied to clipboard");
  return {
    type: actionTypes.CREATE_DASHBOARD_LINK_SUCCESS
  };
};

export const createDashboardLinkFail = error => {
  toastError("Error occured creating dashboard link");

  return {
    type: actionTypes.CREATE_DASHBOARD_LINK_FAIL,
    error
  };
};

export const createAndCopyDashboardLink = dashboardLinkData => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(createDashboardLinkBegin());
    const dashbaordLinkRequest = request.apiRequest(
      {
        url: "/save",
        method: "POST",
        data: dashboardLinkData
      },
      state.authData
    );
    if (dashbaordLinkRequest) {
      dashbaordLinkRequest
        .then(response => {
          if (isSuccessApiResponse(response)) {
            dispatch(createDashboardLinkSuccess(dashboardLinkData[0]));
          }
        })
        .catch(error => {
          console.log(error);
          dispatch(createDashboardLinkFail(error));
        });
    }
  };
};
