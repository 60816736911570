import * as actionTypes from "./ActionType/projectActionTypes";
import * as request from "../../shared/request";
import { toastException } from "../../shared/Utility/toastUtility";
import { isSuccessApiResponse } from "../../shared/Utility/dataUtility";
import * as criteriaBuilder from "../../shared/criteria/criteriaBuilder";

// Load Calendars
export const loadCalendarsBegin = () => {
  return {
    type: actionTypes.FETCH_CALENDAR_BEGIN
  };
};

export const loadCalendarsSuccess = calendars => {
  return {
    type: actionTypes.FETCH_CALENDAR_SUCCESS,
    calendars
  };
};

export const loadCalendarsFail = error => {
  toastException("Failed to load calendars", error);
  return {
    type: actionTypes.FETCH_CALENDAR_FAIL,
    error
  };
};

export const loadCalendars = () => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(loadCalendarsBegin());

    const getCalendarsRequest = request.apiRequest(
      {
        url: "/select/t_Project_Calendar",
        method: "POST"
      },
      state.authData
    );

    if (getCalendarsRequest) {
      getCalendarsRequest
        .then(response => {
          if (isSuccessApiResponse(response) && response.data.length > 0) {
            const calendars = response.data[0].$values;
            dispatch(loadCalendarsSuccess(calendars));
          }
        })
        .catch(error => {
          console.log(error);
          dispatch(loadCalendarsFail(error));
        });
    }
  };
};

// Load Cut Off Types
export const loadCutOffTypesBegin = () => {
  return {
    type: actionTypes.FETCH_CUTOFFTYPE_BEGIN
  };
};

export const loadCutOffTypesSuccess = cutOffTypes => {
  return {
    type: actionTypes.FETCH_CUTOFFTYPE_SUCCESS,
    cutOffTypes
  };
};

export const loadCutOffTypesFail = error => {
  toastException("Failed to load cut off types", error);
  return {
    type: actionTypes.FETCH_CUTOFFTYPE_FAIL,
    error
  };
};

export const loadCutOffTypes = () => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(loadCutOffTypesBegin());

    const getCutOffTypesRequest = request.apiRequest(
      {
        url: "/select/t_Project_CutOffType",
        method: "POST"
      },
      state.authData
    );

    if (getCutOffTypesRequest) {
      getCutOffTypesRequest
        .then(response => {
          if (isSuccessApiResponse(response) && response.data.length > 0) {
            const cutOffTypes = response.data[0].$values;
            dispatch(loadCutOffTypesSuccess(cutOffTypes));
          }
        })
        .catch(error => {
          console.log(error);
          dispatch(loadCutOffTypesFail(error));
        });
    }
  };
};

// Load Current Revision
export const loadCurrentRevisionBegin = () => {
  return {
    type: actionTypes.FETCH_CURRENTREVISION_BEGIN
  };
};

export const loadCurrentRevisionSuccess = currentRevision => {
  return {
    type: actionTypes.FETCH_CURRENTREVISION_SUCCESS,
    currentRevision
  };
};

export const loadCurrentRevisionFail = error => {
  toastException("Failed to load current revision", error);
  return {
    type: actionTypes.FETCH_CURRENTREVISION_FAIL,
    error
  };
};

export const loadCurrentRevision = () => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(loadCurrentRevisionBegin());

    var criteria = criteriaBuilder.getEqualsCriteria("CurrentRevision", true);

    const getCurrentRevisionRequest = request.apiRequest(
      {
        url: "/select/v_Project_Revision",
        method: "POST",
        data: criteria
      },
      state.authData
    );

    if (getCurrentRevisionRequest) {
      getCurrentRevisionRequest
        .then(response => {
          if (isSuccessApiResponse(response) && response.data.length > 0) {
            const currentRevision = response.data[0].$values[0];
            dispatch(loadCurrentRevisionSuccess(currentRevision));
          } else {
            dispatch(
              loadCurrentRevisionFail("Failed to load current revision")
            );
          }
        })
        .catch(error => {
          console.log(error);
          dispatch(loadCurrentRevisionFail(error));
        });
    }
  };
};
