export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  };
};

export const getRequestHeader = token => {
  const authStr = "Bearer ".concat(token);
  return { headers: { Authorization: authStr } };
};

export const getBaseApiUrl = authData => {
  return authData.selectedTenantApiUrl;
};

export const getBaseReportingServerUrl = authData => {
  return authData.selectedTenantWebReportingUrl;
};

export const getPromineoToken = authData => {
  return authData.promineoToken;
};

export const checkIfUserHasValidPromineoToken = authData => {
  const promineoToken = getPromineoToken(authData);
  const expiresOn = authData.expires;

  if (promineoToken && expiresOn && new Date(expiresOn) > new Date()) {
    return true;
  }

  return false;
};
export const checkIfUserIsLoggedIn = authData => {
  const promineoToken = getPromineoToken(authData);
  const expiresOn = authData.expires;
  const tenantId = authData.selectedTenant
    ? authData.selectedTenant.TenantId
    : null;
  if (
    promineoToken &&
    expiresOn &&
    new Date(expiresOn) > new Date() &&
    tenantId
  ) {
    return true;
  }

  return false;
};

export const checkIfUserIdIsValid = authData => {
  if (authData && authData.userId && authData.userId !== "" && checkIfUserIsLoggedIn(authData)) {
    return true;
  }

  return false;
}

export const getRedirectionUrlPathOnTenantSelection = props => {
  let path = "/home";
  if (
    props &&
    props.location &&
    props.location.redirectInfo &&
    props.location.redirectInfo.path
  ) {
    path = props.location.redirectInfo.path;
  }
  return path;
};

export const checkIfObjectIsEmpty = obj => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
};

export const copyTextToClipboard = textToCopy => {
  const el = document.createElement("textarea");
  el.value = textToCopy;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

export const hasAccessPermission = (userRights, appRightFlag) => {
  return (userRights & appRightFlag) === appRightFlag;
}

export const hasDataAccess = (dataAccess, dataGroup, accessToCheck) => {
  let accessForGroup = dataAccess.find(da => da.DataGroup === dataGroup);
  let hasAccess = false;

  if (accessForGroup) {
    hasAccess = (accessForGroup.AccessBits & accessToCheck) === accessToCheck;
  }

  return hasAccess;
};