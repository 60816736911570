import React from "react";
import AddEditLeave from "./AddEditLeaveComponent";
import LeaveRequests from "./LeaveRequestsComponent";
import PersonPositions from "./PersonPositionsComponent";
import { connect } from "react-redux";
import * as actions from "../../store/actions/leaveActions";

class RegisterLeave extends React.Component {
  state = { selectedLeaveRequest: null };

  componentDidMount() {
    this.props.onLoadLeaveTypes();
  }

  onLeaveRequestSelectionChanged = leaveRequest => {
    this.setState({
      selectedLeaveRequest: leaveRequest
    });
  };

  hasPersonData = () => {
    return this.props.myself && this.props.myself.PersonRef && this.props.myself.OrganizationUnitRef
  }

  render() {
    return this.props.myself ? 
    this.hasPersonData() ?
    (
      <div className="container">
        <div className="row">
          <div className="col">
            <AddEditLeave
              selectedLeaveRequest={this.state.selectedLeaveRequest}
              key={
                this.state.selectedLeaveRequest != null
                  ? this.state.selectedLeaveRequest.PrimKey
                  : ""
              }
            />
          </div>
          <div className="col">
            <LeaveRequests
              onSelectionChanged={this.onLeaveRequestSelectionChanged}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col">
            <PersonPositions/>
          </div>
        </div>
      </div>
    ) : <h5>This user is  not associated with any Person and Organization.</h5>
    : (
      ""
    );
  }
}

const mapStateToProps = state => {
  return {
    myself: state.authData.myself,
    leaveRequests: state.leaveData.leaveRequests
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onLoadLeaveTypes: () => dispatch(actions.loadLeaveTypes())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterLeave);
