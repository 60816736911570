import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import "./../styles/App.css";
import Header from "./Header";
import Main from "./Main";
import * as utility from "./../shared/Utility/commonUtility";
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from "react-router-dom";
import { logActivity } from "../store/actions/activityLogActions";
import { activityTypes } from "../shared/Enums/activityTypes";

toast.configure({
  position: toast.POSITION.BOTTOM_RIGHT,
  closeOnClick: true,
  newestOnTop: true,
  draggable: false,
  bodyClassName: "toastBody",
})

class App extends Component {
  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this.props.logActivity(activityTypes.Navigation,location.pathname);
    });
  }

  componentWillUnmount() {
      this.unlisten();
  }
  
  render() {
    const { isAuthenticated } = this.props;
    const headerComponent = <Header key="headerComponent" />;
    const mainComponent = <Main key="mainComponent" />;
    let appComponents = [];
    if (isAuthenticated) {
      appComponents.push(headerComponent);
    }

    appComponents.push(mainComponent);
    return <div>{appComponents}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: utility.checkIfUserIsLoggedIn(state.authData)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logActivity: (action,details) => {
      dispatch(logActivity(action,details));
    }
  };
};

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(App));
