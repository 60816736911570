import React from "react";

const CommunityItem = ({data}) => {
  return (
    <div className="container border-bottom">
      <div className="row">
        <h6>{data.name}</h6>
      </div>
      <div className="row">
        <p>{data.description}</p>
      </div>
    </div>
  );
};

export default CommunityItem;
