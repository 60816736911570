import * as Msal from "msal";

export default class AuthService {
  constructor() {
    this.authority = "https://login.microsoftonline.com/common";
    this.msalConfig = {
      auth: {
        clientId: "bc73b0a1-f3d7-4d47-982f-ecca14984c65",
        authority: this.authority
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
      }
    };

    this.loginRequest = {
      scopes: ["user.read"]
    };

    this.accessTokenRequest = {
      ...this.loginRequest,
      authority: this.authority
    };

    this.msalObject = new Msal.UserAgentApplication(this.msalConfig);
  }

  login = () => {
    return this.msalObject
      .loginPopup(this.loginRequest)
      .then(loginResponse => {
        return loginResponse.idToken.rawIdToken;
      })
      .catch(error => {
        console.error("Error occurred during authentication", error);
      });
  };

  logout = () => {
    this.msalObject.logout();
  };

  getToken = () => {
    return this.msalObject
      .acquireTokenSilent(this.accessTokenRequest)
      .then(accessTokenResponse => {
        return accessTokenResponse.idToken.rawIdToken;
      })
      .catch(error => {
        // call acquireTokenPopup in case of acquireTokenSilent failure
        // due to consent or interaction required
        if (error.name === "InteractionRequiredAuthError") {
          return this.msalObject
            .acquireTokenPopup(this.accessTokenRequest)
            .then(accessTokenResponse => {
              return accessTokenResponse.idToken.rawIdToken;
            })
            .catch(error => {
              console.error(
                "Error occurred while loading Microsoft authentication token",
                error
              );
            });
        }
      });
  };
}
