import React from "react";
import { connect } from "react-redux";
import { PromineoTextBox } from "../Common/Controls/ControlList";
import { toastError } from "../../shared/Utility/toastUtility";
import { GetNewId } from "../../shared/Utility/uidUtility";
import { getApiCompatibleModelType } from "../../shared/Utility/dataUtility";
import * as actions from "../../store/actions/wikiActions";
import { Container } from "react-bootstrap";
import EditorButtons from "../Common/EditorButtonsComponent";

class TagEditor extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = {
      id: props.tagInitialData ? props.tagInitialData.id : "",
      title: props.tagInitialData ? props.tagInitialData.title : "",
      description: props.tagInitialData ? props.tagInitialData.description : "",
      tagType: props.tagInitialData
        ? props.tagInitialData.tagType
        : props.isPortalAdmin === true
        ? "Public"
        : "Private"
    };

    this.state = this.initialState;
  }

  onInputValueChanged = (controlName, value) => {
    this.setState({
      [controlName]: value
    });
  };

  isNotEmptyAndUnderMaxLength = (value, maxLength) => {
    return value.trim() !== "" && value.length <= maxLength;
  };

  isValidTagData = () => {
    return (
      this.isNotEmptyAndUnderMaxLength(this.state.title, 128) &&
      this.isNotEmptyAndUnderMaxLength(this.state.tagType, 128)
    );
  };

  isInEditMode = () => {
    return this.props.mode === "E";
  };

  hasTagDataChanges = tag => {
    if (this.isInEditMode()) {
      return (
        tag.Title !== this.props.tagInitialData.title ||
        tag.Description !== this.props.tagInitialData.description
      );
    } else {
      return true;
    }
  };

  redirectToWikiComponent = () => {
    this.props.history.push("/wiki");
  };

  saveTag = e => {
    e.preventDefault();
    if (this.isValidTagData()) {
      const tagData = [
        {
          $type: getApiCompatibleModelType("Support", "Tag"),
          PrimKey: this.isInEditMode() ? this.state.id : GetNewId(),
          RecordState: this.isInEditMode() ? 1 : 2,
          Title: this.state.title,
          Description: this.state.description,
          TagType: this.state.tagType
        }
      ];

      if (this.hasTagDataChanges(tagData[0])) {
        this.props.createOrUpdateTag(tagData);
      } else {
        if (
          window.confirm(
            "You do not have any change to save. Do you still want to navigate?"
          )
        ) {
          this.redirectToWikiComponent();
        }
      }
    } else {
      toastError("Invalid tag data");
    }
  };

  handleCancelClick = () => {
    this.redirectToWikiComponent();
    
  };

  render() {
    return (
      <Container>
        <form onSubmit={this.saveTag}>
          <PromineoTextBox
            id="title"
            key="title"
            label="Title"
            defaultValue={this.state.title}
            onValueChanged={this.onInputValueChanged}
          />
          <PromineoTextBox
            id="description"
            key="description"
            label="Description"
            defaultValue={this.state.description}
            onValueChanged={this.onInputValueChanged}
          />

          <EditorButtons onCancelClick={this.handleCancelClick} />
        </form>
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    mode:
      props &&
      props.history &&
      props.history.location &&
      props.history.location.state &&
      props.history.location.state.mode
        ? props.history.location.state.mode
        : "I",
    tagInitialData:
      props &&
      props.history &&
      props.history.location &&
      props.history.location.state
        ? props.history.location.state.tag
        : null,
    isPortalAdmin: state.authData.isPortalAdmin
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    createOrUpdateTag: tag => {
      dispatch(actions.createOrUpdateTag(tag, ownProps));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TagEditor);
