import { toast } from "react-toastify";
import * as actionTypes from "./ActionType/datasourceActionTypes";
import * as request from "../../shared/request";
import * as criteriaBuilder from "../../shared/criteria/criteriaBuilder";
import { isSuccessApiResponse } from "../../shared/Utility/dataUtility";

export const loadAllDataSources = () => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(loadAllDatasourcesBegan());

    const datasourceRequest = request.apiRequest(
      {
        url: "/select/v_Reporting_DataSource",
        method: "POST",
      },
      state.authData
    );
    if (datasourceRequest) {
      datasourceRequest
        .then((response) => {
          if (isSuccessApiResponse(response) && response.data.length > 0) {
            dispatch(loadAllDatasourcesSucceeded(response.data[0].$values));
          }
        })
        .catch((error) => {
          errorOccurredWhileLoading(dispatch, error, loadAllDatasourcesFailed);
        });
    }
  };
};

export const loadAllDatasourcesBegan = () => {
  return {
    type: actionTypes.FETCH_ALL_DATASOURCE_BEGIN,
  };
};

export const loadAllDatasourcesSucceeded = (dataSources) => {
  return {
    type: actionTypes.FETCH_ALL_DATASOURCE_SUCCESS,
    dataSources,
  };
};

export const loadAllDatasourcesFailed = (error) => {
  return {
    type: actionTypes.FETCH_ALL_DATASOURCE_FAIL,
    error,
  };
};

export const loadDatasource = (selectedDatasourceId) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(loadDatasourceBegan());

    if (selectedDatasourceId) {
      var data = criteriaBuilder.getEqualsCriteria(
        "PrimKey",
        selectedDatasourceId
      );

      const datasourceRequest = request.apiRequest(
        {
          url:
            "/select/v_Reporting_DataSource/Filters,Filters.Items,Parameters",
          method: "POST",
          data,
        },
        state.authData
      );
      if (datasourceRequest) {
        datasourceRequest
          .then((response) => {
            if (isSuccessApiResponse(response) && response.data.length > 0) {
              dispatch(loadDatasourceSucceeded(response.data));
            }
          })
          .catch((error) => {
            errorOccurredWhileLoading(dispatch, error, loadDatasourceFailed);
          });
      }
    } else {
      errorOccurredWhileLoading(
        dispatch,
        "Datasource key was not found.",
        loadDatasourceFailed
      );
    }
  };
};

export const loadFilterParameterDefinition = (selectedFilterId) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(loadFilterParameterDefinitionBegan());

    if (selectedFilterId) {
      const filterParameterDefinitionGetRequest = request.reportServerRequest(
        {
          url: "/FilterDefinition/" + selectedFilterId,
          method: "GET",
        },
        state.authData
      );

      if (filterParameterDefinitionGetRequest) {
        return filterParameterDefinitionGetRequest
          .then((response) => {
            if (isSuccessApiResponse(response) && response.data.length > 0) {
              let filterParameterDefinitions = response.data;
              dispatch(
                loadFilterParameterDefinitionSucceeded(
                  filterParameterDefinitions
                )
              );
            }
          })
          .catch((error) => {
            errorOccurredWhileLoading(
              dispatch,
              error,
              loadFilterParameterDefinitionFailed
            );
          });
      }
    } else {
      errorOccurredWhileLoading(
        dispatch,
        "No filter is selected",
        loadFilterParameterDefinitionFailed
      );
    }
  };
};

export const loadDatasourceBegan = () => {
  return {
    type: actionTypes.FETCH_DATASOURCE_BEGIN,
  };
};

export const loadDatasourceSucceeded = (data) => {
  return {
    type: actionTypes.FETCH_DATASOURCE_SUCCESS,
    data,
  };
};

export const loadDatasourceFailed = (error) => {
  return {
    type: actionTypes.FETCH_DATASOURCE_FAIL,
    error,
  };
};

export const filterSelectionChanged = (data) => {
  return {
    type: actionTypes.FILTER_SELECTION_CHANGE,
    selectedFilter: data,
  };
};

export const loadFilterParameterDefinitionBegan = () => {
  return {
    type: actionTypes.FETCH_FILTERPARAMETERDEFINITION_BEGIN,
  };
};

export const loadFilterParameterDefinitionSucceeded = (data) => {
  return {
    type: actionTypes.FETCH_FILTERPARAMETERDEFINITION_SUCCESS,
    data,
  };
};

export const loadFilterParameterDefinitionFailed = (error) => {
  return {
    type: actionTypes.FETCH_FILTERPARAMETERDEFINITION_FAIL,
    error,
  };
};

export const clearLoadedFilterParameterDefinition = () => {
  return {
    type: actionTypes.CLEAR_FILTERPARAMETERDATA,
  };
};

export const clearParameterData = () => {
  return {
    type: actionTypes.CLEAR_PARAMETERDATA,
  };
};

export const updateParameter = (id, value) => {
  return {
    type: actionTypes.UPDATE_PARAMETER,
    id,
    value,
  };
};

export const loadFilterLookUpDefinition = (columnRef) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(loadFilterLookUpDefinitionBegan());

    if (columnRef) {
      const lookUpDefinitionRequest = request.apiRequest(
        {
          url: `/getdatasourcecolumnlookupdata/${columnRef}/0/10000`,
          method: "POST",
        },
        state.authData
      );
      if (lookUpDefinitionRequest) {
        lookUpDefinitionRequest
          .then((response) => {
            if (isSuccessApiResponse(response) && response.data.length > 0) {
              const columns = Object.keys(response.data[0]);
              const filteredColumns = columns.filter(
                (column) => column !== "PrimKey" && column !== "RowNumber"
              );

              const lookUpDefinition = {
                columnRef: columnRef,
                columns: filteredColumns,
                datasource: response.data,
                displayColumn:
                  filteredColumns.length > 0 ? filteredColumns[0] : "PrimKey",
                valueColumn: "PrimKey",
              };

              dispatch(loadFilterLookUpDefinitionSucceeded(lookUpDefinition));
            }
          })
          .catch((error) => {
            errorOccurredWhileLoading(
              dispatch,
              error,
              loadFilterLookUpDefinitionFailed
            );
          });
      }
    } else {
      errorOccurredWhileLoading(
        dispatch,
        "Column key was not found.",
        loadFilterLookUpDefinition
      );
    }
  };
};

export const loadFilterLookUpDefinitionBegan = () => {
  return {
    type: actionTypes.FETCH_FILTERLOOKUPDEFINITION_BEGIN,
  };
};

export const loadFilterLookUpDefinitionSucceeded = (data) => {
  return {
    type: actionTypes.FETCH_FILTERLOOKUPDEFINITION_SUCCESS,
    data,
  };
};

export const loadFilterLookUpDefinitionFailed = (error) => {
  return {
    type: actionTypes.FETCH_FILTERLOOKUPDEFINITION_FAIL,
    error,
  };
};

const errorOccurredWhileLoading = (dispatch, message, failedEvent) => {
  console.log(message);
  toast.error(message);
  dispatch(failedEvent);
};
