import React from "react";
import { connect } from "react-redux";
import PromineoPopUp from "../Common/Controls/PromineoPopUpComponent";
import PromineoSelectBox from "./../Common/Controls/PromineoSelectBoxComponent";
import { toastError } from "../../shared/Utility/toastUtility";
import * as projectActions from "../../store/actions/projectActions";
import * as actions from "../../store/actions/communityDataSourceActions";

class PeriodizerInfoPopUp extends React.Component {
  componentDidMount() {
    this.props.updateCalendarSelection(null);
    this.props.updateCutOffTypeSelection(null);
    this.props.loadCalendarsAndCutOffTypes();
  }

  handleCalendarChanged = (controlName, value) => {
    this.props.updateCalendarSelection(value);
  };

  handleCutOffTypeChanged = (controlName, value) => {
    this.props.updateCutOffTypeSelection(value);
  };

  handleOkClick = () => {
    if (this.props.selectedCalendar && this.props.selectedCutOffType) {
      this.props.handlePeriodizerInfoSelected(true);
    } else {
      toastError("Both default calendar and cut off type are required");
    }
  };

  handleCloseClick = () => {
    if (
      window.confirm("Are you sure you want to abort dashboard downloading?")
    ) {
      this.props.handlePeriodizerInfoSelected(false);
    }
  };

  render() {
    const { calendars, cutOffTypes } = this.props;
    return (
      <PromineoPopUp
        visible={this.props.visible}
        title={"Periodizer Information"}
        width={300}
        height={250}
        handleOkClick={this.handleOkClick}
        handleCancelClick={this.handleCloseClick}
      >
        <div className="container">
          <div className="row justify-content-center">
            <PromineoSelectBox
              dataSource={calendars}
              id="calendars"
              label="Default Calendar"
              displayExpr="Title"
              valueExpr="PrimKey"
              onValueChanged={this.handleCalendarChanged}
            />
          </div>
          <div className="row justify-content-center">
            <PromineoSelectBox
              dataSource={cutOffTypes}
              id="leaveType"
              label="Cut Off Type"
              displayExpr="Name"
              valueExpr="PrimKey"
              onValueChanged={this.handleCutOffTypeChanged}
            />
          </div>
        </div>
      </PromineoPopUp>
    );
  }
}

const mapStateToProps = state => {
  return {
    calendars: state.projectData.calendars,
    cutOffTypes: state.projectData.cutOffTypes,
    selectedCalendar: state.communityData.defaultCalendarForDataSourceId,
    selectedCutOffType: state.communityData.cutOffTypeForDataSourceId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadCalendarsAndCutOffTypes: () => {
      dispatch(projectActions.loadCutOffTypes());
      dispatch(projectActions.loadCalendars());
    },
    updateCalendarSelection: selectedCalendarId => {
      dispatch(actions.updateCalendarSelection(selectedCalendarId));
    },
    updateCutOffTypeSelection: selectedCutOffTypeId => {
      dispatch(actions.updateCutOffTypeSelection(selectedCutOffTypeId));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PeriodizerInfoPopUp);
