import React from "react";
import { LoadPanel } from "devextreme-react/load-panel";

const LoadingPanel = props => {
  return (
    <LoadPanel
      shadingColor={"rgba(0,0,0,0.4)"}
      position={props.position}
      visible={props.visible}
      showIndicator={true}
      shading={true}
      showPane={true}
    />
  );
};

export default LoadingPanel;
