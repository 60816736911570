export const HOME = "/home";
export const SETTINGS = "/settings";
export const TENANTS = "/tenants";
export const WIKI = "/wiki";
export const WIKI_VIEWER = "/wikiViewer";
export const WIKI_EDITOR = "/wikiEditor";
export const WIKI_LINK = "/wiki/:articleId";
export const TAG_EDITOR = "/tagEditor";
export const DASHBOARD = "/dashboards";
export const DASHBOARD_VIEWER = "/dashboardViewer";
export const DASHBOARD_LINK = "/dashboard/:linkId";
export const REGISTER_LEAVE = "/registerleave";
export const COMMUNITY_UPLOADS = "/communityuploads";
export const LOGOUT = "/logout";
