import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Moment from "react-moment";
import ListItemAction from "../Common/ListItemActionComponent";
import TypeBadge from "./TypeBadgeComponent";
import * as actions from "../../store/actions/wikiActions";
import { getApiCompatibleModelType } from "../../shared/Utility/dataUtility";
import { hasDataAccess } from "../../shared/Utility/commonUtility";
import { accessBits } from "../../shared/Enums/accessBits";

const EditWiki = props => {
  const article = props.data;

  const articleTags = props.articleTags.filter(articleTage => {
    return articleTage.ArticleRef === article.ArticleRef;
  });

  const tags = props.allTags.filter(tag => {
    return articleTags.find(articleTage => {
      return tag.PrimKey === articleTage.TagRef;
    });
  });

  props.history.push({
    pathname: "/wikiEditor",
    state: {
      wikiArticle: {
        id: article.ArticleRef,
        title: article.Title,
        content: article.Markdown,
        articleType: article.ArticleType,
        tags: tags,
        wikiTags: articleTags
      },
      tags: props.allTags,
      mode: "E"
    }
  });
};

const DeleteWiki = data => {
  const wikiDeleteData = [
    {
      $type: getApiCompatibleModelType("Support", "Article"),
      PrimKey: data.data.ArticleRef,
      RecordState: 3
    }
  ];

  if (window.confirm("Are you sure to delete this wiki?")) {
    data.onDeleteWiki(wikiDeleteData);
  }
};

const hasRightsForPublicArticles = data => {
  let hasAccess = true;
  if (data.ArticleType === "Public") {
    hasAccess = data.isPortalAdmin;
  }

  return hasAccess;
};

const WikiItem = data => {
  const dataItem = data.data;
  return (
    <div className="container border-bottom">
      <div className="row">
        <div className="col-sm-10">
          <h4>{dataItem.Title}</h4>
        </div>
        <div className="col-sm-2 float-right">
          <ListItemAction
            displayEditButton={
              hasDataAccess(data.dataAccess, "Wiki", accessBits.Update) &&
              hasRightsForPublicArticles(data)
            }
            displayDeleteButton={
              hasDataAccess(data.dataAccess, "Wiki", accessBits.Delete) &&
              hasRightsForPublicArticles(data)
            }
            onEdit={() => {
              EditWiki(data);
            }}
            onDelete={() => {
              DeleteWiki(data);
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-8">
          <p>
            Last updated on <Moment format="LLLL">{dataItem.CreatedOn}</Moment>
          </p>
        </div>
        <div className="col-sm-4 text-right">
          <TypeBadge text={dataItem.ArticleType} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    articleTags: state.wikiData.articleTags,
    allTags: state.wikiData.allTags,
    dataAccess: state.authData.dataAccess,
    isPortalAdmin: state.authData.isPortalAdmin
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onDeleteWiki: wiki => dispatch(actions.deleteWiki(wiki))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(WikiItem)
);
