import React from "react";
import { getConfiguration } from "../../shared/Utility/configUtility";
import CompanyName from "./CompanyName";
import PromineoLogo from "./PromineoLogo";

export default function Footer() {
  const CompanyNameAndLogo = () => {
    return (
      <div className="flex gap-x-4 items-center flex-wrap">
        <CompanyName />
        <span className="hidden sm:block">|</span>
        <PromineoLogo isLarge />
      </div>
    );
  };

  const Contact = () => {
    const promineoSupportMail = getConfiguration("supportMail");
    return (
      <>
        <a href={`mailto:${promineoSupportMail}`}>{promineoSupportMail}</a>
      </>
    );
  };

  return (
    <div className="flex justify-between items-center flex-wrap">
      <CompanyNameAndLogo />
      <Contact />
    </div>
  );
}
