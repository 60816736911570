import React, { Component } from "react";
import FilterParmeterDisplay from "./FilterParameterDisplayComponent";
import "./../../styles/dashboard/filterParameterStyle.css";
import PromineoPopUp from "../Common/Controls/PromineoPopUpComponent";
import * as actions from "../../store/actions/datasourceActions";
import DashboardViewer from "./DashboardViewerComponent";
import { connect } from "react-redux";
import ScrollView from "devextreme-react/scroll-view";
import { Button } from "react-bootstrap";

export class DashboardLinkParameterConfirmation extends Component {
  constructor() {
    super();
    this.state = {
      shouldConfirmationPopupVisible: true,
      parameterSelectionConfirmed: false,
      filterDefinitionLoaded: false,
      validationGroupRef: React.createRef(),
    };
  }

  checkIfConfirmationPopupShouldVisible = () => {
    if (
      this.state.filterDefinitionLoaded &&
      !this.state.parameterSelectionConfirmed &&
      this.props.filterParameterDefinition.length
    ) {
      const editableParam = this.props.filterParameterDefinition.find(
        (p) => p.datasourceParameter && p.datasourceParameter.ReadOnly === false
      );
      if (editableParam) {
        return true;
      }
    }
    return false;
  };

  componentDidMount() {
    this.props
      .onLoadFilterParameterDefinition(this.props.selectedFilterId)
      .then(() => {
        this.setState({
          filterDefinitionLoaded: true,
        });
      });
  }

  handleLaunchDashboardClick = () => {
    var result = this.state.validationGroupRef.current.instance.validate();
    if (!result.isValid) return;
    this.setState({ parameterSelectionConfirmed: true });
  };

  render() {
    return (
      <div>
        {this.state.filterDefinitionLoaded ? (
          this.checkIfConfirmationPopupShouldVisible() ? (
            <PromineoPopUp
              visible={true}
              width={700}
              height="570"
              displayActionButtons={false}
            >
              <fieldset className="dashboardlink-parameterFieldSet">
                <legend>Select Parameter(s)</legend>
                <ScrollView id="scrollview" height={"95%"}>
                  <div className="pr-2">
                    <FilterParmeterDisplay
                      validationGroupRef={this.state.validationGroupRef}
                      selectedFilterId={this.props.selectedFilterId}
                      editableParametersOnly
                      displayForDashboardLink
                    />
                  </div>
                </ScrollView>
              </fieldset>
              <div className="py-2 w-100">
                <Button
                  className="btn btn-primary float-right w-100"
                  id="launchDashboardButton"
                  type="button"
                  onClick={this.handleLaunchDashboardClick}
                >
                  Launch Dashboard
                </Button>
              </div>
            </PromineoPopUp>
          ) : (
            <DashboardViewer throughLink={true} linkId={this.props.linkId} />
          )
        ) : (
          <div>Loading...</div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    filterParameterDefinition: state.dashboardData.filterParameterDefinition,
    selectedFilterId: state.dashboardData.selectedFilterId,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onLoadFilterParameterDefinition: (selectedFilterId) =>
      dispatch(actions.loadFilterParameterDefinition(selectedFilterId, true)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardLinkParameterConfirmation);
