import React from "react";
import { connect } from "react-redux";
import { Column } from "devextreme-react/data-grid";
import PromineoGrid from "../Common/Controls/PromineoGridComponent";
import * as actions from "../../store/actions/communityFieldActions";

class CommunityFieldGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.community &&
      (!prevProps.community ||
        this.props.community.id !==
          prevProps.community.id)
    ) {
      this.props.onLoadCommunityFields(this.props.community);
    }
  }

  handleSelectionChanged = selectedData => {
    if (selectedData && selectedData.selectedRowsData) {
      this.props.handleFieldSelectionChanged(selectedData.selectedRowsData);
    }
  };

  render() {
    const { fields } = this.props;
    return (
      <PromineoGrid
        allowMultiSelect={true}
        keyExpression={"id"}
        enablePaging={true}
        dataSource={fields}
        displayFilterRow={true}
        onSelectionChanged={this.handleSelectionChanged}
      >
        <Column dataField={"title"} width={"25%"}/>
        <Column dataField={"description"} width={"60%"} />
        <Column dataField={"type"} width={"15%"} />
      </PromineoGrid>
    );
  }
}

const mapStateToProps = state => {
  return {
    fields: state.communityData.selectedCommunityFields
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLoadCommunityFields: community =>
      dispatch(actions.loadCommunityFields(community)),
    handleFieldSelectionChanged: selectedFields =>
      dispatch(actions.fieldSelectionChanged(selectedFields))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunityFieldGrid);
