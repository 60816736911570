import * as actionTypes from "./ActionType/leaveActionTypes";
import * as request from "../../shared/request";
import * as criteriaBuilder from "../../shared/criteria/criteriaBuilder";
import { toastSuccess, toastError } from "../../shared/Utility/toastUtility";
import { isSuccessApiResponse } from "../../shared/Utility/dataUtility";
import { getToday, addDays } from "../../shared/Utility/dateUtility";
import { updateObject } from "../../shared/Utility/commonUtility";
import moment from "moment";

export const addEditLeaveBegin = () => {
  return {
    type: actionTypes.ADDEDIT_LEAVE_BEGIN,
  };
};

export const addEditLeaveSuccess = () => {
  toastSuccess("Leave saved successfully.");
  return {
    type: actionTypes.ADDEDIT_LEAVE_SUCCESS,
  };
};

export const addEditLeaveFail = (error) => {
  toastError("Failed to save leave. " + error.data.ExceptionMessage);
  return {
    type: actionTypes.ADDEDIT_LEAVE_FAIL,
    error,
  };
};

export const addEditLeave = (leaveRecord, props) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(addEditLeaveBegin());

    const postLeaveRequest = request.apiRequest(
      {
        url: "/save",
        method: "POST",
        data: leaveRecord,
      },
      state.authData
    );

    if (postLeaveRequest) {
      postLeaveRequest
        .then((response) => {
          if (isSuccessApiResponse(response)) {
            dispatch(addEditLeaveSuccess());
            dispatch(loadActiveLeaveRequests());
          }
        })
        .catch((error) => {
          console.log(error);
          dispatch(addEditLeaveFail(error));
        });
    }
  };
};

export const deleteLeaveRequestBegin = () => {
  return {
    type: actionTypes.DELETE_LEAVE_REQUEST_BEGIN,
  };
};

export const deleteLeaveRequestSuccess = () => {
  toastSuccess("Leave request deleted successfully.");
  return {
    type: actionTypes.DELETE_LEAVE_REQUEST_SUCCESS,
  };
};

export const deleteLeaveRequestFail = (error) => {
  toastError("Failed to delete leave. " + error.data.ExceptionMessage);
  return {
    type: actionTypes.DELETE_LEAVE_REQUEST_FAIL,
    error,
  };
};

export const deleteLeaveRequest = (leaveRecord, props) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(deleteLeaveRequestBegin());

    const postLeaveRequest = request.apiRequest(
      {
        url: "/save",
        method: "POST",
        data: leaveRecord,
      },
      state.authData
    );

    if (postLeaveRequest) {
      postLeaveRequest
        .then((response) => {
          if (isSuccessApiResponse(response)) {
            dispatch(deleteLeaveRequestSuccess());

            // load from server or update redux store?
            dispatch(loadActiveLeaveRequests());
          }
        })
        .catch((error) => {
          console.log(error);
          dispatch(deleteLeaveRequestFail(error));
        });
    }
  };
};

////
export const loadLeaveTypesBegin = () => {
  return {
    type: actionTypes.LOAD_LEAVE_TYPES_BEGIN,
  };
};

export const loadLeaveTypesSuccess = (leaveTypes) => {
  return {
    type: actionTypes.LOAD_LEAVE_TYPES_SUCCESS,
    leaveTypes,
  };
};

export const loadLeaveTypesFail = (error) => {
  toastError("Failed to load leave types. " + error.data.ExceptionMessage);
  return {
    type: actionTypes.LOAD_LEAVE_TYPES_FAIL,
    error,
  };
};

export const loadLeaveTypes = () => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(loadLeaveTypesBegin());

    const loadLeaveTypesRequest = request.apiRequest(
      {
        url: "/select/t_Project_PersonAvailabilityExceptionType",
        method: "POST",
      },
      state.authData
    );

    if (loadLeaveTypesRequest) {
      loadLeaveTypesRequest
        .then((response) => {
          if (isSuccessApiResponse(response)) {
            let leaveTypes = response.data[0].$values
              .filter((v) => {
                return v.VisibleForInput;
              })
              .sort((x, y) => {
                if (!x.Index) x.Index = 0;
                if (!y.Index) y.Index = 0;

                return x.Index > y.Index;
              });

            dispatch(loadLeaveTypesSuccess(leaveTypes));
          }
        })
        .catch((error) => {
          console.log(error);
          dispatch(loadLeaveTypesFail(error));
        });
    }
  };
};

//

export const loadActiveLeaveRequestsBegin = () => {
  return {
    type: actionTypes.LOAD_ACTIVE_LEAVE_REQUESTS_BEGIN,
  };
};

export const loadActiveLeaveRequestsSuccess = (leaveRequests) => {
  return {
    type: actionTypes.LOAD_ACTIVE_LEAVE_REQUESTS_SUCCESS,
    leaveRequests,
  };
};

export const loadActiveLeaveRequestsFail = (error) => {
  toastError("Failed to load leave requests. " + error.data.ExceptionMessage);
  return {
    type: actionTypes.LOAD_ACTIVE_LEAVE_REQUESTS_FAIL,
    error,
  };
};

export const loadActiveLeaveRequests = () => {
  return (dispatch, getState) => {
    let state = getState();
    dispatch(loadActiveLeaveRequestsBegin());
    let toDay = getToday();

    var criteriaPersonRef = getPersonCriteria(state);

    var toDateCriteria = criteriaBuilder.getBinaryOperatorCriteria(
      "To",
      toDay,
      ">"
    );

    let criteria = criteriaBuilder.getAndCriteria(
      criteriaPersonRef,
      toDateCriteria
    );

    const loadActiveLeaveRequestsRequest = request.apiRequest(
      {
        url: "/select/t_Project_PersonAvailabilityException",
        method: "POST",
        data: criteria,
      },
      state.authData
    );

    if (loadActiveLeaveRequestsRequest) {
      loadActiveLeaveRequestsRequest
        .then((response) => {
          if (isSuccessApiResponse(response)) {
            let leaveRequests = response.data[0].$values;

            leaveRequests = leaveRequests.map((leaveReq) => {
              // Convert Json date string to Date
              let From = new Date(leaveReq.From);
              let To = new Date(leaveReq.To);
              let ToDisplay = addDays(new Date(leaveReq.To), -1);

              return updateObject(leaveReq, { From, To, ToDisplay });
            });

            dispatch(loadActiveLeaveRequestsSuccess(leaveRequests));
          }
        })
        .catch((error) => {
          console.log(error);
          dispatch(loadActiveLeaveRequestsFail(error));
        });
    }
  };
};

export const loadPositionsBegin = () => {
  return {
    type: actionTypes.LOAD_POSITIONS_BEGIN,
  };
};

export const loadPositionsSuccess = (positions) => {
  return {
    type: actionTypes.LOAD_POSITIONS_SUCCESS,
    positions,
  };
};

export const loadPositionsFail = (error) => {
  let errorMessage = "Failed to load positions.";
  if (error && error.data && error.data.ExceptionMessage) {
    errorMessage = `${errorMessage} ${error.data.ExceptionMessage}`;
  }
  toastError(errorMessage);

  return {
    type: actionTypes.LOAD_POSITIONS_FAIL,
    error,
  };
};

const getPersonCriteria = (state) => {
  var criteriaPersonRef = criteriaBuilder.getEqualsCriteria(
    "PersonRef",
    state.authData.myself.PersonRef
  );
  return criteriaPersonRef;
};

export const loadPositions = () => {
  return (dispatch, getState) => {
    let state = getState();
    dispatch(loadPositionsBegin());

    var criteriaPersonRef = getPersonCriteria(state);

    var futurePositionLoadToDateCriteria =
      criteriaBuilder.getGreaterThanOrEqualCriteria("To", getToday());

    // Position's date is calculated from it's load's from & to date.
    // We need to find only those positions having future dates as load's to date.
    // That's why we need existCriteria here.
    var criteria = criteriaBuilder.getAndCriteria(
      criteriaPersonRef,
      criteriaBuilder.getExistCriteria(
        "t_MPP_ProjectPositionLoad",
        "ProjectPositionRef",
        "PrimKey",
        futurePositionLoadToDateCriteria
      )
    );

    var getLiveProjectRevisionCriteria = () => {
      var projectCriteria = criteriaBuilder.getExistCriteria(
        "t_Project_Project",
        "LiveProjectRevisionRef",
        "PrimKey",
        null
      );

      var projectRevisionCriteria = criteriaBuilder.getExistCriteria(
        "t_Project_ProjectRevision",
        "PrimKey",
        "ProjectRevisionRef",
        projectCriteria
      );
      return projectRevisionCriteria;
    };

    criteria = criteriaBuilder.getAndCriteria(
      criteria,
      getLiveProjectRevisionCriteria()
    );

    const positionsRequest = request.apiRequest(
      {
        url: "/select/t_MPP_ProjectPosition/ProjectRevision.LiveProjectRevisions,Loads,OrganizationUnitPositionType.PositionType",
        method: "POST",
        data: criteria,
      },
      state.authData
    );

    if (positionsRequest) {
      positionsRequest
        .then((response) => {
          if (isSuccessApiResponse(response)) {
            let projPositions = response.data[0].$values;
            //let projRevisions = response.data[1].$values;
            let liveProjects = response.data[2].$values;
            let posLoads = response.data[3].$values;
            let orgUnitPositions = response.data[4].$values;
            let posTypes = response.data[5].$values;

            let positions = projPositions.map((projPos) => {
              let from = getLoadDate(projPos.PrimKey, posLoads, true);
              let to = getLoadDate(projPos.PrimKey, posLoads, false);

              return {
                PositionRef: projPos.PrimKey,
                ProjectPositionId: projPos.ProjectPositionID,
                Project: getProjectForPosition(
                  projPos.ProjectRevisionRef,
                  liveProjects
                ).Title,
                Position: getPositionDesc(
                  projPos.OrganizationUnitPositionTypeRef,
                  orgUnitPositions,
                  posTypes
                ),
                From: from,
                To: to,
                Load: getLoadForPosition(projPos.PrimKey, posLoads),
              };
            });

            dispatch(loadPositionsSuccess(positions));
          }
        })
        .catch((error) => {
          dispatch(loadPositionsFail(error));
        });
    }
  };
};

export const getProjectForPosition = (projectRevisionRef, projects) => {
  return projects.find((p) => p.LiveProjectRevisionRef === projectRevisionRef);
};

export const getLoadDate = (positionRef, loads, isFromDate) => {
  let posLoads = loads.filter((x) => x.ProjectPositionRef === positionRef);
  let loadDate = null;

  if (posLoads && posLoads.length > 0) {
    if (isFromDate) {
      loadDate = new Date(
        Math.min.apply(
          null,
          posLoads.map(function (e) {
            return new Date(e.From);
          })
        )
      );
    } else {
      loadDate = new Date(
        Math.max.apply(
          null,
          posLoads.map(function (e) {
            return new Date(e.To);
          })
        )
      );
    }
  }

  return loadDate;
};

export const getPositionDesc = (
  orgUnitPosTypeRef,
  orgUnitPositions,
  posTypes
) => {
  let orgUnitPosType = orgUnitPositions.find(
    (x) => x.PrimKey === orgUnitPosTypeRef
  );
  let posType = posTypes.find(
    (x) => x.PrimKey === orgUnitPosType.PositionTypeRef
  );

  return posType.Description;
};

export const getLoadForPosition = (positionRef, loads) => {
  let from = getLoadDate(positionRef, loads, true);
  let to = getLoadDate(positionRef, loads, false);

  let dateDiff = moment(to).diff(moment(from), "day");

  let posLoads = loads.filter((x) => x.ProjectPositionRef === positionRef);
  let calLoads = posLoads.map((p) => {
    return (p.Load * moment(p.To).diff(moment(p.From), "day")) / dateDiff;
  });

  return calLoads.reduce((a, b) => a + b, 0);
};
