import React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import PriorityNavMoreButton from "./PriorityNavMoreButton";
import "../styles/PriorityNav.css";
import PriorityNavItem from "./PriorityNavItem";
import { checkIfNavItemIsActive } from "../../../shared/Utility/routeUtility";
import { withRouter } from "react-router-dom";

export function PriorityNav(props) {
  const { items, classNames } = props;

  const [navItemsWidth, setNavItemsWidth] = useState([]);
  const [allItems] = useState(items);
  const [priorityItems, setPriorityItems] = useState(items);
  const [moreItems, setMoreItems] = useState([]);
  const [isReadyToDisplayNavBar, setIsReadyToDisplayNavBar] = useState(false);
  const [screenWidth, setScreenWidth] = useState();
  const [isMoreButtonActive, setIsMoreButtonActive] = useState(false);

  const navigationContainerRef = useRef(null);
  const priorityItemsContainerRef = useRef(null);
  const moreItemsContainerRef = useRef(null);

  useEffect(() => {
    const setWindowWidth = (val) => {
      setScreenWidth(val.target.innerWidth);
    };

    window.addEventListener("resize", setWindowWidth);
    return () => {
      window.removeEventListener("resize", setWindowWidth);
    };
  }, []);

  useEffect(() => {
    if (navItemsWidth.length) {
      setIsReadyToDisplayNavBar(true);
    }
  }, [navItemsWidth]);

  useEffect(() => {
    const getNavElements = () => {
      return Array.from(
        priorityItemsContainerRef.current &&
          priorityItemsContainerRef.current.children
          ? priorityItemsContainerRef.current.children
          : []
      );
    };

    // This can be called with nav items with empty width (when we are moving from large screen to small screen from device toolbar).
    // We will set navItemsWidth only the first time when widths are available.
    if (!isReadyToDisplayNavBar) {
      const allItemsWidth = getNavElements().map(
        (item) => item.getBoundingClientRect().width
      );
      if (allItemsWidth.every((width) => width !== 0)) {
        setNavItemsWidth(allItemsWidth);
      }
    }
  }, [screenWidth, isReadyToDisplayNavBar]);

  const getContainerWidth = () => {
    const navigationContainerWidth =
      navigationContainerRef.current &&
      navigationContainerRef.current.offsetWidth
        ? navigationContainerRef.current.offsetWidth
        : 0;
    return Math.floor(navigationContainerWidth);
  };

  const getMoreButtonWidth = () => {
    const moreItemsContainerWidth =
      moreItemsContainerRef.current && moreItemsContainerRef.current.offsetWidth
        ? moreItemsContainerRef.current.offsetWidth
        : 0;
    return Math.ceil(moreItemsContainerWidth);
  };

  const getCutOffIndex = useCallback(
    (totalWidth, initialWidth) => {
      for (let index = 0; index < navItemsWidth.length; index++) {
        const itemWidth = Math.ceil(navItemsWidth[index]);
        if (initialWidth + itemWidth < totalWidth) {
          initialWidth += itemWidth;
        } else {
          return index;
        }
      }
      return navItemsWidth.length;
    },
    [navItemsWidth]
  );

  const updateNavigation = useCallback(() => {
    const containerWidth = getContainerWidth();
    const moreButtonWidth = getMoreButtonWidth();

    const cutOffIndex = getCutOffIndex(containerWidth, moreButtonWidth);

    const newPriorityItem = allItems.slice(0, cutOffIndex);
    const newMoreItems = allItems.slice(cutOffIndex, allItems.length);
    setPriorityItems(newPriorityItem);
    setMoreItems(newMoreItems);
  }, [allItems, getCutOffIndex]);

  useEffect(() => {
    if (isReadyToDisplayNavBar) {
      updateNavigation();
    }
  }, [isReadyToDisplayNavBar, screenWidth, updateNavigation]);

  useEffect(() => {
    // Reset moreButton active status if there is no - more items to display.
    if (!moreItems.length && isMoreButtonActive) {
      setIsMoreButtonActive(false);
    }
  }, [moreItems, isMoreButtonActive]);

  const isMoreItemsAvaliable = () => {
    return !!moreItems.length;
  };

  const handleMoreButtonActiveStatusChanged = (isActive) => {
    setIsMoreButtonActive(isActive);
  };

  return (
    <div
      className={`${
        classNames ? classNames : ""
      } w-full inline-block whitespace-nowrap bg-gray-100 rounded-lg`}
      ref={navigationContainerRef}
    >
      <div
        className={`inline-block relative ${
          isMoreItemsAvaliable()
            ? "priority-nav-items-calculated-width"
            : "w-full"
        }`}
      >
        <div
          ref={priorityItemsContainerRef}
          className={`${
            isReadyToDisplayNavBar ? "flex justify-evenly" : "inline-block"
          } `}
        >
          {priorityItems.map((item) => {
            const { text, route, children, itemId } = item;
            return (
              <PriorityNavItem
                text={text}
                isActive={checkIfNavItemIsActive(
                  props.history.location.pathname,
                  item
                )}
                children={children ? children : []}
                route={route || ""}
                itemId={itemId}
                isNavbarReadyToDisplay={isReadyToDisplayNavBar}
                key={itemId}
              />
            );
          })}
        </div>
      </div>
      {isMoreItemsAvaliable() && (
        <div
          ref={moreItemsContainerRef}
          className={`inline-block text-center p-2 more-priority-items rounded-lg ${
            isMoreButtonActive ? "bg-primary-red-grayed" : ""
          }`}
        >
          <PriorityNavMoreButton
            items={moreItems}
            onMoreButtonActiveStatusChange={handleMoreButtonActiveStatusChanged}
          />
        </div>
      )}
    </div>
  );
}

export default withRouter(PriorityNav);
