import * as utility from "./Utility/commonUtility";
import axios from "axios";
import "../config";
import config from "react-global-configuration";

const getClient = (baseUrl, addApiUrl, authData) => {
  const promineoToken = utility.getPromineoToken(authData);

  if (baseUrl && promineoToken) {
    let axiosInstance = axios.create({
      baseURL: baseUrl,
      headers: {
        Authorization: "Bearer ".concat(promineoToken),
      },
    });

    if (axiosInstance && addApiUrl) {
      axiosInstance.defaults.headers.common["ApiUrl"] =
        utility.getBaseApiUrl(authData);
    }

    return axiosInstance;
  } else {
    console.error("Base url or promineo token was not found.");
    return null;
  }
};

const getPublicClient = (baseUrl) => {
  if (baseUrl) {
    let axiosInstance = axios.create({
      baseURL: baseUrl,
    });

    return axiosInstance;
  } else {
    console.error("Base url was not found.");
    return null;
  }
};

const handleSuccess = (response) => {
  console.debug("Request Successful!", response);
  const responseObject = {
    Status: response.status,
    statusText: response.statusText,
    data: response.data,
  };

  return responseObject;
};

const handleError = (error) => {
  if (error.response) {
    // Server responded with something we don't expect (other than 2XX)
    const errorObject = {
      status: error.response.status,
      error: error.response.data,
    };

    console.error("Server responded with error:", errorObject);
  } else {
    // Something went wrong during the request
    console.error("Error Message:", error.message);
  }

  return Promise.reject(error.response || error.message);
};

const prepareRequest = (baseUrl, addApiUrl, options, authData) => {
  let client = getClient(baseUrl, addApiUrl, authData);
  if (client) {
    return client(options).then(handleSuccess).catch(handleError);
  } else {
    return null;
  }
};

const preparePublicRequest = (baseUrl, options) => {
  let client = getPublicClient(baseUrl);
  if (client) {
    return client(options).then(handleSuccess).catch(handleError);
  } else {
    return null;
  }
};

export const reportServerRequest = (options, authData) => {
  // TODO: /api should be added in web portal DB and removed from here.
  return prepareRequest(
    utility.getBaseReportingServerUrl(authData) + "/api",
    true,
    options,
    authData
  );
};

export const apiRequest = (options, authData) => {
  return prepareRequest(
    utility.getBaseApiUrl(authData),
    false,
    options,
    authData
  );
};

export const portalRequest = (options, authData) => {
  const baseUrl = config.get("portalUrl");
  return prepareRequest(baseUrl, false, options, authData);
};

export const portalPublicRequest = (options) => {
  const baseUrl = config.get("portalUrl");
  return preparePublicRequest(baseUrl, options);
};
