import { Button } from "devextreme-react/button";
import ValidationSummary from "devextreme-react/validation-summary";
import { NumberBox } from "devextreme-react/number-box";
import { TextArea } from "devextreme-react/text-area";

import { Validator, RequiredRule, RangeRule } from "devextreme-react/validator";
import React from "react";
import { connect } from "react-redux";
import { getApiCompatibleModelType } from "../../shared/Utility/dataUtility";
import { GetNewId } from "../../shared/Utility/uidUtility";
import { getToday, addDays } from "../../shared/Utility/dateUtility";
import * as actions from "../../store/actions/leaveActions";
import {
  PromineoSelectBox,
  PromineoTextBox,
  PromineoDateBox
} from "../Common/Controls/ControlList";

class AddEditLeave extends React.Component {
  constructor(props) {
    super(props);
    const selectedLeaveRequest = this.props.selectedLeaveRequest;

    this.initialState = selectedLeaveRequest
      ? {
          id: selectedLeaveRequest.PrimKey,
          title: selectedLeaveRequest.Title,
          leaveType: selectedLeaveRequest.AvailabilityExceptionTypeRef,
          startDate: selectedLeaveRequest.From,
          endDate: selectedLeaveRequest.ToDisplay,
          description: selectedLeaveRequest.Description,
          absencePercentage: selectedLeaveRequest.Availability
            ? this.getAbsencePercentageFromAvailability(
                selectedLeaveRequest.Availability
              )
            : this.DefaultAbsencePercentageValue
        }
      : this.getInitialState();

    this.state = this.initialState;
  }

  DefaultAbsencePercentageValue = 1;

  getAbsencePercentageFromAvailability = availability => {
    const absence = 1 - availability;
    // Round to two decimal places
    const absencePercentage = Math.round(absence * 100) / 100;
    return absencePercentage;
  };

  onInputValueChanged = (controlName, value) => {
    this.setState({
      [controlName]: value
    });
  };

  getInitialState = () => {
    let today = getToday();

    return {
      id: "",
      title: "",
      leaveType: "",
      startDate: today,
      endDate: today,
      description: "",
      absencePercentage: this.DefaultAbsencePercentageValue
    };
  };

  isInNewMode = () => {
    return this.state.id === "";
  };

  isApprovalRequired = () => {
    var selectedLeaveType = this.props.leaveTypes.find(
      l => l.PrimKey === this.state.leaveType
    );
    return selectedLeaveType.ApprovalNeeded;
  };

  absencePercentageChanged = event => {
    this.setState({
      absencePercentage: event.value
    });
  };

  saveLeave = e => {
    e.preventDefault();

    let saveAsNew = document.activeElement.id === "saveAsNewButton";

    const leaveData = {
      $type: getApiCompatibleModelType(
        "Project",
        "PersonAvailabilityException"
      ),
      PrimKey: this.isInNewMode() || saveAsNew ? GetNewId() : this.state.id,
      RecordState: this.isInNewMode() || saveAsNew ? 2 : 1,
      Title: this.state.title,
      AvailabilityExceptionTypeRef: this.state.leaveType,
      From: this.state.startDate,
      To: addDays(new Date(this.state.endDate), 1),
      Description: this.state.description,
      PersonRef: this.props.myself.PersonRef,
      Availability: this.getAbsencePercentageFromAvailability(
        this.state.absencePercentage
      )
    };

    leaveData.Approval = null;
    leaveData.ApprovalByPersonRef = null;
    leaveData.ApprovalDate = null;

    if (!this.isApprovalRequired()) {
      leaveData.Approval = true;
      leaveData.ApprovalByPersonRef = this.props.myself.PersonRef;
      leaveData.ApprovalDate = getToday();
    }

    this.props.addEditLeave([leaveData]);
  };

  render() {
    const { leaveTypes } = this.props;
    return (
      <div className="container">
        <div className="card">
          <div className="card-header">Add/Edit Leave</div>

          <div className="card-body">
            <form onSubmit={this.saveLeave}>
              <div className="form-row">
                <div className="col-sm-6">
                  <PromineoTextBox
                    id="title"
                    label="Title"
                    defaultValue={this.state.title}
                    onValueChanged={this.onInputValueChanged}
                  />
                </div>

                <div className="col-sm-6">
                  <PromineoSelectBox
                    dataSource={leaveTypes}
                    id="leaveType"
                    label="Leave Type"
                    displayExpr="Title"
                    valueExpr="PrimKey"
                    defaultValue={this.state.leaveType}
                    onValueChanged={this.onInputValueChanged}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col-sm-4">
                  <PromineoDateBox
                    id="startDate"
                    label="Start Date"
                    defaultValue={this.state.startDate}
                    max={this.state.endDate}
                    compareToElementLabel="End Date"
                    onValueChanged={this.onInputValueChanged}
                  />
                </div>

                <div className="col-sm-4">
                  <PromineoDateBox
                    id="endDate"
                    label="End Date"
                    defaultValue={this.state.endDate}
                    onValueChanged={this.onInputValueChanged}
                  />
                </div>
                <div className="col-sm-4">
                  <label htmlFor="absencePercentage">Absence %</label>
                  <NumberBox
                    id="absencePercentage"
                    defaultValue={this.state.absencePercentage}
                    format={"#0%"}
                    step={0.1}
                    min={0}
                    max={1}
                    showSpinButtons={true}
                    onValueChanged={this.absencePercentageChanged}
                  >
                    <Validator>
                      <RequiredRule message={"Absence % is required"} />
                      <RangeRule
                        message={"Absence % must be between 0 to 100"}
                        min={0}
                        max={1}
                      />
                    </Validator>
                  </NumberBox>
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="description">Description</label>
                <TextArea
                  id="description"
                  height={90}
                  value={this.state.description}
                  onValueChanged={e =>
                    this.onInputValueChanged("description", e.value)
                  }
                />
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col">
                    <Button
                      id="saveAsNewButton"
                      className="mr-1"
                      type="success"
                      text="Save as New"
                      useSubmitBehavior={true}
                      disabled={this.state.id === ""}
                    />
                    <Button
                      id="saveButton"
                      text="Save"
                      type="success"
                      useSubmitBehavior={true}
                    />
                  </div>
                  <div className="col">
                    <ValidationSummary id={"summary"} />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    leaveTypes: state.leaveData.leaveTypes,
    myself: state.authData.myself
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addEditLeave: leaveRecord => {
      dispatch(actions.addEditLeave(leaveRecord, ownProps));
    },
    onloadActiveLeaveRequests: () => dispatch(actions.loadActiveLeaveRequests())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEditLeave);
