import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import * as dashboardActions from "../../store/actions/dashboardActions";
import { GetNewId } from "../../shared/Utility/uidUtility";
import { toastError } from "../../shared/Utility/toastUtility";
import { getApiCompatibleModelType } from "../../shared/Utility/dataUtility";
import "devextreme/dist/css/dx.light.compact.css";
import "./../../styles/dashboard/dashboardStyle.css";

class DashboardOperation extends React.Component {
  launchDashboard = () => {
    var result = this.props.validationGroupRef.current.instance.validate();
    if (result.isValid) {
      this.props.history.push("/dashboardViewer");
    }
  };

  generateDashboardLink = () => {
    var result = this.props.validationGroupRef.current.instance.validate();
    if (!result.isValid) return;

    const dashboardLink = {
      $type: getApiCompatibleModelType("Reporting", "DashboardLink"),
      RecordState: 2,
      PrimKey: GetNewId(),
      DashboardRef: this.props.selectedDashboardId,
      FilterRef: this.props.selectedFilterId,
      FilterParamValues: JSON.stringify(this.props.parameters),
    };

    if (dashboardLink.DashboardRef) {
      const dashboardLinkData = [dashboardLink];
      this.props.createAndCopyDashboardLink(dashboardLinkData);
    } else {
      toastError("Dashboard must be selected.");
    }
  };
  render() {
    return (
      <>
        <Button
          className="btn btn-primary dashboardLauncherButton"
          id="launchDashboardButton"
          type="button"
          disabled={!this.props.selectedFilterId}
          onClick={() => {
            this.launchDashboard();
          }}
        >
          Launch Dashboard
        </Button>
        <Button
          id="genrerateDashboardLinkButton"
          className="btn btn-primary dashboardLauncherButton"
          type="button"
          disabled={!this.props.selectedFilterId}
          onClick={() => {
            this.generateDashboardLink();
          }}
        >
          Generate Link
        </Button>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    parameters: state.dashboardData.filterParameters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearAllDashboardSelectionData: () =>
      dispatch(dashboardActions.clearDashboardSelectionData()),
    createAndCopyDashboardLink: (dashboardLinkData) =>
      dispatch(dashboardActions.createAndCopyDashboardLink(dashboardLinkData)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DashboardOperation)
);
