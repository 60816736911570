export const LOAD_TENANT_POSTPROCESSOR_START =
  "LOAD_TENANT_POSTPROCESSOR_START";
export const LOAD_TENANT_POSTPROCESSOR_SUCCESS =
  "LOAD_TENANT_POSTPROCESSOR_SUCCESS";
export const LOAD_TENANT_POSTPROCESSOR_FAILED =
  "LOAD_TENANT_POSTPROCESSOR_FAILED";

export const LOAD_PORTAL_POSTPROCESSOR_START =
  "LOAD_PORTAL_POSTPROCESSOR_START";
export const LOAD_PORTAL_POSTPROCESSOR_SUCCESS =
  "LOAD_PORTAL_POSTPROCESSOR_SUCCESS";
export const LOAD_PORTAL_POSTPROCESSOR_FAILED =
  "LOAD_PORTAL_POSTPROCESSOR_FAILED";

export const ADD_POSTPROCESSOR_START = "ADD_POSTPROCESSOR_START";
export const ADD_POSTPROCESSOR_SUCCESS = "ADD_POSTPROCESSOR_SUCCESS";
export const ADD_POSTPROCESSOR_FAILED = "ADD_POSTPROCESSOR_FAILED";

export const UPDATE_POSTPROCESSOR_START = "UPDATE_POSTPROCESSOR_START";
export const UPDATE_POSTPROCESSOR_SUCCESS = "UPDATE_POSTPROCESSOR_SUCCESS";
export const UPDATE_POSTPROCESSOR_FAILED = "UPDATE_POSTPROCESSOR_FAILED";

export const DELETE_POSTPROCESSOR_START = "DELETE_POSTPROCESSOR_START";
export const DELETE_POSTPROCESSOR_SUCCESS = "DELETE_POSTPROCESSOR_SUCCESS";
export const DELETE_POSTPROCESSOR_FAILED = "DELETE_POSTPROCESSOR_FAILED";
