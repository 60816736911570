import * as actionTypes from "./ActionType/communityActionTypes";
import * as request from "../../shared/request";
import {
  toastError,
  toastException,
  toastSuccess
} from "../../shared/Utility/toastUtility";
import {
  getApiCompatibleModelType,
  isSuccessApiResponse
} from "../../shared/Utility/dataUtility";
import * as criteriaBuilder from "../../shared/criteria/criteriaBuilder";

export const loadCommunityDataSources = selectedCommunity => {
  return (dispatch, getState) => {
    const state = getState();
    if (selectedCommunity && selectedCommunity.id) {
      dispatch(loadCommunityDataSourcesBegin());

      const communityDataSourceLoadRequest = request.portalRequest(
        {
          url: `/Community/${selectedCommunity.id}/DataSources`,
          method: "GET"
        },
        state.authData
      );

      if (communityDataSourceLoadRequest) {
        communityDataSourceLoadRequest
          .then(response => {
            if (isSuccessApiResponse(response)) {
              dispatch(getDownloadableCommunityDataSources(response.data));
            }
          })
          .catch(error => {
            dispatch(loadCommunityDataSourcesFail(error));
          });
      } else {
        loadCommunityDataSourcesFail("Invalid community selection.");
      }
    }
  };
};

export const loadCommunityDataSourcesBegin = () => {
  return {
    type: actionTypes.FETCH_COMMUNITYDATASOURCE_START
  };
};

export const loadCommunityDataSourcesSuccess = dataSources => {
  return {
    type: actionTypes.FETCH_COMMUNITYDATASOURCE_SUCCESS,
    dataSources
  };
};

export const loadCommunityDataSourcesFail = error => {
  toastError("Failed to load community datasources.");

  return {
    type: actionTypes.FETCH_COMMUNITYDATASOURCE_FAIL,
    error
  };
};

const getDownloadableCommunityDataSources = availableCommunityDataSources => {
  return (dispatch, getState) => {
    const state = getState();

    // Filter out existing data sources from available community data sources
    const dataSourceLoadRequest = request.apiRequest(
      {
        url: "/select/t_Reporting_DataSource",
        method: "POST"
      },
      state.authData
    );

    if (dataSourceLoadRequest) {
      dataSourceLoadRequest
        .then(response => {
          if (isSuccessApiResponse(response)) {
            const existingDataSourceKeys = response.data[0].$values.map(ds => {
              return ds.PrimKey;
            });
            const downloadableDataSources = availableCommunityDataSources.filter(
              communityDataSource => {
                return (
                  existingDataSourceKeys.indexOf(communityDataSource.id) < 0
                );
              }
            );
            dispatch(loadCommunityDataSourcesSuccess(downloadableDataSources));
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  };
};

// Data source Selection Changed
export const dataSourceSelectionChanged = selectedDataSources => {
  return {
    type: actionTypes.COMMUNITYDATASOURCE_SELECTION_CHANGED,
    selectedDataSources: selectedDataSources
  };
};

// Update Cut Off type
export const updateCalendarSelection = calendar => {
  return {
    type: actionTypes.DEFAULTCALENDAR_SELECTION_CHANGED,
    defaultCalendarId: calendar
  };
};
// Update calendar
export const updateCutOffTypeSelection = cutOffTypeId => {
  return {
    type: actionTypes.CUTOFFTYPE_SELECTION_CHANGED,
    cutOffTypeId
  };
};

// Download community data sources

// This action method is called to download community datasources from community datasource grid
// Upon successfull download, the grid is refreshed.
export const downloadOnlySelectedCommunityDataSources = () => {
  return (dispatch, getState) => {
    const state = getState();
    const selectedCommunityDataSources =
      state.communityData.selectedDataSources;
    dispatch(downloadCommunityDataSources(selectedCommunityDataSources)).then(
      () => {
        dispatch(
          loadCommunityDataSources(
            state.communityData.selectedCommunityForDataSource
          )
        );
      }
    );
  };
};

export const downloadCommunityDataSources = selectedCommunityDataSources => {
  return (dispatch, getState) => {
    dispatch(downloadCommunityDataSourcesBegin());

    const state = getState();
    if (
      !selectedCommunityDataSources ||
      selectedCommunityDataSources.length === 0
    ) {
      dispatch(
        downloadCommunityDataSourcesFail(
          "Please select data source to download"
        )
      );
    } else {
      let dataSourceInsertData = [];
      let foundError = false;
      selectedCommunityDataSources.map(communityDataSource => {
        const apiCompatibleDataSourceInsertModel = getAPICompatibleDataSourceInsertModel(
          communityDataSource.definition,
          state.communityData
        );
        if (apiCompatibleDataSourceInsertModel === null) {
          foundError = true;
        }
        return dataSourceInsertData.push(...apiCompatibleDataSourceInsertModel);
      });
      if (!foundError) {
        const dataSourceInsertRequest = request.apiRequest(
          {
            url: "/save",
            method: "POST",
            data: dataSourceInsertData
          },
          state.authData
        );

        if (dataSourceInsertRequest) {
          return dataSourceInsertRequest
            .then(response => {
              if (isSuccessApiResponse(response)) {
                dispatch(downloadCommunityDataSourcesSuccess());
              }
            })
            .catch(error => {
              console.log(error);
              dispatch(downloadCommunityDataSourcesFail(error));
              return Promise.reject(error);
            });
        }
      } else {
        return Promise.reject("Error occurred while downloading datasource");
      }
    }
  };
};

export const checkIfDataSourceExists = dataSourceId => {
  return (dispatch, getState) => {
    const state = getState();

    let criteria = criteriaBuilder.getEqualsCriteria("PrimKey", dataSourceId);
    criteria.data.c = dataSourceId;

    const dataSourceExistenceRequest = request.apiRequest(
      {
        url: "/exists/v_Reporting_DataSource",
        method: "POST",
        data: criteria
      },
      state.authData
    );

    if (dataSourceExistenceRequest) {
      return dataSourceExistenceRequest
        .then(response => {
          if (isSuccessApiResponse(response)) {
            return response.data;
          }
        })
        .catch(error => {
          console.log(error);
          return Promise.reject(error);
        });
    }
  };
};

export const checkIfDataSourceHasPeriodizer = dataSourceDefinition => {
  const dataSource = JSON.parse(dataSourceDefinition);
  const hasPeriodizer =
    dataSource.PeriodizerItem && dataSource.PeriodizerItem.length > 0;
  return Promise.resolve(hasPeriodizer);
};

export const downloadCommunityDataSourcesBegin = () => {
  return {
    type: actionTypes.DOWNLOAD_COMMUNITYDATASOURCE_BEGIN
  };
};

export const downloadCommunityDataSourcesSuccess = () => {
  toastSuccess("Successfully downloaded community data sources");
  return {
    type: actionTypes.DOWNLOAD_COMMUNITYDATASOURCE_SUCCESS
  };
};

export const downloadCommunityDataSourcesFail = error => {
  toastException("Failed to download community data sources ", error);
  return {
    type: actionTypes.DOWNLOAD_COMMUNITYDATASOURCE_FAIL,
    error
  };
};

const getAPICompatibleDataSourceInsertModel = (
  communityDataSourceDef,
  communityData
) => {
  const communityDataSource = JSON.parse(communityDataSourceDef);
  let dataSourceInsertModel = [];

  const dataSourceModel = getDataSourceInsertModel(communityDataSource);
  const itemsModel = getDataSourceItemInsertModel(communityDataSource);
  const itemColumnsModel = getDataSourceItemColumnsInsertModel(
    communityDataSource
  );
  const parameterModel = getDataSourceParameterInsertModel(communityDataSource);
  const filterModel = getDataSourceFilterInsertModel(communityDataSource);
  const filterItemsModel = getDataSourceFilterItemsInsertModel(
    communityDataSource
  );
  const periodizerItemsModel = getDataSourcePeriodizeItemsInsertModel(
    communityDataSource,
    communityData
  );

  if (periodizerItemsModel === null) {
    // Either calendar or cut off type is not selected. Error message already shown.
    return null;
  }
  const periodizerLoad = getDataSourcePeriodizeLoadsInsertModel(
    communityDataSource
  );
  const periodizerAvailability = getDataSourcePeriodizeAvailabilitiesInsertModel(
    communityDataSource
  );
  const periodizerAvailabilityException = getDataSourcePeriodizeAvailabilityExceptionInsertModel(
    communityDataSource
  );
  const periodizerGroupColumn = getDataSourcePeriodizeGroupColumnInsertModel(
    communityDataSource
  );
  const combinedDataSourceItem = getDataSourceCombinedItemsInsertModel(
    communityDataSource
  );
  const combinedDataSourceItemLink = getDataSourceCombinedItemLinksInsertModel(
    communityDataSource
  );

  dataSourceInsertModel.push(dataSourceModel);
  dataSourceInsertModel.push(...itemsModel);
  dataSourceInsertModel.push(...itemColumnsModel);
  dataSourceInsertModel.push(...parameterModel);
  dataSourceInsertModel.push(...filterModel);
  dataSourceInsertModel.push(...filterItemsModel);
  dataSourceInsertModel.push(...periodizerItemsModel);
  dataSourceInsertModel.push(...periodizerLoad);
  dataSourceInsertModel.push(...periodizerAvailability);
  dataSourceInsertModel.push(...periodizerAvailabilityException);
  dataSourceInsertModel.push(...periodizerGroupColumn);
  dataSourceInsertModel.push(...combinedDataSourceItem);
  dataSourceInsertModel.push(...combinedDataSourceItemLink);

  return dataSourceInsertModel;
};

const getDataSourceInsertModel = communityDataSource => {
  if (
    communityDataSource.DataSource &&
    communityDataSource.DataSource.length > 0
  ) {
    const dataSourceModel = {
      ...communityDataSource.DataSource[0],
      ...{
        $type: getApiCompatibleModelType("Reporting", "DataSource"),
        RecordState: 2
      }
    };
    return dataSourceModel;
  }
  return [];
};

const getDataSourceItemInsertModel = communityDataSource => {
  let itemsInsertModel = [];

  if (
    communityDataSource.DataSourceItem &&
    communityDataSource.DataSourceItem.length > 0
  ) {
    communityDataSource.DataSourceItem.map(item => {
      const dataSourceItemModel = {
        ...item,
        ...{
          $type: getApiCompatibleModelType("Reporting", "DataSourceItem"),
          RecordState: 2
        }
      };
      return itemsInsertModel.push(dataSourceItemModel);
    });
  }
  return itemsInsertModel;
};

const getDataSourceItemColumnsInsertModel = communityDataSource => {
  let itemsColumnsInsertModel = [];

  if (
    communityDataSource.DataSourceItemColumn &&
    communityDataSource.DataSourceItemColumn.length > 0
  ) {
    communityDataSource.DataSourceItemColumn.map(itemColumn => {
      const dataSourceItemColumnModel = {
        ...itemColumn,
        ...{
          $type: getApiCompatibleModelType("Reporting", "DataSourceItemColumn"),
          RecordState: 2
        }
      };
      return itemsColumnsInsertModel.push(dataSourceItemColumnModel);
    });
  }
  return itemsColumnsInsertModel;
};

const getDataSourceParameterInsertModel = communityDataSource => {
  let parameterModels = [];

  if (
    communityDataSource.DataSourceParameter &&
    communityDataSource.DataSourceParameter.length > 0
  ) {
    communityDataSource.DataSourceParameter.map(parameter => {
      const parameterModel = {
        ...parameter,
        ...{
          $type: getApiCompatibleModelType("Reporting", "DataSourceParameter"),
          RecordState: 2
        }
      };
      return parameterModels.push(parameterModel);
    });
  }
  return parameterModels;
};

const getDataSourceFilterInsertModel = communityDataSource => {
  let filterInsertModels = [];

  if (
    communityDataSource.DataSourceFilter &&
    communityDataSource.DataSourceFilter.length > 0
  ) {
    communityDataSource.DataSourceFilter.map(filter => {
      const filterModel = {
        ...filter,
        ...{
          $type: getApiCompatibleModelType("Reporting", "DataSourceFilter"),
          RecordState: 2
        }
      };
      return filterInsertModels.push(filterModel);
    });
  }
  return filterInsertModels;
};

const getDataSourceFilterItemsInsertModel = communityDataSource => {
  let filterItemsInsertModels = [];

  if (
    communityDataSource.DataSourceFilterItem &&
    communityDataSource.DataSourceFilterItem.length > 0
  ) {
    communityDataSource.DataSourceFilterItem.map(filterItem => {
      const filterItemModel = {
        ...filterItem,
        ...{
          $type: getApiCompatibleModelType("Reporting", "DataSourceFilterItem"),
          RecordState: 2
        }
      };
      return filterItemsInsertModels.push(filterItemModel);
    });
  }
  return filterItemsInsertModels;
};

const getDataSourcePeriodizeItemsInsertModel = (
  communityDataSource,
  communityData
) => {
  let periodizerItemsInsertModels = [];
  if (
    communityDataSource.PeriodizerItem &&
    communityDataSource.PeriodizerItem.length > 0
  ) {
    communityDataSource.PeriodizerItem.map(periodizerItem => {
      let periodizerItemModel = {
        ...periodizerItem,
        ...{
          $type: getApiCompatibleModelType("Reporting", "PeriodizerItem"),
          RecordState: 2
        }
      };

      if (communityData.defaultCalendarForDataSourceId) {
        periodizerItemModel.DefaultCalendarRef =
          communityData.defaultCalendarForDataSourceId;
      } else {
        toastError("Please select default calendar.");
        return null;
      }

      if (communityData.cutOffTypeForDataSourceId) {
        periodizerItemModel.CutOffTypeRef =
          communityData.cutOffTypeForDataSourceId;
      } else {
        toastError("Please select cut off type.");
        return null;
      }
      return periodizerItemsInsertModels.push(periodizerItemModel);
    });
  }

  return periodizerItemsInsertModels;
};

const getDataSourcePeriodizeLoadsInsertModel = communityDataSource => {
  let periodizerLoadsInsertModels = [];

  if (
    communityDataSource.PeriodizerLoad &&
    communityDataSource.PeriodizerLoad.length > 0
  ) {
    communityDataSource.PeriodizerLoad.map(periodizerLoad => {
      const periodizerLoadModel = {
        ...periodizerLoad,
        ...{
          $type: getApiCompatibleModelType("Reporting", "PeriodizerLoad"),
          RecordState: 2
        }
      };
      return periodizerLoadsInsertModels.push(periodizerLoadModel);
    });
  }
  return periodizerLoadsInsertModels;
};

const getDataSourcePeriodizeAvailabilitiesInsertModel = communityDataSource => {
  let periodizerAvailabilityInsertModels = [];

  if (
    communityDataSource.PeriodizerAvailability &&
    communityDataSource.PeriodizerAvailability.length > 0
  ) {
    communityDataSource.PeriodizerAvailability.map(periodizerAvailability => {
      const periodizerAvailabilityModel = {
        ...periodizerAvailability,
        ...{
          $type: getApiCompatibleModelType(
            "Reporting",
            "PeriodizerAvailability"
          ),
          RecordState: 2
        }
      };
      return periodizerAvailabilityInsertModels.push(
        periodizerAvailabilityModel
      );
    });
  }
  return periodizerAvailabilityInsertModels;
};

const getDataSourcePeriodizeAvailabilityExceptionInsertModel = communityDataSource => {
  let periodizerAvailabilityExceptionInsertModels = [];

  if (
    communityDataSource.PeriodizerAvailabilityException &&
    communityDataSource.PeriodizerAvailabilityException.length > 0
  ) {
    communityDataSource.PeriodizerAvailabilityException.map(
      periodizerAvailabilityException => {
        const periodizerAvailabilityExceptionModel = {
          ...periodizerAvailabilityException,
          ...{
            $type: getApiCompatibleModelType(
              "Reporting",
              "PeriodizerAvailabilityException"
            ),
            RecordState: 2
          }
        };
        return periodizerAvailabilityExceptionInsertModels.push(
          periodizerAvailabilityExceptionModel
        );
      }
    );
  }
  return periodizerAvailabilityExceptionInsertModels;
};

const getDataSourcePeriodizeGroupColumnInsertModel = communityDataSource => {
  let periodizerGroupColumnInsertModels = [];

  if (
    communityDataSource.PeriodizerGroupColumn &&
    communityDataSource.PeriodizerGroupColumn.length > 0
  ) {
    communityDataSource.PeriodizerGroupColumn.map(periodizerGroupColumn => {
      const periodizerGroupColumnModel = {
        ...periodizerGroupColumn,
        ...{
          $type: getApiCompatibleModelType(
            "Reporting",
            "PeriodizerGroupColumn"
          ),
          RecordState: 2
        }
      };
      return periodizerGroupColumnInsertModels.push(periodizerGroupColumnModel);
    });
  }
  return periodizerGroupColumnInsertModels;
};

const getDataSourceCombinedItemsInsertModel = communityDataSource => {
  let combinedItemsInsertModels = [];

  if (
    communityDataSource.CombinedDataSourceItem &&
    communityDataSource.CombinedDataSourceItem.length > 0
  ) {
    communityDataSource.CombinedDataSourceItem.map(combinedItem => {
      const combinedItemModel = {
        ...combinedItem,
        ...{
          $type: getApiCompatibleModelType(
            "Reporting",
            "CombinedDataSourceItem"
          ),
          RecordState: 2
        }
      };
      return combinedItemsInsertModels.push(combinedItemModel);
    });
  }
  return combinedItemsInsertModels;
};

const getDataSourceCombinedItemLinksInsertModel = communityDataSource => {
  let combinedItemLinksInsertModels = [];

  if (
    communityDataSource.CombinedDataSourceItemLink &&
    communityDataSource.CombinedDataSourceItemLink.length > 0
  ) {
    communityDataSource.CombinedDataSourceItemLink.map(combinedItemLink => {
      const combinedItemLinkModel = {
        ...combinedItemLink,
        ...{
          $type: getApiCompatibleModelType(
            "Reporting",
            "CombinedDataSourceItemLink"
          ),
          RecordState: 2
        }
      };
      return combinedItemLinksInsertModels.push(combinedItemLinkModel);
    });
  }
  return combinedItemLinksInsertModels;
};

// Community Selection
export const communitySelectionChanged = data => {
  return {
    type: actionTypes.DATASOURCE_COMMUNITY_SELECTION_CHANGED,
    selectedCommunity: data.addedItems[0]
  };
};
