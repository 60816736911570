import React from "react";
import { connect } from "react-redux";
import { TextBox } from "devextreme-react/text-box";
import Button from "devextreme-react/button";
import ValidationSummary from "devextreme-react/validation-summary";
import { Validator, RequiredRule, EmailRule } from "devextreme-react/validator";
import { Container, Row, Col } from "react-bootstrap";
import LoadingPanel from "../Common/LoadingPanelComponent";
import * as actions from "../../store/actions/portalTenantUserActions";

class TenantUserSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null
    };
  }

  createUser = e => {
    e.preventDefault();
    this.props.createTeantAndPortalUser(this.state.email);
  };

  onInputValueChanged = (controlName, value) => {
    this.setState({
      [controlName]: value
    });
  };

  render() {
    const { isLoading } = this.props;
    return (
      <form onSubmit={this.createUser}>
        <Container id="createUserContainer" className="pt-3">
          <LoadingPanel
            visible={isLoading}
            position={{ of: "#createUserContainer" }}
          ></LoadingPanel>
          <Row>
            <Col>
              <TextBox
                id={"searchEmailTexbox"}
                key={"searchEmailTexbox"}
                placeholder={"Email"}
                onValueChanged={e => this.onInputValueChanged("email", e.value)}
              >
                <Validator>
                  <RequiredRule message={"Email is required"} />
                  <EmailRule message={"Email is invalid"} />
                </Validator>
              </TextBox>
            </Col>
            <Col>
              <Button
                id={"createUserButton"}
                key={"createUserButton"}
                text={"Create User"}
                type={"success"}
                useSubmitBehavior={true}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="form-group">
                <ValidationSummary id={"summary"} />
              </div>
            </Col>
          </Row>
        </Container>
      </form>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.tenantData.loading || state.securityData.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createTeantAndPortalUser: email =>
      dispatch(actions.createPortalAndTenantUserFromEmail(email))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TenantUserSettings);
