
// tableInfo could be direct table name eg: t_Support_ArticleRevision or the model name with proper namespace eg: PromineoR2Data.Project.ProjectRevision

export const create = (tableInfo, foreignKeyField, primaryKeyField, condition) => {
  let criteria = {
    __type:
      "PData.Sql.ExistsCriteria`1[[" + tableInfo + (tableInfo.includes(",") ? "" : ",") + "]]",
    data: {
      f: foreignKeyField,
      p: primaryKeyField,
      o: "=",
      c: condition
    }
  };

  return criteria;
};
