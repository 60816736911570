export const FETCH_CALENDAR_BEGIN = "FETCH_CALENDAR_BEGIN";
export const FETCH_CALENDAR_SUCCESS = "FETCH_CALENDAR_SUCCESS";
export const FETCH_CALENDAR_FAIL = "FETCH_CALENDAR_FAIL";

export const FETCH_CUTOFFTYPE_BEGIN = "FETCH_CUTOFFTYPE_BEGIN";
export const FETCH_CUTOFFTYPE_SUCCESS = "FETCH_CUTOFFTYPE_SUCCESS";
export const FETCH_CUTOFFTYPE_FAIL = "FETCH_CUTOFFTYPE_FAIL";

export const FETCH_CURRENTREVISION_BEGIN = "FETCH_CURRENTREVISION_BEGIN";
export const FETCH_CURRENTREVISION_SUCCESS = "FETCH_CURRENTREVISION_SUCCESS";
export const FETCH_CURRENTREVISION_FAIL = "FETCH_CURRENTREVISION_FAIL";

export const FETCH_USERFIELDS_BEGIN = "FETCH_USERFIELDS_BEGIN";
export const FETCH_USERFIELDS_SUCCESS = "FETCH_USERFIELDS_SUCCESS";
export const FETCH_USERFIELDS_FAIL = "FETCH_USERFIELDS_FAIL";
