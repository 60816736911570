import React from "react";
import { connect } from "react-redux";
import { hasAccessPermission } from "../shared/Utility/commonUtility";
import { applicationRightFlags } from "../shared/Enums/applicationRightFlags";
import HeaderBanner from "./Landing/HeaderBanner";
import NavBar from "./NavigationBar/NavBar/Navbar";
import Account from "./NavigationBar/Account";
import PriorityNav from "./NavigationBar/priority-navigation-bar/PriorityNav";
import * as routePath from "../shared/Constants/routePathConstants";

const Header = (props) => {
  const getNavItems = () => {
    const items = [
      {
        text: "Home",
        itemId: "home",
        route: routePath.HOME,
      },
    ];

    if (hasAccessPermission(props.appRights, applicationRightFlags.Wiki)) {
      items.push({
        text: "Wiki",
        itemId: "wiki",
        route: routePath.WIKI,
      });
    }

    if (hasAccessPermission(props.appRights, applicationRightFlags.Dashboard)) {
      items.push({
        text: "Dashboard",
        itemId: "Dashboard",
        route: routePath.DASHBOARD,
      });
    }

    if (
      hasAccessPermission(
        props.appRights,
        applicationRightFlags.AbsenceRegister
      )
    ) {
      items.push({
        text: "Register Leave",
        itemId: "registerleave",
        route: routePath.REGISTER_LEAVE,
      });
    }

    if (props.isPortalAdmin) {
      items.push({
        text: "Uploads",
        itemId: "communityuploads",
        route: routePath.COMMUNITY_UPLOADS,
      });
    }

    return items;
  };

  return (
    <header className="mb-8 px-4 mt-8">
      <nav className="flex flex-wrap justify-between">
        <HeaderBanner />
        <div className="mt-2 hidden xl:inline-block">
          <NavBar items={getNavItems()} />
        </div>
        <div className="mt-1">
          <Account />
        </div>
      </nav>
      <div className="w-full inline-block xl:hidden mt-4">
        {props.appRightsLoaded && <PriorityNav items={getNavItems()} />}
      </div>
    </header>
  );
};

const mapStateToProps = (state, props) => {
  return {
    appRights: state.authData.appRights,
    appRightsLoaded: state.authData.appRightsLoaded,
    isPortalAdmin: state.authData.isPortalAdmin,
  };
};

export default connect(mapStateToProps)(Header);
