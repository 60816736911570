export const checkIfIsActiveRoute = (locationPath, expectedRoute) => {
  return locationPath === expectedRoute;
};

export const checkIfNavItemIsActive = (locationPath, navItem) => {
  if (navItem.isActive !== undefined) {
    return navItem.isActive;
  } else if (navItem.route) {
    return checkIfIsActiveRoute(locationPath, navItem.route);
  } else if (navItem.children) {
    const isActive = !!navItem.children.find(
      (childNav) =>
        childNav.route && checkIfIsActiveRoute(locationPath, childNav.route)
    );
    return isActive;
  }
  return false;
};
