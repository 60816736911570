import React from "react";

export default function Banner() {
  return (
    <div>
      <div className="text-xl font-bold">Resource Management</div>
      <div className="mt-1">
        <div>
          Promineo is a cloud-based software that helps medium and large
          engineering organizations deliver
        </div>
        <div>
          profitable projects by digitalizing the projet staffing process.
        </div>
      </div>
    </div>
  );
}
