import { updateObject } from "../../shared/Utility/commonUtility";
import * as wikiActionTypes from "../actions/ActionType/wikiActionTypes";

const initialState = {
  loading: false,
  error: null,
  wikis: [],
  allTags: [],
  articleTags: [],
  searchText: "",
  selectedTags: [],
  loadingLinkInfoDone: false
};

const loadWikisBegin = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const loadWikisSuccess = (state, action) => {
  return updateObject(state, { loading: false, wikis: action.wikis });
};

const loadWikisFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

const loadWikiTagsBegin = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const loadWikiTagsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    allTags: action.allTags,
    selectedTags: action.allTags,
    articleTags: action.articleTags
  });
};

const loadWikiTagsFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

const setSearchText = (state, action) => {
  return updateObject(state, { searchText: action.searchText });
};

const tagSelectionChanged = (state, action) => {
  return updateObject(state, {
    selectedTags: action.selectedTags
  });
};

const createOrUpdateWikiBegin = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const createOrUpdateWikiSuccess = (state, action) => {
  return updateObject(state, { error: null, loading: false });
};

const createOrUpdateWikiFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

const createOrUpdateTagBegin = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const createOrUpdateTagSuccess = (state, action) => {
  return updateObject(state, { error: null, loading: false });
};

const createOrUpdateTagFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

const loadWikiLinkBegan = state => {
  return updateObject(state, {
    error: null,
    loading: true,
    loadingLinkInfoDone: false
  });
};

const loadWikiLinkSucceeded = (state, action) => {
  return updateObject(state, {
    loading: false,
    loadingLinkInfoDone: true,
    wikiForLink: action.wiki
  });
};

const loadWikiLinkFailed = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

const deleteWikiBegin = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const deleteWikiSuccess = (state, action) => {
  const updatedWikis = state.wikis.filter(
    wiki => wiki.ArticleRef !== action.deletedWiki.PrimKey
  );
  return updateObject(state, { loading: false, wikis: updatedWikis });
};

const deleteWikiFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

const deleteTagBegin = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const deleteTagSuccess = (state, action) => {
  const updatedTags = state.allTags.filter(
    tag => tag.PrimKey !== action.deletedTag.PrimKey
  );
  return updateObject(state, { loading: false, allTags: updatedTags });
};

const deleteTagFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

const wikiReducer = (state = initialState, action) => {
  switch (action.type) {
    case wikiActionTypes.FETCH_WIKIS_BEGIN:
      return loadWikisBegin(state, action);
    case wikiActionTypes.FETCH_WIKIS_SUCCESS:
      return loadWikisSuccess(state, action);
    case wikiActionTypes.FETCH_WIKIS_FAIL:
      return loadWikisFail(state, action);
    case wikiActionTypes.SET_SEARCH_TEXT:
      return setSearchText(state, action);
    case wikiActionTypes.FETCH_WIKI_TAGS_BEGIN:
      return loadWikiTagsBegin(state, action);
    case wikiActionTypes.FETCH_WIKI_TAGS_SUCCESS:
      return loadWikiTagsSuccess(state, action);
    case wikiActionTypes.FETCH_WIKI_TAGS_FAIL:
      return loadWikiTagsFail(state, action);
    case wikiActionTypes.TAG_SELECTION_CHANGED:
      return tagSelectionChanged(state, action);
    case wikiActionTypes.CREATEORUPDATE_WIKI_BEGIN:
      return createOrUpdateWikiBegin(state, action);
    case wikiActionTypes.CREATEORUPDATE_WIKI_SUCCESS:
      return createOrUpdateWikiSuccess(state, action);
    case wikiActionTypes.CREATEORUPDATE_WIKI_FAIL:
      return createOrUpdateWikiFail(state, action);
    case wikiActionTypes.CREATEORUPDATE_TAG_BEGIN:
      return createOrUpdateTagBegin(state, action);
    case wikiActionTypes.CREATEORUPDATE_TAG_SUCCESS:
      return createOrUpdateTagSuccess(state, action);
    case wikiActionTypes.CREATEORUPDATE_TAG_FAIL:
      return createOrUpdateTagFail(state, action);
    case wikiActionTypes.FETCH_WIKI_LINK_BEGIN:
      return loadWikiLinkBegan(state, action);
    case wikiActionTypes.FETCH_WIKI_LINK_SUCCESS:
      return loadWikiLinkSucceeded(state, action);
    case wikiActionTypes.FETCH_WIKI_LINK_FAIL:
      return loadWikiLinkFailed(state, action);
    case wikiActionTypes.DELETE_WIKI_BEGIN:
      return deleteWikiBegin(state, action);
    case wikiActionTypes.DELETE_WIKI_SUCCESS:
      return deleteWikiSuccess(state, action);
    case wikiActionTypes.DELETE_WIKI_FAIL:
      return deleteWikiFail(state, action);
    case wikiActionTypes.DELETE_TAG_BEGIN:
      return deleteTagBegin(state, action);
    case wikiActionTypes.DELETE_TAG_SUCCESS:
      return deleteTagSuccess(state, action);
    case wikiActionTypes.DELETE_TAG_FAIL:
      return deleteTagFail(state, action);

    default:
      return state;
  }
};

export default wikiReducer;
