import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import List from "devextreme-react/list";
import "devextreme/dist/css/dx.light.compact.css";
import WikiListItem from "./WikiListItemComponent";

class WikiList extends React.Component {
  onWikiArticleSelectionChanged = data => {
    if (data && data.addedItems.length > 0) {
      const selectedWiki = data.addedItems[0];

      this.props.history.push("wiki/" + selectedWiki.ArticleRef);
    }
  };

  render() {
    const { wikis, selectedTags, articleTags } = this.props;

    let articleIdsForSelectedTags = articleTags
      .filter(articleTag => {
        return selectedTags.find(tag => {
          return tag.PrimKey === articleTag.TagRef;
        });
      })
      .map(arTag => {
        return arTag.ArticleRef;
      });

    let filteredWikis = wikis.filter(curWiki => {
      return articleIdsForSelectedTags.find(articleRef => {
        return articleRef === curWiki.ArticleRef;
      });
    });

    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="border rounded">
              <List
                dataSource={filteredWikis}
                noDataText={"No wiki found."}
                pageLoadingText={"Loading wikis..."}
                height={"750"}
                pageLoadMode={"scrollBottom"}
                itemComponent={WikiListItem}
                selectionMode={"single"}
                onSelectionChanged={selectedWiki => {
                  this.onWikiArticleSelectionChanged(selectedWiki);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    searchText: state.wikiData.searchText,
    wikis: state.wikiData.wikis,
    selectedTags: state.wikiData.selectedTags,
    articleTags: state.wikiData.articleTags,
    allTags: state.wikiData.allTags
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(WikiList)
);
