import React from "react";
import { Popover } from "devextreme-react/popover";
import "./Styles/PromineoPopover.css";

export default function PromineoPopover(props) {
  const { className, ...rest } = props;
  return (
    <Popover {...rest} className={`promineo-popover ${className}`}></Popover>
  );
}
