import * as actionTypes from "./ActionType/communityActionTypes";
import * as request from "../../shared/request";
import { toastError } from "../../shared/Utility/toastUtility";
import { isSuccessApiResponse } from "../../shared/Utility/dataUtility";

// Load Community
export const loadCommunities = () => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(loadCommunitiesBegin());

    const communityLoadRequest = request.portalRequest(
      {
        url: "/Community",
        method: "GET"
      },
      state.authData
    );

    if (communityLoadRequest) {
      communityLoadRequest
        .then(response => {
          if (isSuccessApiResponse(response)) {
            dispatch(loadCommunitiesSuccess(response.data));
          }
        })
        .catch(error => {
          console.log(error);
          dispatch(loadCommunitiesFail(error));
        });
    }
  };
};

export const loadCommunitiesBegin = () => {
  return {
    type: actionTypes.FETCH_COMMUNITY_START
  };
};

export const loadCommunitiesSuccess = communities => {
  return {
    type: actionTypes.FETCH_COMMUNITY_SUCCESS,
    communities
  };
};

export const loadCommunitiesFail = error => {
  toastError("Failed to load communities.");

  return {
    type: actionTypes.FETCH_COMMUNITY_FAIL,
    error
  };
};

// Clear community dependent data
export const clearCommunityDependentData = () => {
  return {
    type: actionTypes.CLEAR_COMMUNITY_DEPENDENT_DATA
  };
};
