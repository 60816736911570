import React, { useState } from "react";
import AccountPopover from "./AccountPopover";
import { connect } from "react-redux";
import AccountAvatar from "./AccountAvatar";

export function Account(props) {
  const [isAccountPopoverVisible, setIsAccountPopoverVisible] = useState(false);

  const { user } = props;

  const handleAccountPopoverHiding = () => {
    setIsAccountPopoverVisible(false);
  };

  const handleAccountMenuClick = () => {
    setIsAccountPopoverVisible(!isAccountPopoverVisible);
  };

  return (
    <>
      <div className="flex justify-center w-full md:w-auto select-none">
        <div>
          <div
            id="account-menu"
            className={`cursor-pointer pl-4 flex justify-center items-center ${
              isAccountPopoverVisible
                ? "bg-opacity-5 bg-primary-red-grayed rounded-full"
                : ""
            }`}
            onClick={handleAccountMenuClick}
          >
            <div className="text-base font-medium mr-2">Account</div>
            <AccountAvatar
              user={user}
              displayCrossIcon={isAccountPopoverVisible}
            />
          </div>
        </div>
        {user && (
          <AccountPopover
            user={user}
            visible={isAccountPopoverVisible}
            onHiding={handleAccountPopoverHiding}
            target="#account-menu"
          />
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state, props) => {
  return {
    user: state.authData.myself,
  };
};

export default connect(mapStateToProps, null)(Account);
