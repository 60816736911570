import React from "react";
import { ControlVariant } from "../../../shared/Enums/controlVariantEnum";
import { Button as Btn } from "devextreme-react/button";
import "./Styles/Controls.css";
import { ControlSize } from "../../../shared/Enums/controlSizeEnum";
import {
  getSizeSpecificClasses,
  getControlRadius,
} from "../../../shared/Utility/controlUtility";

export default function PromineoButton(props) {
  const { variant, className, size, radiusSize, textSizeClass, ...rest } =
    props;

  const getVariantSpecificClasses = () => {
    if (variant === ControlVariant.primary) {
      return `border-2 border-primary-red text-white bg-primary-red font-extrabold hover:bg-white hover:text-primary-red active:text-white active:border-transparent active:bg-primary-red-grayed ${
        props.disabled ? "border-none bg-gray-4" : ""
      }`;
    }

    if (variant === ControlVariant.basic) {
      return `border-2 font-extrabold hover:text-primary-red ${
        props.disabled ? "border-none bg-gray-4" : ""
      }`;
    }

    if (variant === ControlVariant.basicActive) {
      return `border-2 border-primary-red font-extrabold bg-white text-primary-red ${
        props.disabled ? "border-none bg-gray-4" : ""
      }`;
    }

    return `text-primary-red bg-white font-bold outline-none border-none underline hover:text-gray-2 active:text-primary-red-grayed ${
      props.disabled ? "text-gray-3" : ""
    }`;
  };

  const getClassNames = () => {
    let classNames = `px-2 font-inter ${
      textSizeClass ? textSizeClass : "text-sm"
    } ${getVariantSpecificClasses()} ${getSizeSpecificClasses(
      size
    )} ${getControlRadius(radiusSize)}`;
    return className ? `${className} ${classNames}` : classNames;
  };

  return <Btn className={getClassNames()} {...rest} />;
}

PromineoButton.defaultProps = {
  variant: ControlVariant.primary,
  size: ControlSize.Medium,
  radiusSize: ControlSize.Large,
};
