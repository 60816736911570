import React, { useState } from "react";
import PromineoPopover from "../../Common/PromineoPopover";
import { Offset, Position } from "devextreme-react/popover";
import { withRouter } from "react-router-dom";
import SubNavMenuPopover from "../NavBar/SubNavMenuPopover";

export function PriorityNavMoreButton(props) {
  const { items } = props;

  const [isSubRoutesVisible, setIsSubRoutesVisible] = useState(false);
  const [isMoreOptionsShouldDisplayed, setIsMoreOptionsShouldDisplayed] =
    useState(false);

  const closeMoreOptions = () => {
    setIsMoreOptionsShouldDisplayed(false);
    props.onMoreButtonActiveStatusChange(false);
  };

  const displayMoreOptions = () => {
    const isAlreadyDisplayed = isMoreOptionsShouldDisplayed;
    setIsMoreOptionsShouldDisplayed(!isAlreadyDisplayed);
    props.onMoreButtonActiveStatusChange(!isAlreadyDisplayed);
  };

  const handleMenuItemClick = (selectedItem) => {
    if (selectedItem.children && selectedItem.children.length) {
      setIsSubRoutesVisible(true);
    } else if (selectedItem.route) {
      props.history.push(selectedItem.route);
    }
  };

  const Content = () => {
    return (
      <>
        {(items || []).map((item) => {
          return (
            <div key={item.itemId}>
              <div
                id={`priority-nav-more-items-menu-${item.itemId}`}
                className="block cursor-pointer py-1 pl-2 pr-16 font-medium hover:bg-gray-4 active:bg-gray-4 rounded-sm"
                onClick={() => handleMenuItemClick(item)}
              >
                {item.text}
              </div>
              {item.children &&
                item.children.length > 0 &&
                isSubRoutesVisible && (
                  <SubNavMenuPopover
                    menuItems={item.children}
                    target={`#priority-nav-more-items-menu-${item.itemId}`}
                    onClose={() => setIsSubRoutesVisible(false)}
                    onSelect={() => setIsSubRoutesVisible(false)}
                    yOffset={15}
                  />
                )}
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div>
      <div onClick={displayMoreOptions} id="moreNavItems">
        <i className="icon dx-icon-more font-bold" />
      </div>
      <div>
        {isMoreOptionsShouldDisplayed && (
          <PromineoPopover
            width={"auto"}
            height={"auto"}
            visible={true}
            hideOnOutsideClick={true}
            onHiding={closeMoreOptions}
            target={"#moreNavItems"}
          >
            <Position my={{ x: "right", y: "top" }}>
              <Offset y={25} x={26} />
            </Position>
            <Content />
          </PromineoPopover>
        )}
      </div>
    </div>
  );
}

export default withRouter(PriorityNavMoreButton);
