import React from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import TileView from "devextreme-react/tile-view";
import "./../styles/homeStyle.css";
import { hasAccessPermission } from "../shared/Utility/commonUtility";
import { applicationRightFlags } from "../shared/Enums/applicationRightFlags";

export const modules = [
  {
    Name: "Dashboard",
    color: "rgb(0,181,172)",
    redirectPath: "/dashboards",
    appRightFlag: applicationRightFlags.Dashboard
  },
  {
    Name: "Wiki",
    color: "rgb(45,78,109)",
    redirectPath: "/wiki",
    appRightFlag: applicationRightFlags.Wiki
  },
  {
    Name: "Register Leave",
    color: "rgb(11,48,70)",
    redirectPath: "/registerleave",
    appRightFlag: applicationRightFlags.AbsenceRegister
  },
  {
    Name: "Uploads",
    color: "rgb(10,128,150)",
    redirectPath: "/communityuploads",
    visibleForPortalAdmin: true 
  }
];

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.tileItemClicked = this.tileItemClicked.bind(this);
  }

  render() {
    const {appRights, isPortalAdmin} = this.props;

    var modulesHaveAccess = modules.filter(m => {
      return hasAccessPermission(appRights, m.appRightFlag) || (m.visibleForPortalAdmin && isPortalAdmin)
    })

    return (
      <Container>
        <Row>
          <Col>
            <TileView
              items={modulesHaveAccess}
              itemMargin={100}
              itemRender={HomeImage}
              onItemClick={this.tileItemClicked}
            />
          </Col>
        </Row>
      </Container>
    );
  }

  tileItemClicked(e) {
    if (e && e.itemData && e.itemData.redirectPath) {
      this.props.history.push(e.itemData.redirectPath);
    }
  }
}

const mapStateToProps = (state, props) => {
  return {
    appRights: state.authData.appRights,
    isPortalAdmin: state.authData.isPortalAdmin
  };
};

function HomeImage(data) {
  return (
    <div className={"dx-tile-image"} style={{ background: `${data.color}` }}>
      <p className={"tileName"}>{data.Name}</p>
    </div>
  );
}

export default connect(
  mapStateToProps
)(Home);

