import React from "react";
import { Button } from "devextreme-react/button";
import "./../../../../styles/common/stepNavigationStyles.css";

const StepNavigationButton = (props) => {
  return <Button className="text-center stepNavigation-btn" stylingMode="outlined" {...props} />;
};

const StepWizardNavigation = ({
  displayFinish,
  displayContinueButton,
  displayGoBackToFirstStep,
  firstStepCaption,
  firstStep,
  goToStep,
  lastStep,
  nextStep,
  previousStep,
  totalSteps,
  currentStep,
}) => (
  <div>
    {currentStep > 1 && (
      <StepNavigationButton
        text="Go Back"
        type="default"
        onClick={previousStep}
      />
    )}
    {displayGoBackToFirstStep && currentStep > 2 && (
      <StepNavigationButton
        text={firstStepCaption ? firstStepCaption : "Back To First Step"}
        type="default"
        onClick={firstStep}
      />
    )}
    {displayContinueButton && currentStep < totalSteps ? (
      <StepNavigationButton text="Continue" type="success" onClick={nextStep} />
    ) : displayFinish ? (
      <StepNavigationButton text="Finish" type="success" onClick={nextStep} />
    ) : (
      <></>
    )}
  </div>
);

export default StepWizardNavigation;
