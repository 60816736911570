import * as request from "../../shared/request";
import { toastSuccess, toastError } from "../../shared/Utility/toastUtility";
import { isSuccessApiResponse } from "../../shared/Utility/dataUtility";
import * as actionTypes from "./ActionType/securityActionTypes";

export const createSecurityUser = user => {
  return (dispatch, getState) => {
    dispatch(createUserBegin());
    const state = getState();
    if (user) {
      const createUserRequest = request.apiRequest(
        {
          url: "/save",
          method: "POST",
          data: user
        },
        state.authData
      );

      if (createUserRequest) {
        createUserRequest
          .then(response => {
            if (isSuccessApiResponse(response)) {
              dispatch(createUserSuccess());
            }
          })
          .catch(error => {
            console.log(error);
            dispatch(createUserFail(error));
          });
      }
    } else {
      toastError("Invalid user data");
    }
  };
};

const createUserBegin = () => {
  return {
    type: actionTypes.CREATE_USER_BEGIN
  };
};

const createUserSuccess = () => {
  toastSuccess("Successfully created user.");
  return {
    type: actionTypes.CREATE_USER_FAIL
  };
};

const createUserFail = error => {
  if (error) {
    console.error(error);
    toastError("Failed to create user. Error : ", console.error);
  }

  return {
    type: actionTypes.CREATE_USER_FAIL,
    error
  };
};
