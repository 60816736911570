import { ControlSize } from "../Enums/controlSizeEnum";
export const getSizeSpecificClasses = (size) => {
  let className = "h-10";
  if (size && size === ControlSize.Large) {
    return "h-53px";
  }

  if (size && size === ControlSize.Small) {
    return "h-9";
  }

  return className;
};

export const getControlRadius = (radiusSize) => {
  let className = "rounded-sm";
  if (radiusSize && radiusSize === ControlSize.Medium) {
    return "rounded";
  }

  if (radiusSize && radiusSize === ControlSize.Large) {
    return "rounded-lg";
  }

  return className;
};
