
export const create = (dataType, fieldName, values) => {
    let criteria = {
      __type:
        "PData.Sql.InCriteria`1[[" + dataType + ", mscorlib]]",
      data: {
        f: fieldName,
        v: getCriteriaValues(dataType, values)
      }
    };
  
    return criteria;
  };

  const getCriteriaValues = (dataType, values) => {
    return values.map(value => {
      return {
        __type: dataType,
        data: value
      };
    });
  };

  