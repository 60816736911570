import { updateObject } from "../../shared/Utility/commonUtility";
import * as actionTypes from "../actions/ActionType/authActionTypes";

const initialState = {
  idToken: null,
  promineoToken: null,
  expires: null,
  userId: null,
  isPortalAdmin: null,
  selectedTenant: null,
  selectedTenantApiUrl: null,
  selectedTenantWebReportingUrl: null,
  error: null,
  loading: false,
  authRedirectPath: null,
  tenants: [],
  appRights: 0,
  dataAccess: [],
  authenticationProviders: [],
  myself: null,
  silentAuthenticate: false,
  appRightsLoaded: false,
  loggingOut: false
};

const authStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const authSuccess = (state, action) => {
  const authData = action.authData;

  let updatedAuthState = {
    idToken: authData.idToken,
    promineoToken: authData.promineoToken,
    expires: authData.expires,
    isPortalAdmin: authData.isPortalAdmin,
    selectedTenant: null,
    selectedTenantApiUrl: null,
    selectedTenantWebReportingUrl: null,
    userId: null,
    error: null,
    loading: false,
  };

  const selectedTenant = action.authData.selectedTenant;

  if (selectedTenant) {
    updatedAuthState.selectedTenant = selectedTenant;
    updatedAuthState.selectedTenantApiUrl = selectedTenant.ApiUrl;
    updatedAuthState.selectedTenantWebReportingUrl =
      selectedTenant.ReportingUrl;
    updatedAuthState.userId = authData.userId;
  }

  return updateObject(state, updatedAuthState);
};

const loginTenantStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const loginTenantSuccess = (state, action) => {
  const authData = action.authData;
  const filteredTenants = state.tenants.filter(
    (tenant) => tenant.TenantId === authData.tenantId
  );
  if (filteredTenants.length === 1) {
    const selectedTenant = filteredTenants[0];
    return updateObject(state, {
      selectedTenant: selectedTenant,
      selectedTenantApiUrl: selectedTenant.ApiUrl,
      selectedTenantWebReportingUrl: selectedTenant.ReportingUrl,
      promineoToken: authData.promineoToken,
      expires: authData.expires,
      userId: authData.userId,
      error: null,
      loading: false,
    });
  } else {
    return updateObject(state, {
      selectedTenant: null,
      selectedTenantApiUrl: null,
      selectedTenantWebReportingUrl: null,
      promineoToken: null,
      expires: null,
      userId: null,
      error: null,
      loading: false,
    });
  }
};

const loginTenantFail = (state, action) => {
  console.log(action.error);
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const setAuthRedirectPath = (state, action) => {
  return updateObject(state, {
    authRedirectPath: action.path,
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    idToken: null,
    promineoToken: null,
    expires: null,
    userId: null,
    selectedTenant: null,
    selectedTenantApiUrl: null,
    selectedTenantWebReportingUrl: null,
  });
};

const authLogout = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    idToken: null,
    userId: null,
    selectedTenant: null,
    selectedTenantApiUrl: null,
    selectedTenantWebReportingUrl: null,
    promineoToken: null,
    expires: null,
    loggingOut: action.forOidc ? true : false,
  });
};

const authLogoutFinish = (state, action) => {
  return updateObject(state, {
    loggingOut: false,
  });
};

const authSetUserInfo = (state, action) => {
  const userInfo = action.userInfo;

  return updateObject(state, {
    userId: userInfo ? userInfo.userPrincipalName : null,
  });
};

const loadTenantStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const loadTenantSuccess = (state, action) => {
  const tenants = action.tenants;

  if (tenants) {
    return updateObject(state, {
      tenants: tenants,
      error: null,
      loading: false,
    });
  } else {
    return updateObject(state, {
      tenants: [],
      error: null,
      loading: false,
    });
  }
};

const loadTenantFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const loadApplicationRightsStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const loadApplicationRightsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    appRightsLoaded: true,
    appRights: action.appRights,
  });
};

const loadApplicationRightsFail = (state, action) => {
  return updateObject(state, { appRightsLoaded: true, error: action.error, loading: false });
};

const loadDataAccessStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const loadDataAccessSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    dataAccess: action.dataAccess,
  });
};

const loadDataAccessFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

const loadMyselfStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const loadMyselfSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    myself: action.myself,
  });
};

const loadMyselfFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

const loadAuthenticationProvidersStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const loadAuthenticationProvidersSuccess = (state, action) => {
  return updateObject(state, { authenticationProviders: action.providers, loading: false });
};

const loadAuthenticationProvidersFailed = (state, action) => {
  return updateObject(state, { loading: false });
};

const silentAuthenticationStart = (state) => {
  return updateObject(state, { silentAuthenticate: true});
}

const silentAuthenticationFinish = (state) => {
  return updateObject(state, { silentAuthenticate: false});
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_SET_REDIRECT_PATH:
      return setAuthRedirectPath(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.AUTH_LOGOUT_FINISH:
      return authLogoutFinish(state, action);
    case actionTypes.AUTH_SET_USERINFO:
      return authSetUserInfo(state, action);
    case actionTypes.LOGIN_TENANT_START:
      return loginTenantStart(state, action);
    case actionTypes.LOGIN_TENANT_SUCCESS:
      return loginTenantSuccess(state, action);
    case actionTypes.LOGIN_TENANT_FAIL:
      return loginTenantFail(state, action);
    case actionTypes.LOAD_TENANT_START:
      return loadTenantStart(state, action);
    case actionTypes.LOAD_TENANT_SUCCESS:
      return loadTenantSuccess(state, action);
    case actionTypes.LOAD_TENANT_FAIL:
      return loadTenantFail(state, action);
    case actionTypes.LOAD_APPLICATION_RIGHTS_START:
      return loadApplicationRightsStart(state, action);
    case actionTypes.LOAD_APPLICATION_RIGHTS_SUCCESS:
      return loadApplicationRightsSuccess(state, action);
    case actionTypes.LOAD_APPLICATION_RIGHTS_FAIL:
      return loadApplicationRightsFail(state, action);
    case actionTypes.LOAD_DATA_ACCESS_START:
      return loadDataAccessStart(state, action);
    case actionTypes.LOAD_DATA_ACCESS_SUCCESS:
      return loadDataAccessSuccess(state, action);
    case actionTypes.LOAD_DATA_ACCESS_FAIL:
      return loadDataAccessFail(state, action);
    case actionTypes.LOAD_MYSELF_START:
      return loadMyselfStart(state, action);
    case actionTypes.LOAD_MYSELF_SUCCESS:
      return loadMyselfSuccess(state, action);
    case actionTypes.LOAD_MYSELF_FAIL:
      return loadMyselfFail(state, action);
    case actionTypes.LOAD_AUTHENTICATION_PROVIDERS_START:
      return loadAuthenticationProvidersStart(state, action);
    case actionTypes.LOAD_AUTHENTICATION_PROVIDERS_SUCCESS:
      return loadAuthenticationProvidersSuccess(state, action);
    case actionTypes.LOAD_AUTHENTICATION_PROVIDERS_FAIL:
      return loadAuthenticationProvidersFailed(state, action);
    case actionTypes.SILENT_AUTHENTICATION_START:
      return silentAuthenticationStart(state);
    case actionTypes.SILENT_AUTHENTICATION_FINISH:
      return silentAuthenticationFinish(state);
    default:
      return state;
  }
};

export default reducer;
