import React from "react";
import { Button } from "devextreme-react/button";
import { Popup } from "devextreme-react/popup";

class PromineoPopUp extends React.Component {
  getWidth = () => {
    return this.props && this.props.width ? this.props.width : 300;
  };

  getHeight = () => {
    return this.props && this.props.height ? this.props.height : 250;
  };

  getTitle = () => {
    return this.props && this.props.title ? this.props.title : "";
  };

  getDisplayTitle = () => {
    return this.getTitle() === "" ? false : true;
  };

  getDisplayActionButtons = () => {
    return this.props.displayActionButtons !== false;
  };

  handleOkClick = () => {
    if (this.props.handleOkClick) {
      this.props.handleOkClick();
    }
  };

  handleCancelClick = () => {
    if (this.props.handleCancelClick) {
      this.props.handleCancelClick();
    }
  };

  render() {
    return (
      <Popup
        visible={this.props.visible}
        dragEnabled={false}
        hideOnOutsideClick={false}
        showTitle={this.getDisplayTitle()}
        showCloseButton={false}
        title={this.getTitle()}
        width={this.getWidth()}
        height={this.getHeight()}
      >
        <div className="container">
          <div className="row">{this.props.children}</div>
          {this.getDisplayActionButtons() && (
            <div className="row justify-content-center py-2">
              <Button
                className="text-center"
                text="Ok"
                width={80}
                type="default"
                onClick={() => {
                  this.handleOkClick();
                }}
              />
              <Button
                className="ml-3 text-center"
                text="Cancel"
                width={80}
                type="default"
                onClick={() => {
                  this.handleCancelClick();
                }}
              />
            </div>
          )}
        </div>
      </Popup>
    );
  }
}

export default PromineoPopUp;
