import React from "react";
import DataGrid, { Column, Format, Sorting } from "devextreme-react/data-grid";
import { connect } from "react-redux";
import * as actions from "../../store/actions/leaveActions";

class PersonPositions extends React.Component {
  componentDidMount() {
    this.props.onLoadPositions();
  }

  render() {
    const { positions } = this.props;
    
    return (
      <div className="container">
        <div className="card">
          <div className="card-header">Planned Positions</div>
          <div className="card-body">
            <DataGrid
              dataSource={positions}
              selection={{ mode: "single" }}
              hoverStateEnabled={true}
              showRowLines={true}
              showBorders={false}
            >
              <Sorting mode="multiple"/>
              <Column dataField={"ProjectPositionId"} caption={"Position Id"} />
              <Column dataField={"Project"} />
              <Column dataField={"Position"} />
              <Column
                dataField={"From"}
                dataType={"date"}
                defaultSortIndex={1}
                defaultSortOrder="asc"
              />
              <Column
                dataField={"To"}
                dataType={"date"}
                defaultSortIndex={0}
                defaultSortOrder="asc"
              />
              <Column dataField={"Load"}>
                <Format type="percent" />
              </Column>
            </DataGrid>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    positions: state.leaveData.positions,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onLoadPositions: () => dispatch(actions.loadPositions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonPositions);
