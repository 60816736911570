import React from "react";
import TagBox from "devextreme-react/tag-box";
import { Validator, RequiredRule } from "devextreme-react/validator";

class PromineoTagBox extends React.Component {
  getSearchEnabled = () => {
    return this.props.searchEnabled !== undefined
      ? this.props.searchEnabled
      : true;
  };

  getHideSelectedItems = () => {
    return this.props.hideSelectedItems !== undefined
      ? this.props.hideSelectedItems
      : true;
  };

  getShowSelectionControls = () => {
    return this.props.batchSelection !== undefined
      ? this.props.batchSelection
      : false;
  };

  getshowClearButton = () => {
    return this.props.showClearButton !== undefined
      ? this.props.showClearButton
      : true;
  };

  getPlaceHolder = () => {
    return this.props.placeHolder !== undefined ? this.props.placeHolder : "";
  };

  getId = () => {
    return this.props.id !== undefined ? this.props.id : null;
  };

  getdefaultValue = () => {
    return this.props.defaultValue !== undefined ? this.props.defaultValue : [];
  };

  getLabel = () => {
    return this.props.label !== undefined ? this.props.label : "";
  };

  getRequired = () => {
    return this.props.required !== undefined ? this.props.required : true;
  };

  fireValueChangedEvent = value => {
    if (this.props.onValueChanged !== undefined) {
      this.props.onValueChanged(this.getId(), value);
    }
  };

  render() {
    const validatorRules = [];
    const requredValidator = (
      <RequiredRule
        key={`${this.getId()}-RequiredRule`}
        message={`${this.getLabel()} is required`}
      />
    );

    if (this.getRequired()) {
      validatorRules.push(requredValidator);
    }

    return (
      <div className="form-group">
        <label htmlFor={this.getId()}>{this.getLabel()}</label>
        <TagBox
          id={this.getId()}
          hideSelectedItems={this.getHideSelectedItems()}
          showSelectionControls={this.getShowSelectionControls()}
          showClearButton={this.getshowClearButton()}
          placeholder={this.getPlaceHolder()}
          searchEnabled={this.getSearchEnabled()}
          items={this.props.items}
          value={this.getdefaultValue()}
          displayExpr={this.props.displayField}
          ValueExpr={this.props.valueField}
          onValueChanged={e => this.fireValueChangedEvent(e.value)}
        >
          <Validator>{validatorRules}</Validator>
        </TagBox>
      </div>
    );
  }
}

export default PromineoTagBox;
