import { updateObject } from "../../shared/Utility/commonUtility";
import * as actionTypes from "../actions/ActionType/postProcessorActionTypes";

const initialState = {
  loading: false,
  error: null,
  tenantPostProcessors: [],
  portalPostProcessors: [],
};

const loadTenantPostProcessorsBegin = (state) => {
  return updateObject(state, {
    error: null,
    loading: true,
    tenantPostProcessors: [],
  });
};

const loadTenantPostProcessorsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    tenantPostProcessors: action.payload,
  });
};

const loadTenantPostProcessorsFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

const loadPortalPostProcessorsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    portalPostProcessors: action.payload,
  });
};

const loadPortalPostProcessorsBegin = (state) => {
  return updateObject(state, {
    error: null,
    loading: true,
    portalPostProcessors: [],
  });
};

const loadPortalPostProcessorsFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

// Add PostProcessor

const addPostProcessorsBegin = (state) => {
  return updateObject(state, { error: null, loading: true });
};

const addPostProcessorsFailed = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

const addPostProcessorsSucceed = (state, action) => {
  return updateObject(state, {
    loading: false,
    tenantPostProcessors: [...state.tenantPostProcessors, action.payload],
  });
};

// Update PostProcessor

const updatePostProcessorBegin = (state) => {
  return updateObject(state, { error: null, loading: true });
};

const updatePostProcessorFailed = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

const updatePostProcessorSucceed = (state, action) => {
  const updatedPostProcessor = action.payload;
  return updateObject(state, {
    loading: false,
    tenantPostProcessors: state.tenantPostProcessors.map((pp) => {
      if (pp.PrimKey === updatedPostProcessor.PrimKey) {
        return updatedPostProcessor;
      }
      return pp;
    }),
  });
};

// Delete PostProcessor

const deletePostProcessorBegin = (state) => {
  return updateObject(state, { error: null, loading: true });
};

const deletePostProcessorFailed = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

const deletePostProcessorSucceed = (state, action) => {
  return updateObject(state, {
    loading: false,
    tenantPostProcessors: state.tenantPostProcessors.filter(
      (pp) => pp.PrimKey !== action.payload
    ),
  });
};

const postProcessorReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PORTAL_POSTPROCESSOR_START:
      return loadPortalPostProcessorsBegin(state);
    case actionTypes.LOAD_PORTAL_POSTPROCESSOR_FAILED:
      return loadPortalPostProcessorsFail(state, action);
    case actionTypes.LOAD_PORTAL_POSTPROCESSOR_SUCCESS:
      return loadPortalPostProcessorsSuccess(state, action);

    case actionTypes.LOAD_TENANT_POSTPROCESSOR_START:
      return loadTenantPostProcessorsBegin(state);
    case actionTypes.LOAD_TENANT_POSTPROCESSOR_FAILED:
      return loadTenantPostProcessorsFail(state, action);
    case actionTypes.LOAD_TENANT_POSTPROCESSOR_SUCCESS:
      return loadTenantPostProcessorsSuccess(state, action);

    case actionTypes.ADD_POSTPROCESSOR_START:
      return addPostProcessorsBegin(state);
    case actionTypes.ADD_POSTPROCESSOR_FAILED:
      return addPostProcessorsFailed(state, action);
    case actionTypes.ADD_POSTPROCESSOR_SUCCESS:
      return addPostProcessorsSucceed(state, action);

    case actionTypes.UPDATE_POSTPROCESSOR_START:
      return updatePostProcessorBegin(state);
    case actionTypes.UPDATE_POSTPROCESSOR_FAILED:
      return updatePostProcessorFailed(state, action);
    case actionTypes.UPDATE_POSTPROCESSOR_SUCCESS:
      return updatePostProcessorSucceed(state, action);

    case actionTypes.DELETE_POSTPROCESSOR_START:
      return deletePostProcessorBegin(state);
    case actionTypes.DELETE_POSTPROCESSOR_FAILED:
      return deletePostProcessorFailed(state, action);
    case actionTypes.DELETE_POSTPROCESSOR_SUCCESS:
      return deletePostProcessorSucceed(state, action);

    default:
      return state;
  }
};

export default postProcessorReducer;
