import React, { useState, useEffect } from "react";
import { Column, Editing } from "devextreme-react/data-grid";
import { Container } from "react-bootstrap";
import { PromineoGrid } from "../Common/Controls/ControlList";
import PostProcessorAddDialogComponent from "./PostProcessorAddDialogComponent";
import * as postProcessorActions from "../../store/actions/postProcessorActions";
import { connect } from "react-redux";

export function PostProcessorSettingsComponent(props) {
  useEffect(() => {
    props.getTenantPostProcessors();
  }, []);

  const [isAddPostProcessorDialogVisible, setIsAddPostProcessorDialogVisible] =
    useState(false);

  const getToolbar = () => {
    return {
      items: [
        {
          location: "before",
          widget: "dxButton",
          options: {
            text: "Add Post Processor",
            icon: "plus",
            onClick: () => {
              setIsAddPostProcessorDialogVisible(true);
            },
          },
        },
      ],
    };
  };

  const handleOkClick = (postProcessorToAdd) => {
    props.createNewPostProcessor(postProcessorToAdd);
    setIsAddPostProcessorDialogVisible(false);
  };

  const handleSave = (evt) => {
    props.updatePostProcessor(evt.data);
  };

  const handleDelete = (evt) => {
    props.deletePostProcessor(evt.data);
    evt.cancel = true;
  };

  return (
    <Container className="mt-3 pb-3">
      <PromineoGrid
        dataSource={props.tenantPostProcessors}
        keyExpression="PrimKey"
        enablePaging={true}
        showBorders={true}
        onRowUpdated={handleSave}
        onRowRemoving={handleDelete}
        defaultPageSize={20}
        toolbar={getToolbar()}
        displaySearchPanel={true}
      >
        <Editing
          mode="row"
          useIcons={true}
          allowUpdating={true}
          allowDeleting={true}
        />
        <Column dataField="Name" caption="Title" allowEditing={false} />
        <Column
          dataField="Identifier"
          caption="Identifier"
          allowEditing={false}
        />
        <Column
          dataField="Description"
          caption="Description"
          allowEditing={true}
        />
        <Column dataField="IsActive" caption="Active" allowEditing={true} />
        <Column type="buttons" width={100} buttons={["edit", "delete"]} />
      </PromineoGrid>
      {isAddPostProcessorDialogVisible && (
        <PostProcessorAddDialogComponent
          tenantPostProcessors={props.tenantPostProcessors}
          onCancelClick={() => setIsAddPostProcessorDialogVisible(false)}
          onOkClick={handleOkClick}
        />
      )}
    </Container>
  );
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    tenantPostProcessors: state.postProcessorData.tenantPostProcessors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTenantPostProcessors: () =>
      dispatch(postProcessorActions.loadTenantsPostProcessors()),
    createNewPostProcessor: (postProcessor) => {
      dispatch(postProcessorActions.addPostProcessor(postProcessor));
    },
    updatePostProcessor: (postProcessor) => {
      dispatch(
        postProcessorActions.updatePostProcessor(
          postProcessor.PrimKey,
          postProcessor
        )
      );
    },
    deletePostProcessor: (postProcessor) => {
      return dispatch(
        postProcessorActions.deletePostProcessor(postProcessor.PrimKey)
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostProcessorSettingsComponent);
