import React from "react";
import { connect } from "react-redux";
import List from "devextreme-react/list";
import "devextreme/dist/css/dx.light.compact.css";
import TagListItem from "./WikiTagListItemComponent";
import * as actions from "../../store/actions/wikiActions";

class WikiTags extends React.Component {
  render() {
    const { tags, onTagSelectionChanged, selectedTags } = this.props;
    
    return (
      <div className="border rounded">
        <List
          dataSource={tags}
          noDataText={"No tag found."}
          pageLoadingText={"Loading tags..."}
          height={"790"}
          pageLoadMode={"scrollBottom"}
          itemComponent={TagListItem}
          selectionMode={"all"}
          showSelectionControls={true}
          selectAllMode={"page"}
          selectedItems={selectedTags}
          onOptionChanged={onTagSelectionChanged}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    tags: props.tags,
    selectedTags: state.wikiData.selectedTags
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTagSelectionChanged: args => {
      if (args.name === "selectedItems") {
        dispatch(actions.tagSelectionChanged(args));
      }
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WikiTags);
