import React from "react";
import Filters from "./../FilterComponent";
import WithDashboardStepNavigation from "./../../hoc/WithDashboardStepNavigation";

const FilterStep = (props) => {
  const handleStepChange = () => {
    props.nextStep();
  };

  return <Filters onStepChange={handleStepChange} {...props}></Filters>;
};

export default WithDashboardStepNavigation(FilterStep);
