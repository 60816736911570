const authenticationProviderStorageKey = "selectedAuthenticationProvider";
const authenticateUsingOidcStorageKey = "authenticateUsingOidc";

export const saveSelectedAuthenticationProvider = (provider) => {
  localStorage.setItem(
    authenticationProviderStorageKey,
    JSON.stringify(provider)
  );
};

export const getSavedAuthenticationProvider = () => {
  const providerAsString = localStorage.getItem(
    authenticationProviderStorageKey
  );
  const provider = JSON.parse(providerAsString);
  return provider;
};

export const authenticateUsingOidc = (usingOidcAuthentication) => {
  localStorage.setItem(authenticateUsingOidcStorageKey, usingOidcAuthentication);
};

export const checkIfAuthenticateWithOidc = () => {
  const authenticationUsingOidc = localStorage.getItem(authenticateUsingOidcStorageKey);
  return JSON.parse(authenticationUsingOidc);
};

export const getSavedAuthenticationProviderId = () => {
  let authenticationProviderFromLocalStorage = getSavedAuthenticationProvider();

  return authenticationProviderFromLocalStorage ? authenticationProviderFromLocalStorage.id : null;
}