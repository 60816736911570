import * as uploadActionTypes from "./ActionType/communityUploadActionTypes";
import * as criteriaBuilder from "../../shared/criteria/criteriaBuilder";
import * as request from "../../shared/request";
import { toastError, toastSuccess } from "../../shared/Utility/toastUtility";
import { isSuccessApiResponse } from "../../shared/Utility/dataUtility";

const DATASOURCE_DETAIL_URL =
  "/select/v_Reporting_DataSource/Items,Parameters,Filters,Items.Columns,Filters.Items,PeriodizerItems.LoadSettings,PeriodizerItems.AvailabilitySettings,PeriodizerItems.AvailabilityExceptionSettings,PeriodizerItems.GroupColumns,CombinedItems.DataSourceItemLinks";

const DASHBOARD_DETAIL_URL =
  "/select/v_Reporting_Dashboard/DashboardDataSourceItems,DashboardPeriodizerItems,DashboardCombinedDataSourceItems";

export const uploadUserFieldDataToCommunity = (
  communityId,
  fields,
  successCallBack
) => {
  return (dispatch, getState) => {
    const state = getState();

    dispatch(uploadFieldsToCommunityStart());

    let hasError = false;
    let errorMessage = "";

    if (!communityId) {
      hasError = true;
      errorMessage = "No community is selected";
    } else if (!fields || fields.length === 0) {
      hasError = true;
      errorMessage = "No user field is selected";
    }

    if (hasError) {
      toastError(errorMessage);
      dispatch(uploadFieldsToCommunityFail(null));
      return Promise.reject(errorMessage);
    }

    const structureIds = fields.reduce((result, field) => {
      if (field.StructureRef) {
        result.push(field.StructureRef);
      }
      return result;
    }, []);

    if (structureIds.length > 0) {
      const structuresToUpload = state.projectData.userFieldStructures.filter(
        structure => {
          return structureIds.indexOf(structure.PrimKey) >= 0;
        }
      );

      dispatch(uploadStructuresToCommunity(structuresToUpload))
        .then(() => {
          dispatch(uploadFieldsToCommunity(communityId, fields))
            .then(() => {
              dispatch(uploadFieldsToCommunitySuccess());
              if (successCallBack) {
                successCallBack();
              }
            })
            .catch(error => {
              dispatch(uploadFieldsToCommunityFail(error));
            });
        })
        .catch(error => {
          dispatch(uploadFieldsToCommunityFail(error));
        });
    } else {
      dispatch(uploadFieldsToCommunity(communityId, fields))
        .then(() => {
          dispatch(uploadFieldsToCommunitySuccess());
          if (successCallBack) {
            successCallBack();
          }
        })
        .catch(error => {
          dispatch(uploadFieldsToCommunityFail(error));
        });
    }
  };
};

export const uploadStructuresToCommunity = structures => {
  return (dispatch, getState) => {
    const state = getState();
    const communityStructureUploadRequest = request.portalRequest(
      {
        url: `/Community/Structure`,
        method: "POST",
        data: structures
      },
      state.authData
    );

    if (communityStructureUploadRequest) {
      return communityStructureUploadRequest
        .then(response => {
          if (isSuccessApiResponse(response)) {
            return response;
          }
        })
        .catch(error => {
          const errorMessage = "Error occurred while uploading structure!";
          console.log(error);
          return Promise.reject(errorMessage);
        });
    }
  };
};

export const uploadFieldsToCommunity = (communityId, fields) => {
  return (dispatch, getState) => {
    const state = getState();
    const communityFieldUploadRequest = request.portalRequest(
      {
        url: `/Community/${communityId}/Field`,
        method: "POST",
        data: fields
      },
      state.authData
    );

    if (communityFieldUploadRequest) {
      return communityFieldUploadRequest
        .then(response => {
          if (isSuccessApiResponse(response)) {
            return response;
          }
        })
        .catch(error => {
          const errorMessage = "Error occurred while uploading fields!";
          console.log(error);
          return Promise.reject(errorMessage);
        });
    }
  };
};

export const uploadFieldsToCommunityStart = () => {
  return {
    type: uploadActionTypes.UPLOAD_FIELDS_COMMUNITY_START
  };
};

export const uploadFieldsToCommunitySuccess = () => {
  toastSuccess("Succesffully uploaded community fields");
  return {
    type: uploadActionTypes.UPLOAD_FIELDS_COMMUNITY_SUCCESS
  };
};

export const uploadFieldsToCommunityFail = error => {
  if (error) {
    toastError("Failed to upload community fields");
    console.error(error);
  }
  return {
    type: uploadActionTypes.UPLOAD_FIELDS_COMMUNITY_FAIL,
    error
  };
};

export const uploadDataSourceToCommunity = (communityId, dataSource) => {
  return (dispatch, getState) => {
    const state = getState();

    if (!communityId) {
      toastError("Select a community to upload");
      return Promise.reject("No community selected");
    }

    dispatch(uploadDataSourceToCommunityStart());

    var criteria = criteriaBuilder.getEqualsCriteria(
      "PrimKey",
      dataSource.PrimKey
    );

    // TODO: Should be moved under datasource actions
    const dataSourceLoadRequest = request.apiRequest(
      {
        url: DATASOURCE_DETAIL_URL,
        method: "POST",
        data: criteria
      },
      state.authData
    );

    if (dataSourceLoadRequest) {
      return dataSourceLoadRequest
        .then(response => {
          if (isSuccessApiResponse(response) && response.data.length > 0) {
            let dataSourceDetails = response.data;
            console.log("DataSource details:", dataSourceDetails);

            const communityDataSourceLoadRequest = request.portalRequest(
              {
                url: `/Community/${communityId}/DataSource`,
                method: "POST",
                data: dataSourceDetails
              },
              state.authData
            );

            if (communityDataSourceLoadRequest) {
              return communityDataSourceLoadRequest
                .then(response => {
                  if (isSuccessApiResponse(response)) {
                    if (dataSource.Name) {
                      toastSuccess(
                        `${dataSource.Name} uploaded to the selected community.`
                      );
                    }
                    dispatch(uploadDataSourceToCommunitySuccess());
                  }
                })
                .catch(error => {
                  let msg = "Error occurred while uploading datasource!";
                  console.log(error);
                  return Promise.reject(msg);
                });
            }
          } else {
            let msg = "No record found for the selected datasource";
            errorOccurredWhileLoading(
              dispatch,
              msg,
              uploadDataSourceToCommunityFail
            );
            return Promise.reject(msg);
          }
        })
        .catch(error => {
          let msg = "Error occurred while uploading datasource!";
          errorOccurredWhileLoading(
            dispatch,
            msg,
            uploadDataSourceToCommunityFail
          );
          return Promise.reject(msg);
        });
    }
  };
};

export const uploadDashbaordToCommunity = (communityId, dashboard) => {
  return (dispatch, getState) => {
    const state = getState();

    let dataSource = { PrimKey: dashboard.DataSourceRef };

    // At first upload the required DataSource for the selected Dashboard
    dispatch(uploadDataSourceToCommunity(communityId, dataSource)).then(x => {
      dispatch(uploadDashboardToCommunityStart());

      var criteria = criteriaBuilder.getEqualsCriteria(
        "PrimKey",
        dashboard.PrimKey
      );

      // Should be moved under dashboard actions
      const dashboardLoadRequest = request.apiRequest(
        {
          url: DASHBOARD_DETAIL_URL,
          method: "POST",
          data: criteria
        },
        state.authData
      );

      // Upload Dashboard
      if (dashboardLoadRequest) {
        return dashboardLoadRequest
          .then(response => {
            if (isSuccessApiResponse(response) && response.data.length > 0) {
              let dashboardDetails = response.data;
              console.log("DataSource details:", dashboardDetails);

              const communityDashboardUploadRequest = request.portalRequest(
                {
                  url: `/Community/${communityId}/Dashboard`,
                  method: "POST",
                  data: dashboardDetails
                },
                state.authData
              );

              if (communityDashboardUploadRequest) {
                return communityDashboardUploadRequest
                  .then(response => {
                    if (isSuccessApiResponse(response)) {
                      toastSuccess(
                        `${dashboard.Title} uploaded to the selected community.`
                      );
                      dispatch(uploadDashboardToCommunitySuccess());
                    }
                  })
                  .catch(error => {
                    dispatch(uploadDashboardToCommunityFail(error));
                  });
              }
            } else {
              errorOccurredWhileLoading(
                dispatch,
                "No record found for the selected dashboard",
                uploadDashboardToCommunityFail
              );
            }
          })
          .catch(error => {
            errorOccurredWhileLoading(
              dispatch,
              error,
              uploadDashboardToCommunityFail
            );
          });
      }
    });
  };
};

const errorOccurredWhileLoading = (dispatch, message, failedEvent) => {
  console.log(message);
  toastError(message);
  dispatch(failedEvent);
};

export const uploadDataSourceToCommunityStart = () => {
  return {
    type: uploadActionTypes.UPLOAD_DATASOURCE_COMMUNITY_START
  };
};

export const uploadDataSourceToCommunitySuccess = () => {
  return {
    type: uploadActionTypes.UPLOAD_DATASOURCE_COMMUNITY_SUCCESS
  };
};

export const uploadDataSourceToCommunityFail = error => {
  return {
    type: uploadActionTypes.UPLOAD_DATASOURCE_COMMUNITY_FAIL,
    error
  };
};

export const uploadDashboardToCommunityStart = () => {
  return {
    type: uploadActionTypes.UPLOAD_DASHBOARD_COMMUNITY_START
  };
};

export const uploadDashboardToCommunitySuccess = () => {
  return {
    type: uploadActionTypes.UPLOAD_DASHBOARD_COMMUNITY_SUCCESS
  };
};

export const uploadDashboardToCommunityFail = error => {
  return {
    type: uploadActionTypes.UPLOAD_DASHBOARD_COMMUNITY_FAIL,
    error
  };
};
