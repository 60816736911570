import React from "react";
import promineoWheel from "../../static/images/promineo-wheel.svg";
import Header from "./Header";
import Banner from "./Banner";
import Footer from "./Footer";

export default function LandingComponent() {
  return (
    <div className="p-8">
      <Header />
      <div className="space-y-8 mt-16">
        <div className="flex justify-center">
          <Banner />
        </div>
        <div className="bg-white-smoke">
          <img src={promineoWheel} alt="promineo-wheel"/>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    </div>
  );
}
