import React from "react";
import { connect } from "react-redux";
import { Button } from "devextreme-react/button";
import WikiList from "./WikiListComponent";
import WikiSearch from "./WikiSearchComponent";
import WikiTags from "./WikiTagsComponent";
import * as actions from "../../store/actions/wikiActions";
import { hasDataAccess } from "../../shared/Utility/commonUtility";
import { accessBits } from "../../shared/Enums/accessBits";


class Wiki extends React.Component {
  componentDidMount() {
    this.props.loadWikiTags();
  }
  onNewAricleClicked = () => {
    this.props.history.push({
      pathname: "/wikiEditor",
      state: {
        tags: this.props.tags
      }
    });
  };
  onAddTagClicked = () => {
    this.props.history.push("/tagEditor");
  };

  render() {
    const { tags, dataAccess } = this.props;

    return (
      <div className="container">
        <div className="row">
          <div className="col">
            <h4>Wiki</h4>
          </div>
          <div className="col">
          { hasDataAccess(dataAccess, "Wiki", accessBits.Insert) &&
            <Button 
              width={90}
              text="New Article"
              className="float-right"
              type="default"
              stylingMode="text"
              onClick={this.onNewAricleClicked}
            />
          }
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <div className="row">
              <div className="col">
              { hasDataAccess(dataAccess, "Wiki", accessBits.Insert) &&
                <Button
                  width={90}
                  text="Add Tag"
                  className="float-right"
                  type="default"
                  stylingMode="text"
                  onClick={this.onAddTagClicked}
                />
              }

              </div>
            </div>
            <div className="row">
              <div className="col">
                <WikiTags tags={tags} />
              </div>
            </div>
          </div>
          <div className="col">
            <WikiSearch />
            <WikiList />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    tags: state.wikiData.allTags,
    dataAccess: state.authData.dataAccess
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadWikiTags: () => dispatch(actions.loadWikiTags())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Wiki);
