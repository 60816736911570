
import { updateObject } from "../../shared/Utility/commonUtility";
import * as leaveActionTypes from "../actions/ActionType/leaveActionTypes";

const initialState = {
  loading: false,
  error: null,
  leaveTypes: [],
  leaveRequests: [],
  positions: []
};


const loadLeaveTypesBegin = (state, action) => {
    return updateObject(state, { error: null, loading: true });
  };
  
  const loadLeaveTypesSuccess = (state, action) => {
    return updateObject(state, { loading: false, leaveTypes: action.leaveTypes });
  };
  
  const loadLeaveTypesFail = (state, action) => {
    return updateObject(state, { error: action.error, loading: false });
  };

  const loadActiveLeaveRequestsBegin = (state, action) => {
    return updateObject(state, { error: null, loading: true });
  };
  
  const loadActiveLeaveRequestsSuccess = (state, action) => {
    return updateObject(state, { loading: false, leaveRequests: action.leaveRequests });
  };
  
  const loadActiveLeaveRequestsFail = (state, action) => {
    return updateObject(state, { error: action.error, loading: false });
  };

  const loadPositionsBegin = (state, action) => {
    return updateObject(state, { error: null, loading: true });
  };
  
  const loadPositionsSuccess = (state, action) => {
    return updateObject(state, { loading: false, positions: action.positions });
  };
  
  const loadPositionsFail = (state, action) => {
    return updateObject(state, { error: action.error, loading: false });
  };

  const leaveReducer = (state = initialState, action) => {
    switch (action.type) {
      case leaveActionTypes.LOAD_LEAVE_TYPES_BEGIN:
        return loadLeaveTypesBegin(state, action);
      case leaveActionTypes.LOAD_LEAVE_TYPES_SUCCESS:
        return loadLeaveTypesSuccess(state, action);
      case leaveActionTypes.LOAD_LEAVE_TYPES_FAIL:
        return loadLeaveTypesFail(state, action);
      case leaveActionTypes.LOAD_ACTIVE_LEAVE_REQUESTS_BEGIN:
        return loadActiveLeaveRequestsBegin(state, action);
      case leaveActionTypes.LOAD_ACTIVE_LEAVE_REQUESTS_SUCCESS:
        return loadActiveLeaveRequestsSuccess(state, action);
      case leaveActionTypes.LOAD_ACTIVE_LEAVE_REQUESTS_FAIL:
        return loadActiveLeaveRequestsFail(state, action);
      case leaveActionTypes.LOAD_POSITIONS_BEGIN:
        return loadPositionsBegin(state, action);
      case leaveActionTypes.LOAD_POSITIONS_SUCCESS:
        return loadPositionsSuccess(state, action);
      case leaveActionTypes.LOAD_POSITIONS_FAIL:
        return loadPositionsFail(state, action);
      default:
        return state;
    }
  };
  
  export default leaveReducer;
  