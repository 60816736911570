import React from "react";
import DateBox from 'devextreme-react/date-box'
import { Validator, RequiredRule, RangeRule } from "devextreme-react/validator";
import moment from "moment";

class PromineoDateBox extends React.Component {
  getShowClearButton = () => {
    return this.props.showClearButton !== undefined
      ? this.props.showClearButton
      : false;
  };

  getDefaultValue = () => {
    return this.props.defaultValue ? this.props.defaultValue : "";
  }

  getPlaceHolder = () => {
    return this.props.placeHolder !== undefined ? this.props.placeHolder : "";
  };

  getDisabled = () => {
    return this.props.disabled !== undefined ? this.props.disabled : false;
  };

  getReadOnly = () => {
    return this.props.readOnly !== undefined ? this.props.readOnly : false;
  };

  getRequired = () => {
    return this.props.required !== undefined ? this.props.required : true;
  };

  fireValueChangedEvent = value => {
    if (this.props.onValueChanged !== undefined) {
      this.props.onValueChanged(this.getId(), value);
    }
  };

  getId = () => {
    return this.props.id !== undefined ? this.props.id : null;
  };

  getLabel = () => {
    return this.props.label !== undefined ? this.props.label : "";
  };

  hasMaxLimit = () => {
    return this.props.max !== undefined;
  };

  getMaxLimit = () => {
    return this.props.max;
  };

  getCompareToLabel = () => {
    return this.props.compareToElementLabel
      ? this.props.compareToElementLabel
      : moment(this.getMaxLimit()).format("L");
  };

  render() {
    const validatorRules = [];
    const requredValidator = (
      <RequiredRule
        key={`${this.getId()}-RequiredRule`}
        message={`${this.getLabel()} is required`}
      />
    );

    const maxLimitValidator = (
      <RangeRule
        key={`${this.getId()}-MaxLimitRule`}
        message={`${this.getLabel()} cannot be greater than ${this.getCompareToLabel()}`}
        max={this.getMaxLimit()}
      />
    );

    if (this.getRequired()) {
      validatorRules.push(requredValidator);
    }

    if (this.hasMaxLimit()) {
      validatorRules.push(maxLimitValidator);
    }

    return (
      <div className="form-group">
        <label htmlFor={this.getId()}>{this.getLabel()}</label>
        <DateBox
          id={this.getId()}
          type={"date"}
          showClearButton={this.getShowClearButton()}
          disabled={this.getDisabled()}
          readOnly={this.getReadOnly()}
          placeholder={this.getPlaceHolder()}
          useMaskBehavior={true}
          defaultValue={this.getDefaultValue()}
          displayFormat={"shortdate"}
          onValueChanged={e => this.fireValueChangedEvent(e.value)}
        >
          <Validator>{validatorRules}</Validator>
        </DateBox>
      </div>
    );
  }
}

export default PromineoDateBox;
