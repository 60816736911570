import React from "react";
import Avatar from "../../Common/Avatar";

export default function AccountAvatar(props) {
  const { user, displayCrossIcon } = props;
  return (
    <Avatar
      firstName={user ? user.FirstName : ""}
      lastName={user ? user.LastName : ""}
      displayCrossIcon={displayCrossIcon}
    />
  );
}
