import React from "react";
import { connect } from "react-redux";
import DashboardItem from "./DashboardItemComponent";
import * as actions from "../../store/actions/dashboardActions";
import * as datasourceActions from "../../store/actions/datasourceActions";
import List from "devextreme-react/list";
import "devextreme/dist/css/dx.light.compact.css";

class DashboadList extends React.Component {
  componentDidMount() {
    this.props.onLoadDashboards();
  }

  shouldComponentUpdate(nextProps) {
    // Skip re-renderning on dashboard selection change
    return this.props.selectedDatasourceId === nextProps.selectedDatasourceId;
  }

  handleDashboardSelectionChanged = data => {
    this.props.onDashboardSelectionChanged(data);
    if(this.props.onStepChange){
      this.props.onStepChange();
    }
  };

  render() {
    const { dashboards } = this.props;

    const dashboardDataSource = {
      store: dashboards,
      sort: "Title"
    };

    return (
      <fieldset>
        <legend>1 - Select Dashboard</legend>
        <List
          dataSource={dashboardDataSource}
          noDataText={"No dashboard found."}
          pageLoadingText={"Loading Dashboards.."}
          height={"750"}
          pageLoadMode={"scrollBottom"}
          itemComponent={DashboardItem}
          selectionMode={"single"}
          searchEnabled={true}
          searchExpr={"Title"}
          searchMode={"contains"}
          searchEditorOptions={{
            placeholder: "Enter text to search...",
            height: 30,
            padding: 10
          }}
          onSelectionChanged={this.handleDashboardSelectionChanged}
        />
      </fieldset>
    );
  }
}

const mapStateToProps = state => {
  return {
    dashboards: state.dashboardData.dashboards,
    selectedDatasourceId: state.dashboardData.selectedDatasourceId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLoadDashboards: () => dispatch(actions.loadDashboards()),
    onDashboardSelectionChanged: data => {
      dispatch(actions.dashboardSelectionChanged(data));
      dispatch(datasourceActions.clearLoadedFilterParameterDefinition());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboadList);
