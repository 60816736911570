import React from "react";
import DataGrid, { Column } from "devextreme-react/data-grid";
import { Button } from "devextreme-react/button";
import { connect } from "react-redux";
import * as actions from "../../store/actions/leaveActions";
import { getApiCompatibleModelType } from "../../shared/Utility/dataUtility";
import AcceptIcon from "../../components/Common/icons/AcceptIcon";
import DeclinedIcon from "../../components/Common/icons/DeclinedIcon";
import PendingIcon from "../../components/Common/icons/PendingIcon";
import { Template } from "devextreme-react/core/template";


class LeaveRequestsComponent extends React.Component {
  componentDidMount() {
    this.props.onloadActiveLeaveRequests();
  }

  removeLeaveRequest = (e, recordId) => {
    e.event.stopPropagation();

    const leaveRecord = [
      {
        $type: getApiCompatibleModelType(
          "Project",
          "PersonAvailabilityException"
        ),
        PrimKey: recordId,
        RecordState: 3,
      },
    ];

    if (window.confirm("Are you sure to delete this leave request?")) {
      this.props.onDeleteLeaveRequest(leaveRecord);
    }
  };

  cellRenderRemoveRecord = (data) => {
    return (
      <Button
        key={data.value}
        icon="trash"
        onClick={(e) => this.removeLeaveRequest(e, data.value)}
      />
    );
  };

  onSelectionChanged = (selectedItems) => {
    let selectedLeave = selectedItems.selectedRowsData[0];
    this.props.onSelectionChanged(selectedLeave);
  };

  statusCellRender = (cellData) => {
    if (cellData.data.Approval === undefined) {
      return <PendingIcon />;
    }
    if (cellData.data.Approval) {
      return <AcceptIcon />;
    }

    return <DeclinedIcon />;
  };

  render() {
    const { leaveRequests } = this.props;

    return (
      <div className="container">
        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className="col-4">Leave Requests</div>
              <div className="col-8">
                <div className="float-right">
                  <span className="mr-3">
                    <AcceptIcon /> Accepted
                  </span>
                  <span className="mr-3">
                    <DeclinedIcon /> Rejected
                  </span>
                  <span>
                    <PendingIcon /> Pending
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <DataGrid
              dataSource={leaveRequests}
              selection={{ mode: "single" }}
              hoverStateEnabled={true}
              keyExpr={"PrimKey"}
              showRowLines={true}
              showBorders={false}
              height={330}
              onSelectionChanged={this.onSelectionChanged}
            >
              <Column dataField={"From"} dataType={"date"} sortOrder={"asc"} />
              <Column
                caption={"To"}
                dataField={"ToDisplay"}
                dataType={"date"}
              />
              <Column dataField={"Title"} />
              <Column
                dataField={"Approval"}
                caption={"Status"}
                width={50}
                alignment={"center"}
                allowSorting={true}
                cellTemplate={"statusCellTemplate"}
              />

              <Column
                dataField={"PrimKey"}
                caption={""}
                width={50}
                alignment={"center"}
                allowSorting={false}
                cellRender={this.cellRenderRemoveRecord}
              />

              <Template
                name="statusCellTemplate"
                render={this.statusCellRender}
              />
            </DataGrid>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    leaveRequests: state.leaveData.leaveRequests,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onloadActiveLeaveRequests: () =>
      dispatch(actions.loadActiveLeaveRequests()),
    onDeleteLeaveRequest: (leaveRecord) =>
      dispatch(actions.deleteLeaveRequest(leaveRecord)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeaveRequestsComponent);
