export const getApiCompatibleDataType = data => {
  // TODO: We need to find a way to determing if the data type is a byte array
  let dataType = "";
  switch (typeof data) {
    case "number":
      // TODO: Decide whether it's int32, float, double or decimal
      dataType = "System.Double";
      break;
    case "boolean":
      dataType = "System.Boolean";
      break;
    case "string":
      if (isUniqueIdentifier(data)) {
        dataType = "System.Guid";
      } else {
        dataType = "System.String";
      }
      break;
    default:
      dataType = "System.String";
  }

  return dataType;
};

export const isUniqueIdentifier = data => {
  const uidMatched = data.match(
    "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
  );
  if (uidMatched === null) {
    return false;
  }
  return true;
};

export const getApiCompatibleModelType = (domain, model) => {
  return "PromineoR2Data." + domain + "." + model + "Record";
};

export const isSuccessApiResponse = response => {
  return response && (response.Status === 200 || response.status === 200 || response.Status === 201);
};
