import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import SubNavMenuPopover from "./SubNavMenuPopover";

export function NavItem(props) {
  const { isActive, itemId: navItemId, text, route, children } = props;

  const [isSubmenuVisible, setIsSubmenuVisible] = useState(false);

  const handleNavItemClick = () => {
    if (children && children.length) {
      setIsSubmenuVisible((prevState) => !prevState);
    } else if (route) {
      props.history.push(route);
    }
  };

  const closeSubmenu = () => {
    setIsSubmenuVisible(false);
  };

  const getActiveStateClass = () => {
    return isActive ? "text-black border-b-2 active-nav" : "";
  };

  const getNavItemId = () => {
    return `nav-item-id-${navItemId}`;
  };

  return (
    <div className="inline-block">
      <div
        id={getNavItemId()}
        className={`nav-item relative text-center border-black text-bold text-2xl cursor-pointer leading-7 hover:text-black ${
          isSubmenuVisible ? "text-black" : ""
        } ${getActiveStateClass()} ${
          !isActive && !isSubmenuVisible ? "text-gray-1" : ""
        }`}
        onClick={handleNavItemClick}
        data-text={text}
      >
        <div className="nav-item-text">{text}</div>
      </div>
      {isSubmenuVisible && (
        <SubNavMenuPopover
          target={`#${getNavItemId()}`}
          menuItems={children || []}
          onClose={closeSubmenu}
          onSelect={closeSubmenu}
          yOffset={20}
        />
      )}
    </div>
  );
}

export default withRouter(NavItem);
