import React from "react";
import { connect } from "react-redux";
import FilterItem from "./FilterItemComponent";
import * as actions from "../../store/actions/datasourceActions";
import List from "devextreme-react/list";
import "devextreme/dist/css/dx.light.compact.css";
import { filter, sortBy } from "lodash";

class Filters extends React.Component {
  componentDidMount() {
    if (this.props.selectedDatasourceId) {
      this.props.onLoadDatasource(this.props.selectedDatasourceId);
    }
  }

  filterSelectionChanged = (data) => {
    if (data && data.addedItems.length > 0) {
      const selectedFilter = data.addedItems[0];

      this.props.onFilterSelectionChanged(selectedFilter);
      if (this.props.onStepChange) {
        this.props.onStepChange();
      }
    }
  };

  render() {
    const { filters } = this.props;
    this.filterListDataSource = {
      store: sortBy(
        filter(filters, (f) => f.Name !== "#Designer Filter#"),
        "Name"
      ),
    };

    return (
      <fieldset>
        <legend>2 - Select Filter</legend>
        <List
          dataSource={this.filterListDataSource}
          noDataText={"No filter found."}
          pageLoadingText={"Loading Filters.."}
          height={"750"}
          pageLoadMode={"scrollBottom"}
          itemComponent={FilterItem}
          selectionMode={"single"}
          keyExpr={"PrimKey"}
          onSelectionChanged={this.filterSelectionChanged}
        />
      </fieldset>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    filters: state.dashboardData.filters,
    selectedDatasourceId: props.selectedDatasourceId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadDatasource: (selectedDatasourceId) =>
      dispatch(actions.loadDatasource(selectedDatasourceId)),
    onFilterSelectionChanged: (data) => {
      dispatch(actions.clearParameterData());
      dispatch(actions.filterSelectionChanged(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
