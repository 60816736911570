import { updateObject } from "../../shared/Utility/commonUtility";
import * as actionTypes from "../actions/ActionType/portalTenantActionTypes";

const initialState = {
  error: null,
  loading: false,
  authData: null
};

const createTenantUserBegin = state => {
  return updateObject(state, { error: null, loading: true });
};

const createTenantUserSuccess = state => {
  return updateObject(state, { error: null, loading: false });
};

const createTenantUserFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

const tenantReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_TENANT_USER_BEGIN:
      return createTenantUserBegin(state);
    case actionTypes.CREATE_TENANT_USER_SUCCESS:
      return createTenantUserSuccess(state);
    case actionTypes.CREATE_TENANT_USER_FAIL:
      return createTenantUserFail(state, action);
    default:
      return state;
  }
};

export default tenantReducer;
