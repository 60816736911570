import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { checkIfUserHasValidPromineoToken } from "../shared/Utility/commonUtility";
import Dashboards from "./Dashboards/DashboardsComponent";
import Settings from "./Settings/SettingsComponent";
import Tenants from "./Tenants/Tenants";
import * as actions from "../store/actions";
import DashboardViewer from "./Dashboards/DashboardViewerComponent";
import DashboardLink from "./Dashboards/DashboardLinkComponent";
import Wiki from "./Wiki/WikiComponent";
import WikiEditor from "./Wiki/WikiEditorComponent";
import TagEditor from "./Wiki/TagEditorComponent";
import WikiViewer from "./Wiki/WikiViewerComponent";
import WikiLink from "./Wiki/WikiLinkComponent";
import RegisterLeave from "./Leave/RegisterLeaveComponent";
import CommunityUploads from "./Community/CommunityUploadsCompnent";
import HomeComponent from "./HomeComponent";
import Logout from "./Authentication/logoutComponent";
import { checkIfAuthenticateWithOidc } from "../shared/Utility/authUtility";
import LandingComponent from "./Landing/LandingComponent";

import PrivateRoute from "./Authentication/privateRouteComponent";

class Main extends Component {
  componentDidMount() {
    this.props.onTryAutoSignin();
  }

  render() {
    return (
      <Switch>
        <Route
          exact
          path="/"
          isAuthenticated={this.props.isAuthenticated}
          component={LandingComponent}
        />
        <PrivateRoute
          path="/home"
          isAuthenticated={this.props.isAuthenticated}
          component={HomeComponent}
        />
        <PrivateRoute
          path="/settings"
          isAuthenticated={this.props.isAuthenticated}
          component={Settings}
        />
        <PrivateRoute
          path="/tenants"
          isAuthenticated={this.props.isAuthenticated}
          component={Tenants}
        />
        <PrivateRoute
          exact
          path="/wiki"
          isAuthenticated={this.props.isAuthenticated}
          component={Wiki}
        />
        <PrivateRoute
          path="/wikiViewer"
          isAuthenticated={this.props.isAuthenticated}
          component={WikiViewer}
        />
        <PrivateRoute
          path="/wikiEditor"
          isAuthenticated={this.props.isAuthenticated}
          component={WikiEditor}
        />
        <PrivateRoute
          path="/tagEditor"
          isAuthenticated={this.props.isAuthenticated}
          component={TagEditor}
        />
        <PrivateRoute
          path="/dashboards"
          isAuthenticated={this.props.isAuthenticated}
          component={Dashboards}
        />
        <PrivateRoute
          path="/dashboardViewer"
          isAuthenticated={this.props.isAuthenticated}
          component={DashboardViewer}
        />
        <PrivateRoute
          exact
          path="/dashboard/:linkId"
          isAuthenticated={this.props.isAuthenticated}
          component={DashboardLink}
        />
        <PrivateRoute
          exact
          path="/wiki/:articleId"
          isAuthenticated={this.props.isAuthenticated}
          component={WikiLink}
        />
        <PrivateRoute
          exact
          path="/registerleave"
          isAuthenticated={this.props.isAuthenticated}
          component={RegisterLeave}
        />
        <PrivateRoute
          path="/communityuploads"
          isAuthenticated={this.props.isAuthenticated}
          component={CommunityUploads}
        />
        <Route path="/logout" component={Logout} />
      </Switch>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: checkIfUserHasValidPromineoToken(state.authData),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignin: () => {
      const authenticationUsingOidc = checkIfAuthenticateWithOidc();
      if (authenticationUsingOidc) {
        dispatch(actions.silentAuthenticateStart());
      } else {
        dispatch(actions.authCheckState());
      }
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
