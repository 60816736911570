import React from "react";
import PromineoIconSvg from "../../static/images/promineo-icon.svg";
import prominioLargeIcongSvg from "../../static/images/promineo-large-icon.svg";
import { getConfiguration } from "../../shared/Utility/configUtility";

export default function PromineoLogo(props) {
  const promineoSiteUrl = getConfiguration("promineoSiteUrl");
  return (
    <a href={promineoSiteUrl}>
      <img
        src={props.isLarge ? prominioLargeIcongSvg : PromineoIconSvg}
        alt="promineo"
      />
    </a>
  );
}
