import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import SubNavMenuPopover from "../NavBar/SubNavMenuPopover";

export function PriorityNavItem({
  text,
  isActive,
  children,
  route,
  itemId,
  isNavbarReadyToDisplay,
  history,
}) {
  const [isSubMenuVisible, setIsSubMenuVisible] = useState(false);

  const handleNavItemClick = () => {
    if (children && children.length) {
      setIsSubMenuVisible((prevState) => !prevState);
    } else if (route) {
      history.push(route);
    }
  };

  const getActiveStateClass = () => {
    return isActive ? "bg-primary-red-grayed text-black" : "";
  };

  return (
    <>
      <div
        id={`priority-nav-item-${itemId}`}
        className={`${isNavbarReadyToDisplay ? "flex-grow" : "inline-block"} 
        py-2 px-4 font-semibold rounded-lg text-center cursor-pointer ${getActiveStateClass()} ${
          isSubMenuVisible ? "text-black" : ""
        } ${!isActive && !isSubMenuVisible ? "text-black" : ""}`}
        onClick={handleNavItemClick}
        data-text={text}
      >
        <div className="nav-item-text">{text}</div>
      </div>
      {children && children.length > 0 && isSubMenuVisible && (
        <SubNavMenuPopover
          menuItems={children}
          target={`#priority-nav-item-${itemId}`}
          onClose={() => setIsSubMenuVisible(false)}
          onSelect={() => setIsSubMenuVisible(false)}
          yOffset={25}
        />
      )}
    </>
  );
}

export default withRouter(PriorityNavItem);
