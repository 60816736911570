import React from "react";
import { Button } from "devextreme-react/button";
import { GetNewId } from "../../shared/Utility/uidUtility";

class ListItemAction extends React.Component {
  constructor(props) {
    super(props);
    this.id = GetNewId();
  }

  getDisplayEditButton = () => {
    return this.props.displayEditButton !== undefined
      ? this.props.displayEditButton
      : true;
  };

  getDisplayDeleteButton = () => {
    return this.props.displayDeleteButton !== undefined
      ? this.props.displayDeleteButton
      : true;
  };

  fireEditEvent = e => {
    e.event.stopPropagation();
    if (this.props.onEdit !== undefined) {
      this.props.onEdit();
    }
  };

  fireDeleteEvent = e => {
    e.event.stopPropagation();
    if (this.props.onDelete !== undefined) {
      this.props.onDelete();
    }
  };

  render() {
    const editButton = (
      <Button
        key={this.id + "-edit"}
        icon="edit"
        onClick={this.fireEditEvent}
      />
    );
    const deleteButton = (
      <Button
        key={this.id + "-delete"}
        icon="trash"
        onClick={this.fireDeleteEvent}
      />
    );
    let actionButtons = [];

    if (this.getDisplayEditButton()) {
      actionButtons.push(editButton);
    }

    if (this.getDisplayDeleteButton()) {
      actionButtons.push(deleteButton);
    }
    return <div className="float-right" key={GetNewId()}>{actionButtons}</div>;
  }
}

export default ListItemAction;
