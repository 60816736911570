import React from "react";
import { connect } from "react-redux";
import PromineoTab from "./../Common/Controls/PromineoTabComponent";
import CommunityFieldSettings from "./CommunityFieldSettingsComponent";
import CommunityDataSourceSettings from "./CommunityDataSourceSettingsComponent";
import CommunityDashboardSettings from "./CommunityDashboardSettingsComponent";
import TenantUserSettings from "./TenantUserSettingsComponent";
import { Item } from "devextreme-react/tab-panel";
import * as actions from "../../store/actions/communityActions";
import ServiceAccountSettingsComponent from "./ServiceAccountSettingsComponent";
import PostProcessorSettingsComponent from "./PostProcessorSettingsComponent";

class Settings extends React.Component {
  componentDidMount() {
    this.props.clearCommunityDependentData();
  }

  render() {
    return (
      <div className="container pt-3">
        <div className="row">
          <div className="col">
            <PromineoTab>
              <Item title={"Tenant Users"} component={TenantUserSettings} />
              <Item
                title={"Service Accounts"}
                component={ServiceAccountSettingsComponent}
              />
              <Item
                title={"Community Fields"}
                component={CommunityFieldSettings}
              />
              <Item
                title={"Community Datasources"}
                component={CommunityDataSourceSettings}
              />
              <Item
                title={"Community Dashboards"}
                component={CommunityDashboardSettings}
              />
              <Item
                title={"Post Processors"}
                component={PostProcessorSettingsComponent}
              />
            </PromineoTab>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearCommunityDependentData: () =>
      dispatch(actions.clearCommunityDependentData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
