import React from "react";
import { connect } from "react-redux";
import { Column } from "devextreme-react/data-grid";
import { Button } from "devextreme-react/button";
import LoadingPanel from "../Common/LoadingPanelComponent";
import {
  PromineoSelectBox,
  PromineoGrid
} from "../Common/Controls/ControlList";
import * as userFieldActions from "../../store/actions/userFieldActions";
import * as communityActions from "../../store/actions/communityActions";
import * as communityUploadActions from "../../store/actions/communityUploadActions";

class CommunityFieldUpload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCommunity: null,
      selectedUserFields: [],
      gridObject: null
    };
  }

  componentDidMount() {
    this.props.onLoadCommunities();
    this.props.onLoadUserFields();
  }

  onInputValueChanged = (controlName, value) => {
    this.setState({
      [controlName]: value
    });
  };

  handleSelectionChanged = selectedData => {
    if (selectedData && selectedData.selectedRowsData) {
      this.setState({
        selectedUserFields: selectedData.selectedRowsData
      });
    }
  };

  clearGridSelection = () => {
    if (this.state.gridObject) {
      this.state.gridObject.clearSelection();
    }
  };

  onUploadCommnuityFieldsClick = () => {
    this.props.onUploadCommunityFields(
      this.state.selectedCommunity,
      this.state.selectedUserFields,
      this.clearGridSelection
    );
  };

  handleReferenceSet = grid => {
    this.setState({
      gridObject: grid
    });
  };

  render() {
    const {
      communities,
      userFields,
      isLoadingCommunityData,
      isLoadingProjectData
    } = this.props;
    return (
      <div className="container" id="UploadContainer">
        <LoadingPanel
          visible={isLoadingCommunityData || isLoadingProjectData}
          position={{ of: "#UploadContainer" }}
        ></LoadingPanel>
        <div className="row">
          <div className="col-md-4">
            <PromineoSelectBox
              dataSource={communities}
              id="selectedCommunity"
              label="Select Community"
              displayExpr="name"
              valueExpr="id"
              onValueChanged={this.onInputValueChanged}
            />
          </div>
        </div>
        <div className="row">
          <div>
            <PromineoGrid
              allowMultiSelect={true}
              onRef={this.handleReferenceSet}
              enablePaging={true}
              defaultPageSize={15}
              dataSource={userFields}
              displayFilterRow={true}
              onSelectionChanged={this.handleSelectionChanged}
            >
              <Column dataField={"Title"} width={"20%"} sortOrder={"asc"} />
              <Column dataField={"Type"} width={"10%"} />
              <Column dataField={"Description"} width={"70%"} />
            </PromineoGrid>
          </div>
        </div>
        <div className="row pt-3 pb-3">
          <div className="col text-center">
            <Button
              className="text-center"
              text="Upload Community Fields"
              type="default"
              onClick={this.onUploadCommnuityFieldsClick}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    communities: state.communityData.communities,
    isLoadingCommunityData: state.communityData.loading,
    isLoadingProjectData: state.projectData.loading,
    userFields: state.projectData.userFields
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLoadCommunities: () => dispatch(communityActions.loadCommunities()),
    onLoadUserFields: () => dispatch(userFieldActions.getUserFields()),
    onUploadCommunityFields: (communuty, fields, successCallBack) => {
      dispatch(
        communityUploadActions.uploadUserFieldDataToCommunity(
          communuty,
          fields,
          successCallBack
        )
      );
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunityFieldUpload);
