import React from "react";
import { connect } from "react-redux";
import DashboardLinkParameterConfirmation from "./DashboardLinkParameterConfirmationComponent";
import * as actions from "../../store/actions/dashboardActions";

class DashboardLink extends React.Component {
  componentDidMount() {
    this.props.onLoadDashboardLink();
  }

  render() {
    var dataLoaded = this.props.loadingLinkInfoDone;
    return (
      <div>
        {dataLoaded ? (
          <DashboardLinkParameterConfirmation
            linkId={this.props.match.params.linkId}
          />
        ) : (
          <div>Loading...</div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loadingLinkInfoDone: state.dashboardData.loadingLinkInfoDone,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onLoadDashboardLink: () =>
      dispatch(actions.loadDashboardLink(props.match.params.linkId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLink);
