import React from "react";
import { connect } from "react-redux";
import CommunityList from "./CommunityListComponent";
import CommunityFieldsGrid from "./CommunityFieldsGridComponent";
import { Button } from "devextreme-react/button";
import * as actions from "../../store/actions/communityFieldActions";

class CommunityFieldSettings extends React.Component {
  onDownloadClicked = () => {
    this.props.downloadCommunityFields();
  };

  handleCommunitySelection = community => {
    this.props.onCommunitySelectionChanged(community);
  };

  render() {
    const { selectedCommunity } = this.props;
    return (
      <div className="container pt-3">
        <div className="row">
          <div className="col-sm-3">
          <CommunityList onSelectionChanged={this.handleCommunitySelection} />
          </div>
          <div className="col-sm-9">
            <CommunityFieldsGrid community={selectedCommunity} />
          </div>
        </div>
        <div className="row pt-3 pb-3">
          <div className="col text-center">
            <Button
              className="text-center"
              text="Download Selected Fields"
              type="default"
              onClick={this.onDownloadClicked}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    selectedCommunity: state.communityData.selectedCommunityForField
  };
};

const mapDispatchToProps = dispatch => {
  return {
    downloadCommunityFields: () => dispatch(actions.downloadCommunityFields()),
    onCommunitySelectionChanged: data => {
      dispatch(actions.communitySelectionChanged(data));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunityFieldSettings);
