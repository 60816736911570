import React, { useEffect, useRef } from "react";
import PromineoPopUp from "../Common/Controls/PromineoPopUpComponent";
import { PromineoGrid } from "../Common/Controls/ControlList";
import { Column } from "devextreme-react/data-grid";
import * as postProcessorActions from "../../store/actions/postProcessorActions";
import { connect } from "react-redux";

export function PostProcessorAddDialogComponent(props) {
  const gridRef = useRef(null);
  useEffect(() => {
    props.getPostProcessors();
  }, []);

  const handleOkClick = () => {
    const selectedPostProcessors = gridRef.current.getSelectedRows();
    if (selectedPostProcessors.length) {
      props.onOkClick(selectedPostProcessors[0]);
    }
  };

  return (
    <div>
      <PromineoPopUp
        visible={true}
        handleCancelClick={props.onCancelClick}
        handleOkClick={handleOkClick}
        width={800}
        height={520}
      >
        <div className="p-6">
          <div className="h5 mb-2">Add Post Processor</div>
          <PromineoGrid
            ref={gridRef}
            dataSource={props.postProcessors}
            keyExpression="id"
            enablePaging={true}
            showBorders={true}
            defaultPageSize={10}
            displaySearchPanel={true}
          >
            <Column dataField="name" caption="Title" />
            <Column dataField="identifier" caption="Identifier" />
            <Column dataField="description" caption="Description" />
            <Column dataField="isActive" caption="Active" />
          </PromineoGrid>
        </div>
      </PromineoPopUp>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  const identifierAlreadyExists = (props.tenantPostProcessors || []).map(
    (tpp) => tpp.Identifier
  );
  const filteredPostProcessors = (
    state.postProcessorData.portalPostProcessors || []
  ).filter((pp) => !identifierAlreadyExists.includes(pp.identifier));

  return {
    ...props,
    postProcessors: filteredPostProcessors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPostProcessors: () =>
      dispatch(postProcessorActions.loadPortalsPostProcessors()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostProcessorAddDialogComponent);
