import React from "react";
import { connect } from "react-redux";
import { DashboardControl } from "devexpress-dashboard/common/dashboard-control";
import { ResourceManager } from "devexpress-dashboard/common/resource-manager";
import Globalize from "globalize";
import * as uidUtility from "../../shared/Utility/uidUtility";
import { logActivity } from "../../store/actions/activityLogActions";
import { find } from "lodash";
import { activityTypes } from "../../shared/Enums/activityTypes";

class DashboardViewer extends React.Component {
  constructor(props) {
    super(props);
    this.initGlobalize();
    ResourceManager.embedBundledResources();
    this.requestId = uidUtility.GetNewId();
    this.dashboardControl = new DashboardControl();
  }
  componentDidMount() {
    this.logDashboardLaunchedActivity();

    this.dashboardControl = new DashboardControl(this.refs.dashboardcontainer, {
      endpoint:  this.props.tenantReportingUrl + "api/dashboard",
      workingMode: "Viewer",
      initialDashboardId: this.props.selectedDashboard ? this.props.selectedDashboard.PrimKey : null,
      extensions: {
        "url-state": {
          includeDashboardIdToUrl: false
        },
        "dashboard-export": {
          allowExportDashboardItems: true
        },
        "viewer-api":{
          onItemWidgetCreated: function(args) { 
            if (args && args.itemName.startsWith('gridDashboardItem')) {     
              var grid = args.getWidget();  
              grid.option("headerFilter", {visible: true});  
            }
          }
        }
      }
    });

    this.dashboardControl.render();
    this.dashboardControl.remoteService.beforeSend = jqXHR => {
      jqXHR.setRequestHeader(
        "promineo-token",
        this.props.promineoToken
      );
      jqXHR.setRequestHeader("request-id", this.requestId);
      jqXHR.setRequestHeader("api-url", this.props.tenantApiUrl);
      jqXHR.setRequestHeader("filter-id", this.props.selectedFilterId);
      jqXHR.setRequestHeader(
        "filter-parameter",
        JSON.stringify(this.props.parameters)
      );
    };
  }

  componentWillUnmount() {
    this.dashboardControl.dispose();
  }

  logDashboardLaunchedActivity = () => {
    if(this.props.selectedDashboard){
      let filterName = "";
      if(this.props.filters && this.props.filters.length > 0){
        filterName = find(this.props.filters, filter => filter.PrimKey === this.props.selectedFilterId).Name;
      }
      let activityType = activityTypes.LaunchDashboard;
      let logDetail = `Dashboard:${this.props.selectedDashboard.PrimKey} ${this.props.selectedDashboard.Title ? this.props.selectedDashboard.Title: ''},Filter:${this.props.selectedFilterId} ${filterName},Parameter:${JSON.stringify(this.props.parameters)}`;
      if(this.props.throughLink){
        activityType = activityTypes.LaunchDashboardViaLink;
        logDetail = `LinkId:${this.props.linkId},`+ logDetail;
      }
      
      this.props.logActivity(activityType, logDetail);
    }
  }
  
  render() {
    return (
      <div
        ref="dashboardcontainer"
        style={{ width: "100%", height: "100%", position: "absolute" }}
      />
    );
  }

  initGlobalize() {
    Globalize.load(
      require("devextreme-cldr-data/en.json"),
      require("devextreme-cldr-data/supplemental.json")
    );
    Globalize.locale("en");
  }
}

const mapStateToProps = (state, props) => {
  // The api expects empty object instead of null as parameter
  let params = {};
  if (state && state.dashboardData && state.dashboardData.filterParameters) {
    params = state.dashboardData.filterParameters;
  }
  return {
    promineoToken: state.authData.promineoToken,
    tenantApiUrl: state.authData.selectedTenantApiUrl,
    tenantReportingUrl: state.authData.selectedTenantWebReportingUrl,
    selectedFilterId:
      state && state.dashboardData
        ? state.dashboardData.selectedFilterId
        : null,
    selectedDashboard:
      state && state.dashboardData && state.dashboardData.selectedDashboard
        ? state.dashboardData.selectedDashboard
        : null,
    parameters: params,
    filters: state && state.dashboardData ? state.dashboardData.filters : null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logActivity: (action,details) => {
      dispatch(logActivity(action,details));
    }
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(DashboardViewer);
