import React from "react";

const tenant = props => {
  return (
    <div key={props.id} className="card">
      <div
        key={props.id + "child"}
        className={props.isSelected ? "card-body bg-info" : "card-body"}
      >
        <p className="card-text">{props.name}</p>
        <button onClick={props.onClick} className="btn btn-primary">
          Log on
        </button>
      </div>
    </div>
  );
};

export default tenant;
