import React from "react";
import HeaderBanner from "./HeaderBanner";
import AuthComponent from "../Authentication/AuthComponent";

export default function Header() {
  return (
    <div className="flex justify-between items-center flex-wrap md:flex-nowrap">
      <HeaderBanner />
      <div className="flex justify-center md:justify-end w-full md:w-auto mt-8 md:mt-0">
        <AuthComponent />
      </div>
    </div>
  );
}
