export const FETCH_COMMUNITY_START = "FETCH_COMMUNITY_START";
export const FETCH_COMMUNITY_SUCCESS = "FETCH_COMMUNITY_SUCCESS";
export const FETCH_COMMUNITY_FAIL = "FETCH_COMMUNITY_FAIL";

export const FETCH_COMMUNITYFIELD_START = "FETCH_COMMUNITYFIELD_START";
export const FETCH_COMMUNITYFIELD_SUCCESS = "FETCH_COMMUNITYFIELD_SUCCESS";
export const FETCH_COMMUNITYFIELD_FAIL = "FETCH_COMMUNITYFIELD_FAIL";

export const DOWNLOAD_COMMUNITYFIELD_BEGIN = "DOWNLOAD_COMMUNITYFIELD_BEGIN";
export const DOWNLOAD_COMMUNITYFIELD_SUCCESS =
  "DOWNLOAD_COMMUNITYFIELD_SUCCESS";
export const DOWNLOAD_COMMUNITYFIELD_FAIL = "DOWNLOAD_COMMUNITYFIELD_FAIL";

export const COMMUNITYFIELD_SELECTION_CHANGED =
  "COMMUNITYFIELD_SELECTION_CHANGED";
export const FIELD_COMMUNITY_SELECTION_CHANGED =
  "FIELD_COMMUNITY_SELECTION_CHANGED";

export const FETCH_COMMUNITYDATASOURCE_START =
  "FETCH_COMMUNITYDATASOURCE_START";
export const FETCH_COMMUNITYDATASOURCE_SUCCESS =
  "FETCH_COMMUNITYDATASOURCE_SUCCESS";
export const FETCH_COMMUNITYDATASOURCE_FAIL = "FETCH_COMMUNITYDATASOURCE_FAIL";

export const COMMUNITYDATASOURCE_SELECTION_CHANGED =
  "COMMUNITYDATASOURCE_SELECTION_CHANGED";

export const DOWNLOAD_COMMUNITYDATASOURCE_BEGIN =
  "DOWNLOAD_COMMUNITYDATASOURCE_BEGIN";
export const DOWNLOAD_COMMUNITYDATASOURCE_SUCCESS =
  "DOWNLOAD_COMMUNITYDATASOURCE_SUCCESS";
export const DOWNLOAD_COMMUNITYDATASOURCE_FAIL =
  "DOWNLOAD_COMMUNITYDATASOURCE_FAIL";

export const DATASOURCE_COMMUNITY_SELECTION_CHANGED =
  "DATASOURCE_COMMUNITY_SELECTION_CHANGED";

export const DATASOURCE_COMMUNITY_PERIODIZERINFODISPLAY_CHANGED =
  "DATASOURCE_COMMUNITY_PERIODIZERINFODISPLAY_CHANGED";

export const DEFAULTCALENDAR_SELECTION_CHANGED =
  "DEFAULTCALENDAR_SELECTION_CHANGED";
export const CUTOFFTYPE_SELECTION_CHANGED = "CUTOFFTYPE_SELECTION_CHANGED";

export const CLEAR_COMMUNITY_DEPENDENT_DATA = "CLEAR_COMMUNITY_DEPENDENT_DATA";

export const FETCH_COMMUNITYDASHBOARD_START = "FETCH_COMMUNITYDASHBOARD_START";
export const FETCH_COMMUNITYDASHBOARD_SUCCESS =
  "FETCH_COMMUNITYDASHBOARD_SUCCESS";
export const FETCH_COMMUNITYDASHBOARD_FAIL = "FETCH_COMMUNITYDASHBOARD_FAIL";

export const COMMUNITYDASHBOARD_SELECTION_CHANGED =
  "COMMUNITYDASHBOARD_SELECTION_CHANGED";

export const DOWNLOAD_COMMUNITYDASHBOARD_BEGIN =
  "DOWNLOAD_COMMUNITYDASHBOARD_BEGIN";
export const DOWNLOAD_COMMUNITYDASHBOARD_SUCCESS =
  "DOWNLOAD_COMMUNITYDASHBOARD_SUCCESS";
export const DOWNLOAD_COMMUNITYDASHBOARD_FAIL =
  "DOWNLOAD_COMMUNITYDASHBOARD_FAIL";

export const DASHBOARD_COMMUNITY_SELECTION_CHANGED =
  "DASHBOARD_COMMUNITY_SELECTION_CHANGED";

export const DASHBOARD_COMMUNITY_PERIODIZERINFODISPLAY_CHANGED =
  "DASHBOARD_COMMUNITY_PERIODIZERINFODISPLAY_CHANGED";

export const DASHBOARD_COMMUNITY_DETAILED_TEMP =
  "DASHBOARD_COMMUNITY_DETAILED_TEMP";
