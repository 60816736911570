import React from "react";
import SelectBox from "devextreme-react/select-box";
import { Validator, RequiredRule } from "devextreme-react/validator";

class PromineoSelectBox extends React.Component {
  getshowClearButton = () => {
    return this.props.showClearButton !== undefined
      ? this.props.showClearButton
      : false;
  };

  getPlaceHolder = () => {
    return this.props.placeHolder !== undefined ? this.props.placeHolder : "";
  };

  getDefaultValue = () => {
    return this.props.defaultValue !== undefined ? this.props.defaultValue : "";
  };

  getDisabled = () => {
    return this.props.disabled !== undefined ? this.props.disabled : false;
  };

  getReadOnly = () => {
    return this.props.readOnly !== undefined ? this.props.readOnly : false;
  };

  getRequired = () => {
    return this.props.required !== undefined ? this.props.required : true;
  };

  fireValueChangedEvent = e => {
    if (this.props.onValueChanged !== undefined) {
      this.props.onValueChanged(this.getId(), e.value);
    }
  };

  getId = () => {
    return this.props.id !== undefined ? this.props.id : null;
  };

  getLabel = () => {
    return this.props.label !== undefined ? this.props.label : "";
  };

  render() {
    const validatorRules = [];
    const requredValidator = (
      <RequiredRule
        key={`${this.getId()}-RequiredRule`}
        message={`${this.getLabel()} is required`}
      />
    );

    if (this.getRequired()) {
      validatorRules.push(requredValidator);
    }

    return (
      <div className="form-group">
        <label htmlFor={this.getId()}>{this.getLabel()}</label>
        <SelectBox
          id={this.getId()}
          items={this.props.items}
          dataSource={this.props.dataSource}
          showClearButton={this.getshowClearButton()}
          placeholder={this.getPlaceHolder()}
          defaultValue={this.getDefaultValue()}
          disabled={this.getDisabled()}
          readOnly={this.getReadOnly()}
          displayExpr={this.props.displayExpr}
          valueExpr={this.props.valueExpr}
          onValueChanged={this.fireValueChangedEvent} 
        >
          <Validator>{validatorRules}</Validator>
        </SelectBox>
      </div>
    );
  }
}

export default PromineoSelectBox;
