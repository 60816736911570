import React from "react";

export default function Avatar(props) {
  const { firstName, lastName, size, displayCrossIcon } = props;

  const getNameInitials = () => {
    let nameInitials = `${firstName && firstName.length ? firstName[0] : ""}${
      lastName && lastName.length ? lastName[0] : ""
    }`;
    return nameInitials.toUpperCase();
  };

  const getSizeSpecificClass = () => {
    return `${size && size === "small" ? "h-6 w-6 text-2xs" : "h-35px w-35px"}`;
  };

  const getCrossIcon = () => {
    return <span className="icon dx-icon-close text-base"></span>;
  };

  return (
    <div
      className={`${getSizeSpecificClass()} rounded-full flex justify-center items-center bg-primary-red text-white font-medium`}
    >
      {displayCrossIcon ? getCrossIcon() : getNameInitials()}
    </div>
  );
}
