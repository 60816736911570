import React from "react";
import "./../../styles/dashboard/filterParameterStyle.css";
import FilterParmeterDisplay from "./FilterParameterDisplayComponent";
import ScrollView from "devextreme-react/scroll-view";

export default class FilterParameters extends React.Component {
  render() {
    return (
      <fieldset className="parameterFieldSet">
        <legend>3 - Select Parameter(s)</legend>
        <ScrollView id="scrollview" height={"90%"}>
          <div className="parameterContents">
            <FilterParmeterDisplay {...this.props} />
          </div>
        </ScrollView>
      </fieldset>
    );
  }
}
