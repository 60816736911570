import { getApiCompatibleModelType } from "./dataUtility";

const getStructureRef = communityField => {
  if (!communityField.structureRef || communityField.structureRef === "null") {
    return null;
  }

  return communityField.structureRef;
};

export const getApiCompatibleUserFieldData = userField => {
  return {
    $type: getApiCompatibleModelType("Project", "Field"),
    RecordState: 2,
    PrimKey: userField.id,
    Title: userField.title,
    Type: userField.type,
    Description: userField.description,
    StructureRef: getStructureRef(userField)
  };
};

const getApiCompatibleStructureData = (
  structure,
  liveRevisionRef,
  state = 2
) => {
  return {
    $type: getApiCompatibleModelType("Project", "Structure"),
    RecordState: state,
    PrimKey: structure.id,
    Title: structure.title,
    Description: structure.description,
    RevisionRef: liveRevisionRef
  };
};

export const getApiCompatibleStructureListForUpsert = (
  existingStructures,
  changedStructures,
  liveRevisionRef
) => {
  const upsertStructureIds = changedStructures.map(structure => {
    return structure.id;
  });

  // Find list of structures to be updated
  const structuresIdsToUpdated = existingStructures.reduce(
    (result, structure) => {
      if (upsertStructureIds.indexOf(structure.PrimKey) >= 0) {
        result.push(structure.PrimKey);
      }
      return result;
    },
    []
  );

  const apiCompatibleStructureData = changedStructures.map(structure => {
    const recordState =
      structuresIdsToUpdated.indexOf(structure.id) < 0 ? 2 : 1;
    return getApiCompatibleStructureData(
      structure,
      liveRevisionRef,
      recordState
    );
  });

  return apiCompatibleStructureData;
};
