import * as request from "../../shared/request";
import { isSuccessApiResponse } from "../../shared/Utility/dataUtility";
import { toastError } from "../../shared/Utility/toastUtility";
import * as actionTypes from "./ActionType/projectActionTypes";

export const loadTenantStructures = () => {
  return (dispatch, getState) => {
    const state = getState();

    const loadStructureRequest = request.apiRequest(
      {
        url: "/select/v_Project_Structure",
        method: "POST"
      },
      state.authData
    );

    if (loadStructureRequest) {
      
      return loadStructureRequest
        .then(response => {
          if (isSuccessApiResponse(response) && response.data.length > 0) {
            const structures = response.data[0].$values;
            return structures;
          } else {
            return [];
          }
        })
        .catch(error => {
          throw error;
        });
    }
  };
};

export const loadUserFieldsBegin = () => {
  return {
    type: actionTypes.FETCH_USERFIELDS_BEGIN
  };
};

export const loadUserFieldsSuccess = userFieldData => {
  return {
    type: actionTypes.FETCH_USERFIELDS_SUCCESS,
    userFieldData
  };
};

export const loadUserFieldsFail = error => {
  toastError("Failed to load user fields.");

  return {
    type: actionTypes.FETCH_USERFIELDS_FAIL,
    error
  };
};

export const getUserFields = () => {
  return dispatch => {
    dispatch(loadUserFieldsBegin());

    dispatch(getExistingUserFields(true))
      .then(userFieldResponse => {
        dispatch(loadUserFieldsSuccess(userFieldResponse));
      })
      .catch(error => {
        dispatch(loadUserFieldsFail(error));
      });
  };
};

export const getExistingUserFields = (loadRelatedStructures = false) => {
  return (dispatch, getState) => {
    const state = getState();

    const baseUserFieldsUrl = "/select/t_Project_Field";
    const loadUrl =
      baseUserFieldsUrl + (loadRelatedStructures ? "/Structure" : "");
    const userFieldsLoadRequest = request.apiRequest(
      {
        url: loadUrl,
        method: "POST"
      },
      state.authData
    );

    const emptyReturnObject = {
      fields: [],
      structures: []
    };

    if (userFieldsLoadRequest) {
      return userFieldsLoadRequest
        .then(response => {
          if (isSuccessApiResponse(response)) {
            let returnObject = {
              fields: response.data[0].$values
            };

            if (loadRelatedStructures && response.data.length > 1) {
              returnObject = {
                ...returnObject,
                ...{ structures: response.data[1].$values }
              };
            }

            return returnObject;
          } else {
            return emptyReturnObject;
          }
        })
        .catch(error => {
          console.log(error);
          throw error;
        });
    } else {
      return emptyReturnObject;
    }
  };
};
