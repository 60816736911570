import React from "react";
import { connect } from "react-redux";
import {
  PromineoTagBox,
  PromineoTextBox,
  PromineoWikiEditor
} from "../Common/Controls/ControlList";
import EditorButtons from "../Common/EditorButtonsComponent";
import { toastError } from "../../shared/Utility/toastUtility";
import { GetNewId } from "../../shared/Utility/uidUtility";
import { getApiCompatibleModelType } from "../../shared/Utility/dataUtility";
import * as actions from "../../store/actions/wikiActions";
import { Container } from "react-bootstrap";

class WikiEditor extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = {
      id: props.articleInitialData ? props.articleInitialData.id : "",
      title: props.articleInitialData ? props.articleInitialData.title : "",
      content: props.articleInitialData ? props.articleInitialData.content : "",
      tags: props.articleInitialData ? props.articleInitialData.tags : [],
      isPublishable: true,
      articleType: props.articleInitialData
        ? props.articleInitialData.articleType
        : this.props.isPortalAdmin === true
        ? "Public"
        : "Private"
    };
    this.state = this.initialState;
  }

  componentDidMount() {
    // Tags are expected passed as parameter from the container control.
    // If tags are not passed, load from database
    if (!this.props.paramTags.length && !this.props.dbTags.length) {
      this.props.loadWikiTags();
    }
  }

  onInputValueChanged = (controlName, value) => {
    this.setState({
      [controlName]: value
    });
  };

  isValidWiki = () => {
    return (
      this.state.title.trim() !== "" &&
      this.state.content.trim() !== "" &&
      this.state.tags.length > 0
    );
  };

  isInEditMode = () => {
    return this.props.mode === "E";
  };

  hasWikiDataChanges = articleRevision => {
    if (this.isInEditMode()) {
      // Check if tilte or content is modified
      return (
        articleRevision.Title !== this.props.articleInitialData.title ||
        articleRevision.Markdown !== this.props.articleInitialData.content
      );
    } else {
      return true;
    }
  };

  getWikiTagData = (tag, primKey, recordState, articleRef) => {
    return {
      $type: getApiCompatibleModelType("Support", "ArticleTag"),
      RecordState: recordState,
      PrimKey: primKey,
      ArticleRef: articleRef,
      TagRef: tag.PrimKey
    };
  };

  getWikiTagsToSave = article => {
    const tagApiType = getApiCompatibleModelType("Support", "ArticleTag");
    let wikiTags = [];
    if (this.isInEditMode()) {
      const initialWikiTags = this.props.articleInitialData.wikiTags;
      const initialTags = this.props.articleInitialData.tags;

      const deletedTags = initialTags.filter(
        initialTag => this.state.tags.indexOf(initialTag) < 0
      );

      const addedTags = this.state.tags.filter(
        tag => initialTags.indexOf(tag) < 0
      );

      deletedTags.map(deletedTag =>
        wikiTags.push(
          this.getWikiTagData(
            deletedTag,
            initialWikiTags.filter(
              initialWikiTag => initialWikiTag.TagRef === deletedTag.PrimKey
            )[0].PrimKey,
            3,
            article.PrimKey
          )
        )
      );

      addedTags.map(tag =>
        wikiTags.push(this.getWikiTagData(tag, GetNewId(), 2, article.PrimKey))
      );
    } else {
      this.state.tags.map(tag =>
        wikiTags.push({
          $type: tagApiType,
          RecordState: 2,
          PrimKey: GetNewId(),
          ArticleRef: article.PrimKey,
          TagRef: tag.PrimKey
        })
      );
    }

    return wikiTags;
  };

  redirectToWikiComponent = () => {
    this.props.history.push("/wiki");
  };

  saveWikiData = event => {
    event.preventDefault();

    if (this.isValidWiki()) {
      const currentCreationTime = new Date();
      const currentUser = this.props.currentUserId;
      const articleRevisionState = 2;
      const articleState = this.isInEditMode() ? 1 : 2;

      const articleRevision = {
        $type: getApiCompatibleModelType("Support", "ArticleRevision"),
        PrimKey: GetNewId(),
        RecordState: articleRevisionState,
        Title: this.state.title,
        Markdown: this.state.content,
        CreatedOn: currentCreationTime,
        CreatedBy: currentUser
      };

      const article = {
        $type: getApiCompatibleModelType("Support", "Article"),
        PrimKey: this.isInEditMode() ? this.state.id : GetNewId(),
        RecordState: articleState,
        ArticleRevisionRef: articleRevision.PrimKey,
        CreatedOn: currentCreationTime,
        CreatedBy: currentUser,
        ArticleType: this.state.articleType,
        IsPublished: this.state.isPublishable
      };

      let content = [];

      if (this.hasWikiDataChanges(articleRevision)) {
        content.push(articleRevision);
        content.push(article);
      }

      const tagData = this.getWikiTagsToSave(article);
      if (tagData.length > 0) {
        tagData.map(tag => content.push(tag));
      }

      if (content.length > 0) {
        this.props.createOrUpdateWiki(content);
      } else {
        if (
          window.confirm(
            "You do not have any changes to save. Still want to navigate?"
          )
        ) {
          this.redirectToWikiComponent();
        }
      }
    } else {
      toastError("Invalid wiki data");
    }
  };

  handleCancelClick = () => {
    this.redirectToWikiComponent();
  };

  render() {
    const { paramTags, dbTags } = this.props;
    // If parameter tag is empty, use tags from DB
    const tags = paramTags.length ? paramTags : dbTags;
    return (
      <Container>
        <form onSubmit={this.saveWikiData}>
          <PromineoTextBox
            id="title"
            label="Title"
            defaultValue={this.state.title}
            onValueChanged={this.onInputValueChanged}
          />
          <PromineoWikiEditor
            id="content"
            label="Content"
            defaultValue={this.state.content}
            onChange={this.onInputValueChanged}
          />
          <PromineoTagBox
            id="tags"
            label="Tag(s)"
            items={tags}
            displayField="Title"
            defaultValue={this.state.tags}
            ValueExpr="PrimKey"
            batchSelection={true}
            placeHolder="Select wiki tag(s)"
            onValueChanged={this.onInputValueChanged}
          />

          <EditorButtons onCancelClick={this.handleCancelClick} />
        </form>
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    mode:
      props &&
      props.history &&
      props.history.location &&
      props.history.location.state &&
      props.history.location.state.mode
        ? props.history.location.state.mode
        : "I",
    articleInitialData:
      props &&
      props.history &&
      props.history.location &&
      props.history.location.state
        ? props.history.location.state.wikiArticle
        : null,
    paramTags:
      props &&
      props.history &&
      props.history.location &&
      props.history.location.state
        ? props.history.location.state.tags
        : [],
    dbTags: state.wikiData.allTags,
    currentUserId: state.authData.userId,
    isPortalAdmin: state.authData.isPortalAdmin
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadWikiTags: () => dispatch(actions.loadWikiTags()),
    createOrUpdateWiki: wikiArticle =>
      dispatch(actions.createOrUpdateWiki(wikiArticle, ownProps))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WikiEditor);
