import React from "react";
import { connect } from "react-redux";
import Tenant from "./Tenant/Tenant";
import * as actions from "../../store/actions";
import { Redirect } from "react-router-dom";

class Tenants extends React.Component {
  constructor(props) {
    super(props);
    this.uiSelectedTenantId = null;
  }

  componentDidMount() {
    this.props.loadTenants();
  }

  render() {
    const {
      tenants,
      selectedTenant,
      tenantLoginError,
      promineoToken,
      redirectionPath,
      onSetTenant
    } = this.props;

    if (
      !tenantLoginError &&
      promineoToken &&
      this.uiSelectedTenantId &&
      selectedTenant &&
      this.uiSelectedTenantId === selectedTenant.TenantId
    ) {
      let redirectTo = "/home";
      if (redirectionPath) {
        redirectTo = redirectionPath;
      }
      return <Redirect to={redirectTo} />;
    } else {
      return tenants.map(tenant => (
        <Tenant
          key={tenant.TenantId}
          id={tenant.TenantId}
          name={tenant.Name}
          isSelected={
            selectedTenant != null && selectedTenant.Name === tenant.Name
          }
          onClick={() => {
            this.uiSelectedTenantId = tenant.TenantId;
            onSetTenant(
              tenant.TenantId,
              tenant.Name,
              tenant.ApiUrl,
              tenant.ReportingUrl
            );
          }}
        />
      ));
    }
  }
}

const mapStateToProps = state => {
  return {
    token: state.authData.token,
    tenants: state.authData.tenants,
    selectedTenant: state.authData.selectedTenant,
    promineoToken: state.authData.promineoToken,
    tenantLoginError: state.authData.error,
    redirectionPath: state.authData.authRedirectPath
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadTenants: () => {dispatch(actions.loadTenants())},
    onSetTenant: (
      tenantId,
      tenantName,
      tenantApiUrl,
      tenantWebReportingUrl
    ) => {
      dispatch(
        actions.loginToTenant(
          tenantId,
          tenantName,
          tenantApiUrl,
          tenantWebReportingUrl
        )
      );
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tenants);
