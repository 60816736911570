export const FETCH_WIKIS_BEGIN = "FETCH_WIKIS_BEGIN";
export const FETCH_WIKIS_SUCCESS = "FETCH_WIKIS_SUCCESS";
export const FETCH_WIKIS_FAIL = "FETCH_WIKIS_FAIL";

export const CREATEORUPDATE_WIKI_BEGIN = "CREATEORUPDATE_WIKI_BEGIN";
export const CREATEORUPDATE_WIKI_SUCCESS = "CREATEORUPDATE_WIKI_SUCCESS";
export const CREATEORUPDATE_WIKI_FAIL = "CREATEORUPDATE_WIKI_FAIL";

export const FETCH_WIKI_TAGS_BEGIN = "FETCH_WIKI_TAGS_BEGIN";
export const FETCH_WIKI_TAGS_SUCCESS = "FETCH_WIKI_TAGS_SUCCESS";
export const FETCH_WIKI_TAGS_FAIL = "FETCH_WIKI_TAGS_FAIL";

export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";
export const TAG_SELECTION_CHANGED = "TAG_SELECTION_CHANGED";

export const CREATEORUPDATE_TAG_BEGIN = "CREATEORUPDATE_TAG_BEGIN";
export const CREATEORUPDATE_TAG_SUCCESS = "CREATEORUPDATE_TAG_SUCCESS";
export const CREATEORUPDATE_TAG_FAIL = "CREATEORUPDATE_TAG_FAIL";

export const FETCH_WIKI_LINK_BEGIN = "FETCH_WIKI_LINK_BEGIN";
export const FETCH_WIKI_LINK_SUCCESS = "FETCH_WIKI_LINK_SUCCESS";
export const FETCH_WIKI_LINK_FAIL = "FETCH_WIKI_LINK_FAIL";

export const DELETE_WIKI_BEGIN = "DELETE_WIKI_BEGIN";
export const DELETE_WIKI_SUCCESS = "DELETE_WIKI_SUCCESS";
export const DELETE_WIKI_FAIL = "DELETE_WIKI_FAIL";

export const DELETE_TAG_BEGIN = "DELETE_TAG_BEGIN";
export const DELETE_TAG_SUCCESS = "DELETE_TAG_SUCCESS";
export const DELETE_TAG_FAIL = "DELETE_TAG_FAIL";