import React from "react";
import { Button } from "devextreme-react/button";
import ValidationSummary from 'devextreme-react/validation-summary'
import "./../../styles/editorButtonStyles.css";

class EditorButtons extends React.Component {
  handleCancelClick = () => {
    if (this.props && this.props.onCancelClick) {
      this.props.onCancelClick();
    }
  };

  render() {
    return (
      <div className="form-group">
        <ValidationSummary id={"summary"} />
        <Button
          id={"saveButton"}
          key={"saveButton"}
          text={"Save"}
          type={"success"}
          useSubmitBehavior={true}
        />
        <Button
          id={"cancelButton"}
          key={"cancelButton"}
          className="cancel-button"
          text={"Cancel"}
          type={"danger"}
          onClick={this.handleCancelClick}
        />
      </div>
    );
  }
}

export default EditorButtons;
