import React from "react";

const iValidType = type => {
  const validTypes = [
    "primary",
    "secondary ",
    "success",
    "danger",
    "warning",
    "info",
    "light",
    "dark"
  ];
  return type && validTypes.includes(type);
};

const Badge = props => {
  const badgeClass = `badge badge-pill badge-${
    iValidType(props.type) ? props.type : "primary"
  }`;
  return <span className={badgeClass}>{props.text}</span>;
};

export default Badge;
