import * as dataSourceActions from "./communityDataSourceActions";
import * as actionTypes from "./ActionType/communityActionTypes";
import * as request from "../../shared/request";
import {
  toastError,
  toastException,
  toastSuccess
} from "../../shared/Utility/toastUtility";
import {
  getApiCompatibleModelType,
  isSuccessApiResponse
} from "../../shared/Utility/dataUtility";

export const loadCommunityDashboards = selectedCommunity => {
  return (dispatch, getState) => {
    const state = getState();
    if (selectedCommunity && selectedCommunity.id) {
      dispatch(loadCommunityDashboardsBegin());

      const communityDashboardRequest = request.portalRequest(
        {
          url: `/Community/${selectedCommunity.id}/Dashboards`,
          method: "GET"
        },
        state.authData
      );

      if (communityDashboardRequest) {
        communityDashboardRequest
          .then(response => {
            if (isSuccessApiResponse(response)) {
              dispatch(getDownloadableCommunityDashboards(response.data));
            }
          })
          .catch(error => {
            dispatch(loadCommunityDashboardsFail(error));
          });
      } else {
        loadCommunityDashboardsFail("Invalid community selection.");
      }
    }
  };
};

export const loadCommunityDashboardsBegin = () => {
  return {
    type: actionTypes.FETCH_COMMUNITYDASHBOARD_START
  };
};

export const loadCommunityDashboardsSuccess = dashboards => {
  return {
    type: actionTypes.FETCH_COMMUNITYDASHBOARD_SUCCESS,
    dashboards
  };
};

export const loadCommunityDashboardsFail = error => {
  toastError("Failed to load community dashboards.");

  return {
    type: actionTypes.FETCH_COMMUNITYDASHBOARD_FAIL,
    error
  };
};

const getDownloadableCommunityDashboards = availableCommunityDashboards => {
  return (dispatch, getState) => {
    const state = getState();

    // Filter out existing Dashboards from available community dashboards
    const dashboardLoadRequest = request.apiRequest(
      {
        url: "/select/vc_Reporting_DashboardWithoutLayout",
        method: "POST"
      },
      state.authData
    );

    if (dashboardLoadRequest) {
      dashboardLoadRequest
        .then(response => {
          if (isSuccessApiResponse(response)) {
            const existingDashboardKeys = response.data[0].$values.map(db => {
              return db.PrimKey;
            });
            const downloadableDashboards = availableCommunityDashboards.filter(
              communityDashboard => {
                return existingDashboardKeys.indexOf(communityDashboard.id) < 0;
              }
            );
            dispatch(loadCommunityDashboardsSuccess(downloadableDashboards));
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  };
};

// Dashboard Selection Changed
export const dashboardSelectionChanged = selectedDashboard => {
  return {
    type: actionTypes.COMMUNITYDASHBOARD_SELECTION_CHANGED,
    selectedDashboard
  };
};

// Download community data sources
export const downloadCommunityDashboards = () => {
  return (dispatch, getState) => {
    dispatch(downloadCommunityDashboardsBegin());

    const state = getState();
    const selectedCommunityDashboard = state.communityData.selectedDashboard;
    if (selectedCommunityDashboard) {
      dispatch(loadCommunityDashboardDetailedData()).then(dashboardDetail => {
        if (dashboardDetail && dashboardDetail.dataSource) {
          dispatch(
            dataSourceActions.checkIfDataSourceExists(
              dashboardDetail.dataSource.id
            )
          ).then(exists => {
            if (!exists) {
              dataSourceActions
                .checkIfDataSourceHasPeriodizer(
                  dashboardDetail.dataSource.definition
                )
                .then(hasPeriodizer => {
                  if (hasPeriodizer) {
                    dispatch(storeTemporaryDashboardDetailed(dashboardDetail));
                    dispatch(periodizerSettingDisplayConfigChanged(true));
                  } else {
                    dispatch(
                      forceDownloadDashboardAndDataSource(dashboardDetail)
                    );
                  }
                });
            } else {
              dispatch(downloadDashboardData(dashboardDetail.definition));
            }
          });
        }
      });
    } else {
      dispatch(
        downloadCommunityDashboardsFail("Please select dashboard to download")
      );
    }
  };
};
export const forceDownloadDashboardAndDataSource = dashboardData => {
  return (dispatch, getState) => {
    dispatch(
      dataSourceActions.downloadCommunityDataSources([dashboardData.dataSource])
    ).then(() => {
      dispatch(downloadDashboardData(dashboardData.definition));
    });
  };
};
export const downloadDashboardData = dashboardData => {
  return (dispatch, getState) => {
    const state = getState();
    const apiCompatibleInsertModel = getAPICompatibleDashboardInsertModel(
      dashboardData
    );
    if (apiCompatibleInsertModel !== null) {
      const dashboardInsertRequest = request.apiRequest(
        {
          url: "/save",
          method: "POST",
          data: apiCompatibleInsertModel
        },
        state.authData
      );

      if (dashboardInsertRequest) {
        return dashboardInsertRequest
          .then(response => {
            if (isSuccessApiResponse(response)) {
              dispatch(downloadCommunityDashboardsSuccess());
              dispatch(storeTemporaryDashboardDetailed(null));
              dispatch(
                loadCommunityDashboards(
                  state.communityData.selectedCommunityForDashboard
                )
              );
            }
          })
          .catch(error => {
            console.log(error);
            dispatch(downloadCommunityDashboardsFail(error));
            return Promise.reject(error);
          });
      }
    }
  };
};
export const downloadCommunityDashboardsBegin = () => {
  return {
    type: actionTypes.DOWNLOAD_COMMUNITYDASHBOARD_BEGIN
  };
};

export const downloadCommunityDashboardsSuccess = () => {
  toastSuccess("Successfully downloaded community dashboards");
  return {
    type: actionTypes.DOWNLOAD_COMMUNITYDASHBOARD_SUCCESS
  };
};

export const downloadCommunityDashboardsFail = error => {
  toastException("Failed to download community dashboards", error);
  return {
    type: actionTypes.DOWNLOAD_COMMUNITYDASHBOARD_FAIL,
    error
  };
};

export const loadCommunityDashboardDetailedData = () => {
  return (dispatch, getState) => {
    const state = getState();
    const selectedDashboard = state.communityData.selectedDashboard;
    const selectedCommunity = state.communityData.selectedCommunityForDashboard;
    if (
      selectedCommunity &&
      selectedCommunity.id &&
      selectedDashboard &&
      selectedDashboard.id
    ) {
      const dashboardRequest = request.portalRequest(
        {
          url: `/Community/${selectedCommunity.id}/Dashboard/${
            selectedDashboard.id
          }`,
          method: "GET"
        },
        state.authData
      );

      if (dashboardRequest) {
        return dashboardRequest
          .then(response => {
            if (isSuccessApiResponse(response)) {
              return response.data.dashboard;
            }
          })
          .catch(error => {
            toastError(
              "Error occurred while loading dashboard definition for download. ",
              error
            );
            return null;
          });
      } else {
        return Promise.reject(
          "Error occurred while downloading dashboard details"
        );
      }
    }
  };
};

const getAPICompatibleDashboardInsertModel = communityDashboardDef => {
  const communityDashboard = JSON.parse(communityDashboardDef);
  let dashboardInsertModel = [];
  const dashboardModel = getDashboardItemInsertModel(communityDashboard);
  const dashboardDataSourceItemModel = getdashboardDataSourceItemInsertModel(
    communityDashboard
  );
  const dashboardPeriodizerItem = getdashboardPeriodizerItemInsertModel(
    communityDashboard
  );
  const dashboardCombinedDataSourceItem = getdashboardCombinedDataSourceItemInsertModel(
    communityDashboard
  );

  dashboardInsertModel.push(dashboardModel);
  dashboardInsertModel.push(...dashboardDataSourceItemModel);
  dashboardInsertModel.push(...dashboardPeriodizerItem);
  dashboardInsertModel.push(...dashboardCombinedDataSourceItem);

  return dashboardInsertModel;
};

const getDashboardItemInsertModel = communityDashboard => {
  if (communityDashboard.Dashboard && communityDashboard.Dashboard.length > 0) {
    const dashboardModel = {
      ...communityDashboard.Dashboard[0],
      ...{
        $type: getApiCompatibleModelType("Reporting", "Dashboard"),
        RecordState: 2
      }
    };
    return dashboardModel;
  }
  return [];
};

const getdashboardDataSourceItemInsertModel = communityDashboard => {
  let itemsInsertModel = [];

  if (
    communityDashboard.DashboardDataSourceItem &&
    communityDashboard.DashboardDataSourceItem.length > 0
  ) {
    communityDashboard.DashboardDataSourceItem.map(item => {
      const dataSourceItemModel = {
        ...item,
        ...{
          $type: getApiCompatibleModelType(
            "Reporting",
            "DashboardDataSourceItem"
          ),
          RecordState: 2
        }
      };
      return itemsInsertModel.push(dataSourceItemModel);
    });
  }
  return itemsInsertModel;
};

const getdashboardPeriodizerItemInsertModel = communityDashboard => {
  let dashboardPeriodizerItemInsertModel = [];

  if (
    communityDashboard.DashboardPeriodizerItem &&
    communityDashboard.DashboardPeriodizerItem.length > 0
  ) {
    communityDashboard.DashboardPeriodizerItem.map(periodizerItem => {
      const dashboardPeriodizerItemModel = {
        ...periodizerItem,
        ...{
          $type: getApiCompatibleModelType(
            "Reporting",
            "DashboardPeriodizerItem"
          ),
          RecordState: 2
        }
      };
      return dashboardPeriodizerItemInsertModel.push(
        dashboardPeriodizerItemModel
      );
    });
  }
  return dashboardPeriodizerItemInsertModel;
};

const getdashboardCombinedDataSourceItemInsertModel = communityDashboard => {
  let dashboardCombinedDataSourceItemModels = [];

  if (
    communityDashboard.DashboardCombinedDataSourceItem &&
    communityDashboard.DashboardCombinedDataSourceItem.length > 0
  ) {
    communityDashboard.DashboardCombinedDataSourceItem.map(
      combinedDataSource => {
        const combinedModel = {
          ...combinedDataSource,
          ...{
            $type: getApiCompatibleModelType(
              "Reporting",
              "DashboardCombinedDataSourceItem"
            ),
            RecordState: 2
          }
        };
        return dashboardCombinedDataSourceItemModels.push(combinedModel);
      }
    );
  }
  return dashboardCombinedDataSourceItemModels;
};

// Community Selection
export const communitySelectionChanged = data => {
  return {
    type: actionTypes.DASHBOARD_COMMUNITY_SELECTION_CHANGED,
    selectedCommunity: data.addedItems[0]
  };
};

// Periodizer information modal display
export const periodizerSettingDisplayConfigChanged = display => {
  return {
    type: actionTypes.DASHBOARD_COMMUNITY_PERIODIZERINFODISPLAY_CHANGED,
    display
  };
};

// Store Temporary Dashboard Detailed
export const storeTemporaryDashboardDetailed = dashboardDetailed => {
  return {
    type: actionTypes.DASHBOARD_COMMUNITY_DETAILED_TEMP,
    dashboard: dashboardDetailed
  };
};
