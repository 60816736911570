
import * as request from "../../shared/request";
import { getApiCompatibleModelType } from "../../shared/Utility/dataUtility";
import * as utility from "../../shared/Utility/commonUtility";
import moment from "moment";
import packageJson from '../../../package.json';

export const logActivity = (action,details) => {
    return (dispatch,getState) => {
        const state = getState();

        if(utility.checkIfUserIdIsValid(state.authData)){
          request.apiRequest(
            {
              url: "/save",
              method: "POST",
              data: [{
                  Action: action,
                  Details: details,
                  User: state.authData.userId,
                  Time: moment.utc(),
                  Client: "WebUI",
                  Version: packageJson.version,
                  RecordState : 2, //"New"
                  $type: getApiCompatibleModelType("Security", "ActivityLog"),
              }]
            },
            state.authData
          );
        }
    }
}