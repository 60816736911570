import React from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import FilterParameterWithOperation from "./FilterParameterWithOperationComponent";
import PromineoStepWizard from "../Common/Controls/StepWizard/PromineoStepWizardComponet";
import * as dashboardActions from "../../store/actions/dashboardActions";
import WithDashboardStepNavigation from "../hoc/WithDashboardStepNavigation";
import DashboardListStep from "./StepNavigation/DashboardStepNavigationComponent";
import FilterStep from "./StepNavigation/FilterStepComponent";
import "devextreme/dist/css/dx.light.compact.css";
import "./../../styles/dashboard/dashboardStyle.css";

const FilterParameterOperation = WithDashboardStepNavigation(
  FilterParameterWithOperation
);
class Dashboards extends React.Component {
  // TODO: On constructor clear filter selection and filter parameter data
  componentDidMount() {
    this.props.clearAllDashboardSelectionData();
  }

  render() {
    const {
      selectedDashboardId,
      selectedDatasourceId,
      selectedFilterId,
    } = this.props;

    return (
      <Container>
        <Row>
          <Col>
            <PromineoStepWizard>
              <DashboardListStep />
              <FilterStep selectedDatasourceId={selectedDatasourceId} />
              <FilterParameterOperation
                selectedDashboardId={selectedDashboardId}
                selectedFilterId={selectedFilterId}
              />
            </PromineoStepWizard>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedDashboardId: state.dashboardData.selectedDashboard
      ? state.dashboardData.selectedDashboard.PrimKey : null,
    selectedDatasourceId: state.dashboardData.selectedDatasourceId,
    selectedFilterId: state.dashboardData.selectedFilterId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearAllDashboardSelectionData: () =>
      dispatch(dashboardActions.clearDashboardSelectionData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboards);
