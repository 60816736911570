import PromineoPopover from "../../Common/PromineoPopover";
import Link from "../../Common/Link";
import { Offset, Position } from "devextreme-react/popup";
import React from "react";
import settingIconSvg from "../../../static/images/settings.svg";
import userIconSvg from "../../../static/images/user.svg";
import AccountAvatar from "./AccountAvatar";
import { withRouter } from "react-router-dom";
import * as routePath from "../../../shared/Constants/routePathConstants";

export function AccountPopover(props) {
  const { user } = props;

  const handleSettingClicked = () => {
    props.history.push(routePath.SETTINGS);
  };

  const handleTenantsClicked = () => {
    props.history.push(routePath.TENANTS);
  };

  const AccountOption = (optionProps) => {
    const { icon, altText, text, onClick } = optionProps;
    return (
      <div
        className="text-sm mb-1 flex font-medium cursor-pointer hover:bg-gray-4 py-1 px-2 rounded-sm"
        onClick={onClick}
      >
        <img src={icon} alt={altText} className="mr-2" />
        {text}
      </div>
    );
  };

  const Content = () => {
    return (
      <div>
        <div className="flex ml-2 mt-2">
          <div className="flex-shrink-0 mr-3">
            <AccountAvatar user={user} />
          </div>
          <div className="truncate">
            <div className="text-sm font-medium">
              {user.FirstName} {user.LastName}
            </div>
            <div className="text-xs font-normal">{user.EMail}</div>
          </div>
        </div>
        <div className="mt-3">
          <AccountOption
            icon={userIconSvg}
            altText="Tenants"
            text="Tenants"
            onClick={handleTenantsClicked}
          />
          <AccountOption
            icon={settingIconSvg}
            altText="settings"
            text="Settings"
            onClick={handleSettingClicked}
          />
        </div>
        <div className="mt-3 ml-2">
          <Link to={routePath.LOGOUT} className="text-primary-red font-bold">
            Sign out
          </Link>
        </div>
      </div>
    );
  };

  return (
    <>
      <PromineoPopover width={264} height={182} {...props}>
        <Position my={{ x: "right", y: "top" }}>
          <Offset x={61} y={0} />
        </Position>
        <Content />
      </PromineoPopover>
    </>
  );
}

export default withRouter(AccountPopover);
