import React from "react";
import { connect } from "react-redux";
import CommunityItem from "./CommunityListItemComponent";
import * as actions from "../../store/actions/communityActions";
import List from "devextreme-react/list";
import "devextreme/dist/css/dx.light.compact.css";

class CommunityList extends React.Component {
  componentDidMount() {
    this.props.onLoadCommunities();
  }

  handleSelectionChanged = data => {
    if(this.props.onSelectionChanged){
      this.props.onSelectionChanged(data);
    }
  }

  render() {
    const { communities } = this.props;

    const communityDataSource = {
      store: communities,
      sort: "name"
    };

    return (
      <List
        dataSource={communityDataSource}
        noDataText={"No community was found."}
        pageLoadingText={"Loading Communities.."}
        pageLoadMode={"scrollBottom"}
        itemComponent={CommunityItem}
        selectionMode={"single"}
        searchEnabled={true}
        searchExpr={"name"}
        searchMode={"contains"}
        searchEditorOptions={{
          placeholder: "Enter text to search...",
          height: 30,
          padding: 10
        }}
        onSelectionChanged={this.handleSelectionChanged}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    communities: state.communityData.communities
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLoadCommunities: () => dispatch(actions.loadCommunities()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunityList);
