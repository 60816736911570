
export const applicationRightFlags = {
    //                    0xSAAAUUU ::> SS : Setup, AAA : Admin, UUU : User
    None                : 0x0000000, // No rights

    User                : 0x0000001, // Required to start the MPP application, can read most genereric information
    Persons             : 0x0000002, // Required to start the Persons screen
    Projects            : 0x0000004, // Required to start the Projects screen
    Positions           : 0x0000008, // Required to start the Positions screen
    MyPositions         : 0x0000010, // Required to start the My Positions screen

    AbsenceRegister     : 0x0000020, // Required to register own absence
    AbsenceApproval     : 0x0000040, // Required to approve other's absence requests

    Dashboard           : 0x0000080, // Required to view dashboards
    Analyzer            : 0x0000100, // Required to start analyzer
    Reports             : 0x0000200, // Required to run reports
    FindPersons         : 0x0000400, // Required to search for persons across all organization units
    Wiki                : 0x0000800, // Required to view wiki

    UserAll             : 0x0000FFF, // All User Rights

    AdminPositionTypes  : 0x0001000, // Can setup position types
    AdminCustomFields   : 0x0002000, // Can setup custom fields
    AdminCalendars      : 0x0004000, // Can setup calendars
    AdminProjects       : 0x0008000, // Can setup projects, can also see and change all projects and positions
    AdminOrganization   : 0x0010000, // Can setup organization, can also see and change all organization and persons
    AdminReports        : 0x0020000, // Can create and modify reports
    AdminDashboards     : 0x0040000, // Can create and modify dashboards
    AdminSecurity       : 0x0080000, // Can setup rights for application
    AdminLayoutFilter   : 0x0100000, // Can modify and share all layouts & filters
    AdminAll            : 0x0FFF000, // All Admin rights
    
    SetupColors         : 0x1000000, 
//        Setup               : 0x2000000,
    SetupInterfaces     : 0x4000000,
    SetupSecurity       : 0x8000000,
    SetupAll            : 0xF000000
  }