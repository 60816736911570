export const FETCH_DATASOURCE_BEGIN = "FETCH_DATASOURCE_BEGIN";
export const FETCH_DATASOURCE_SUCCESS = "FETCH_DATASOURCE_SUCCESS";
export const FETCH_DATASOURCE_FAIL = "FETCH_DATASOURCE_FAIL";

export const FETCH_ALL_DATASOURCE_BEGIN = "FETCH_ALL_DATASOURCE_BEGIN";
export const FETCH_ALL_DATASOURCE_SUCCESS = "FETCH_ALL_DATASOURCE_SUCCESS";
export const FETCH_ALL_DATASOURCE_FAIL = "FETCH_ALL_DATASOURCE_FAIL";

export const FILTER_SELECTION_CHANGE = "FILTER_SELECTION_CHANGE";

export const FETCH_FILTERPARAMETERDEFINITION_BEGIN = "FETCH_FILTERPARAMETERDEFINITION_BEGIN";
export const FETCH_FILTERPARAMETERDEFINITION_SUCCESS = "FETCH_FILTERPARAMETERDEFINITION_SUCCESS";
export const FETCH_FILTERPARAMETERDEFINITION_FAIL = "FETCH_FILTERPARAMETERDEFINITION_FAIL";

export const FETCH_FILTERLOOKUPDEFINITION_BEGIN = "FETCH_FILTERLOOKUPDEFINITION_BEGIN";
export const FETCH_FILTERLOOKUPDEFINITION_SUCCESS = "FETCH_FILTERLOOKUPDEFINITION_SUCCESS";
export const FETCH_FILTERLOOKUPDEFINITION_FAIL = "FETCH_FILTERLOOKUPDEFINITION_FAIL";

export const CLEAR_FILTERPARAMETERDATA = "CLEAR_FILTERPARAMETERDATA";
export const CLEAR_PARAMETERDATA = "CLEAR_PARAMETERDATA";

export const UPDATE_PARAMETER ="UPDATE_PARAMETER";