import React from "react";
import { connect } from "react-redux";
import { Button } from "devextreme-react/button";
import * as communityActions from "../../store/actions/communityActions";
import * as dashboardActions from "../../store/actions/dashboardActions";
import * as communityUploadActions from "../../store/actions/communityUploadActions";
import {
  PromineoSelectBox,
  PromineoGrid
} from "../Common/Controls/ControlList";
import { Column } from "devextreme-react/data-grid";

class CommunityDashboardUpload extends React.Component {
 constructor(props){
   super(props);

   this.state = {
    selectedCommunity: null
   }
 }

  componentDidMount() {
    this.props.onLoadCommunities();
    this.props.onLoadAllDashboards();
  }

  onInputValueChanged = (controlName, value) => {
    this.setState({
      [controlName]: value
    });
  };

  handleSelectionChanged = selectedData => {
    if (selectedData && selectedData.selectedRowKeys.length > 0) {
      this.setState({
        selectedDashboardId: selectedData.selectedRowKeys[0]
      });
    }
  };

  uploadDashboard = (e, dashboard) => {
    e.event.stopPropagation();

    this.props.onUploadDashboard(this.state.selectedCommunity, dashboard);
  };

  cellRenderUpload = data => {
    return (
      <Button
        key={data.value}
        icon="upload"
        onClick={e => this.uploadDashboard(e, data.data)}
      />
    );
  };

  render() {
    const { communities, dashboards } = this.props;
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <PromineoSelectBox
              dataSource={communities}
              id="selectedCommunity"
              label="Select Community"
              displayExpr="name"
              valueExpr="id"
              onValueChanged={this.onInputValueChanged}
            />
          </div>
        </div>
        <div className="row">
          <div className="">
            <PromineoGrid
              allowMultiSelect={false}
              enablePaging={true}
              defaultPageSize={15}
              dataSource={dashboards}
              displayFilterRow={true}
              onSelectionChanged={this.handleSelectionChanged}
            >
              <Column dataField={"Title"} width={"30%"} sortOrder={"asc"}/>
              <Column dataField={"Description"} width={"70%"} />
              <Column
                dataField={"PrimKey"}
                caption={""}
                width={100}
                alignment={"center"}
                allowSorting={false}
                allowFiltering={false}
                cellRender={this.cellRenderUpload}
              />
            </PromineoGrid>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    communities: state.communityData.communities,
    dashboards: state.dashboardData.dashboards
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLoadCommunities: () => dispatch(communityActions.loadCommunities()),
    onLoadAllDashboards: () => dispatch(dashboardActions.loadDashboards()),
    onUploadDashboard: (communityId, dashboard) => dispatch(communityUploadActions.uploadDashbaordToCommunity(communityId, dashboard))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunityDashboardUpload);
