import React from "react";
import { connect } from "react-redux";
import PromineoDropwdownGrid from "../Common/Controls/PromineoDropwdownGridComponent";
import * as actions from "../../store/actions/datasourceActions";

class ParameterDropdownGrid extends React.Component {
  componentDidMount() {
    this.props.onLoadLookUpDefinition(this.props.columnRef);
  }

  render() {
    const { lookUpDefinition, columnRef } = this.props;
    if (columnRef && lookUpDefinition[columnRef]) {
      return (
        <PromineoDropwdownGrid
          label={this.props.label}
          id={this.props.id}
          allowMultiSelection={this.props.allowMultiSelection}
          readOnly={this.props.readOnly}
          required={this.props.required}
          defaultValue={this.props.defaultValue}
          columns={lookUpDefinition[columnRef].columns}
          displayColumn={lookUpDefinition[columnRef].displayColumn}
          valueColumn={lookUpDefinition[columnRef].valueColumn}
          datasource={lookUpDefinition[columnRef].datasource}
          onValueChanged={this.props.onValueChanged}
        />
      );
    } else {
      return <div />;
    }
  }
}

const mapStateToProps = state => {
  return {
    lookUpDefinition: state.dashboardData.lookUpDefinition
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLoadLookUpDefinition: comlumnRef =>
      dispatch(actions.loadFilterLookUpDefinition(comlumnRef))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParameterDropdownGrid);
