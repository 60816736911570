import * as actionTypes from "./ActionType/serviceAccountActionTypes";
import * as request from "../../shared/request";
import { toastSuccess, toastError } from "../../shared/Utility/toastUtility";
import { isSuccessApiResponse } from "../../shared/Utility/dataUtility";

export const addEditServiceAccountBegin = () =>{
    return {
        type: actionTypes.ADDEDIT_SERVICEACCOUNT_BEGIN
    }
}

export const addEditServiceAccountSuccess = (serviceAccount, isNew) => {
    toastSuccess("Service account saved successfully.");
    return {
        type: actionTypes.ADDEDIT_SERVICEACCOUNT_SUCCESS,
        serviceAccountData: {serviceAccount, isNew}
    }
}

export const addEditServiceAccountFail = (error) => {
    toastError("Failed to save service account.");
    return {
        type: actionTypes.ADDEDIT_SERVICEACCOUNT_FAIL
    }
}

export const loadServiceAccountBegin = () => {
    return {
        type: actionTypes.LOAD_SERVICEACCOUNT_BEGIN
    }
}

export const loadServiceAccountSuccess = (serviceAccounts) => {
    return {
        type: actionTypes.LOAD_SERVICEACCOUNT_SUCCESS,
        serviceAccounts
    }
}

export const loadServiceAccountFail = () => {
    return {
        type: actionTypes.LOAD_SERVICEACCOUNT_FAIL
    }
}

export const deleteServiceAccountBegin = () => {
    return {
        type: actionTypes.DELETE_SERVICEACCOUNT_BEGIN
    }
}

export const deleteServiceAccountSuccess = () => {
    toastSuccess("Deleted service account successfully.");
    return {
        type: actionTypes.DELETE_SERVICEACCOUNT_SUCCESS,
    }
}

export const deleteServiceAccountFail = (error) => {
    toastError("Failed to delete service account.");
    return {
        type: actionTypes.DELETE_SERVICEACCOUNT_FAIL
    }
}

export const createSasTokenBegin = () => {
    return {
        type: actionTypes.CREATE_SASTOKEN_BEGIN
    }
}

export const createSasTokenSuccess = (sasToken) => {
    return {
        type: actionTypes.CREATE_SASTOKEN_SUCCESS,
        sasToken
    }
}

export const createSasTokenFail = (error) => {
    toastError("Failed to create sas token.");
    return {
        type: actionTypes.CREATE_SASTOKEN_FAIL
    }
}

export const loadServiceAccounts = () => {
    return (dispatch,getState) => {
        dispatch(loadServiceAccountBegin());
        const state = getState();

        const tenantId = state.authData.selectedTenant
            ? state.authData.selectedTenant.TenantId
            : null;

        const serviceAccountRequest = request.portalRequest(
            {
                url: `/ServiceAccounts/ServiceAccountList/${tenantId}`,
                method: "GET"
            },
            state.authData
        );

        if(serviceAccountRequest){
            serviceAccountRequest.then(response => {
                if (isSuccessApiResponse(response)) {
                    let serviceAccounts = response.data;
                    dispatch(loadServiceAccountSuccess(serviceAccounts));
                }
            }).catch(error => {
                dispatch(loadServiceAccountFail(error));
            });
        }
    }
}

export const addEditServiceAccount = (serviceAccount) => {
    return (dispatch,getState) => {
        dispatch(addEditServiceAccountBegin());
        const state = getState();
        const url = `/ServiceAccounts`;
        const isNew = !serviceAccount.id;
        const saveAccountRequest = request.portalRequest(
            {
                url: serviceAccount.id ? `${url}/${serviceAccount.id}` : url,
                method: isNew ? "POST" : "PUT",
                data: serviceAccount
            },
            state.authData
        );

        if(saveAccountRequest){
            saveAccountRequest.then(response => {
                if (isSuccessApiResponse(response)) {
                    let serviceAccounts = response.data;
                    dispatch(addEditServiceAccountSuccess(serviceAccounts, isNew));
                }
            }).catch(error => {
                dispatch(addEditServiceAccountFail(error));
            });
        }
    }
}

export const deleteServiceAccount = (serviceAccount) => {
    return (dispatch,getState) => {
        dispatch(deleteServiceAccountBegin());
        const state = getState();

        const deleteAccountRequest = request.portalRequest(
            {
                url: `/ServiceAccounts/${serviceAccount.id}`,
                method: "DELETE",
            },
            state.authData
        );

        if(deleteAccountRequest){
            deleteAccountRequest.then(response => {
                if (response && response.Status === 204) {
                    dispatch(deleteServiceAccountSuccess());
                    dispatch(loadServiceAccounts());
                }
            }).catch(error => {
                dispatch(deleteServiceAccountFail(error));
            });
        }
    }
}

export const createSasToken = (serviceAccount,validTill) => {
    return (dispatch,getState) => {
        dispatch(createSasTokenBegin());
        const state = getState();
        const tenantId = state.authData.selectedTenant
            ? state.authData.selectedTenant.TenantId
            : null;

        const url = `AccessControl/createsastoken/${tenantId}/${serviceAccount.serviceId}/${validTill}`;
        const createSasTokenRequest = request.portalRequest(
            {
                url: url,
                method: "GET",
            },
            state.authData
        );

        if(createSasTokenRequest){
            createSasTokenRequest.then(response => {
                if (isSuccessApiResponse(response)) {
                    dispatch(createSasTokenSuccess(response.data));
                }
            }).catch(error => {
                dispatch(createSasTokenFail(error));
            });
        }
    }
}