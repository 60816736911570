import React from "react";
import NavItem from "./NavItem";
import "../styles/Navbar.css";
import { checkIfNavItemIsActive } from "../../../shared/Utility/routeUtility";
import { withRouter } from "react-router-dom";

export function Navbar(props) {
  return (
    <div className="navigation-menu flex gap-x-6 font-inter">
      {props.items.map((item) => {
        return (
          <NavItem
            text={item.text}
            route={item.route}
            itemId={item.itemId}
            children={item.children ? item.children : []}
            isActive={checkIfNavItemIsActive(
              props.history.location.pathname,
              item
            )}
            key={item.itemId}
          />
        );
      })}
    </div>
  );
}

export default withRouter(Navbar);
