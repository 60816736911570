import React from "react";
import { Container, Row } from "react-bootstrap";
import FilterParameter from "./FilterParameterComponent";
import DashboardOperation from "./DashboardOperationComponent";

class FilterParameterWithOperation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validationGroupRef: React.createRef(),
    };
  }

  render() {
    return (
      <Container>
        <Row>
          <FilterParameter
            selectedFilterId={this.props.selectedFilterId}
            validationGroupRef={this.state.validationGroupRef}
          />
        </Row>
        <Row>
          <DashboardOperation
            selectedDashboardId={this.props.selectedDashboardId}
            selectedFilterId={this.props.selectedFilterId}
            validationGroupRef={this.state.validationGroupRef}
          />
        </Row>
      </Container>
    );
  }
}

export default FilterParameterWithOperation;
