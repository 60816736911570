import React from "react";
import { DropDownBox } from "devextreme-react/drop-down-box";
import DataGrid, { Selection, Scrolling } from "devextreme-react/data-grid";
import { Validator, RequiredRule } from "devextreme-react/validator";

class PromineoDropwdownGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gridBoxValue: this.getDefaultValue(),
    };
    this.dropdownRef = React.createRef();
    this.handleDataGridSelectionChanged =
      this.handleDataGridSelectionChanged.bind(this);
    this.getDataGridEditor = this.getDataGridEditor.bind(this);
  }

  getDefaultValue = () => {
    return this.props.defaultValue ? this.props.defaultValue : null;
  };

  getIfMultiSelectionAllowed = () => {
    return this.props.allowMultiSelection
      ? this.props.allowMultiSelection
      : false;
  };

  getSelectionMode = () => {
    return this.getIfMultiSelectionAllowed() ? "multiple" : "single";
  };

  getId = () => {
    return this.props.id !== undefined ? this.props.id : null;
  };

  getLabel = () => {
    return this.props.label ? this.props.label : "";
  };

  getValueColumn = () => {
    return this.props.valueColumn ? this.props.valueColumn : "PrimKey";
  };

  getDisplayColumn = () => {
    return this.props.displayColumn ? this.props.displayColumn : "PrimKey";
  };

  getColumns = () => {
    return this.props.columns ? this.props.columns : [];
  };

  getReadOnly = () => {
    return this.props.readOnly ? this.props.readOnly : false;
  };

  getRequired = () => {
    return this.props.required ? this.props.required : false;
  };

  getDatasource = () => {
    return this.props.datasource ? this.props.datasource : [];
  };

  syncDataGridSelection = (e) => {
    this.setState(
      {
        gridBoxValue: e
          ? e.value
          : this.getIfMultiSelectionAllowed()
          ? []
          : null,
      },
      () => {
        if (this.props.onValueChanged) {
          this.props.onValueChanged(this.state.gridBoxValue);
        }
      }
    );
  };

  handleDataGridSelectionChanged(e) {
    const newValue = this.getIfMultiSelectionAllowed()
      ? (e.selectedRowKeys.length && e.selectedRowKeys) || []
      : (e.selectedRowKeys.length && e.selectedRowKeys[0]) || null;

    this.setState(
      {
        gridBoxValue: newValue,
      },
      () => {
        if (this.props.onValueChanged) {
          this.props.onValueChanged(this.state.gridBoxValue);
        }
      }
    );
  }

  closeDropdownPopup = () => {
    if (this.dropdownRef.current && this.dropdownRef.current.instance) {
      this.dropdownRef.current.instance.close();
    }
  };

  getDataGridEditor() {
    return (
      <DataGrid
        dataSource={this.getDatasource()}
        keyExpr={this.getValueColumn()}
        columns={this.getColumns()}
        selectedRowKeys={this.state.gridBoxValue}
        hoverStateEnabled={true}
        allowColumnResizing={true}
        columnResizingMode={"widget"}
        onSelectionChanged={this.handleDataGridSelectionChanged}
        columnMinWidth={50}
        columnAutoWidth={true}
        height="100%"
      >
        <Selection mode={this.getSelectionMode()} />
        <Scrolling mode={"infinite"} />
      </DataGrid>
    );
  }

  render() {
    const validatorRules = [];
    const requredValidator = (
      <RequiredRule
        key={`${this.getId()}-RequiredRule`}
        message={`${this.getLabel()} is required`}
      />
    );

    if (this.getRequired()) {
      validatorRules.push(requredValidator);
    }

    return (
      <div className="form-group">
        <label htmlFor={this.getId()}>{this.getLabel()}</label>
        <DropDownBox
          ref={this.dropdownRef}
          value={this.state.gridBoxValue}
          valueExpr={this.getValueColumn()}
          deferRendering={false}
          displayExpr={this.getDisplayColumn()}
          placeholder={`Select ${this.getLabel()}...`}
          showClearButton={true}
          readOnly={this.getReadOnly()}
          required={this.getRequired()}
          dataSource={this.getDatasource()}
          onValueChanged={this.syncDataGridSelection}
          contentRender={this.getDataGridEditor}
          dropDownOptions={{
            width: "650px",
            toolbarItems: [
              {
                toolbar: "bottom",
                widget: "dxButton",
                location: "after",
                options: {
                  text: "Apply",
                  onClick: () => {
                    this.closeDropdownPopup();
                  },
                },
              },
            ],
          }}
        >
          <Validator>{validatorRules}</Validator>
        </DropDownBox>
      </div>
    );
  }
}

export default PromineoDropwdownGrid;
