import React from "react";
import CompanyName from "./CompanyName";
import PromineoLogo from "./PromineoLogo";

export default function HeaderBanner() {
  return (
    <div>
      <CompanyName />
      <div className="flex">
        <span className="mr-1">part of </span>
        <div className="mt-1">
          <PromineoLogo />
        </div>
      </div>
    </div>
  );
}
