export const AUTH_START = 'AUTH_START'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAIL = 'AUTH_FAIL'
export const AUTH_SET_REDIRECT_PATH = 'AUTH_SET_REDIRECT_PATH'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_LOGOUT_FINISH = 'AUTH_LOGOUT_FINISH'
export const AUTH_SET_USERINFO = 'AUTH_SET_USERINFO'

export const LOGIN_TENANT_START = "LOGIN_TENANT_START";
export const LOGIN_TENANT_SUCCESS = "LOGIN_TENANT_SUCCESS";
export const LOGIN_TENANT_FAIL = "LOGIN_TENANT_FAIL";

export const LOAD_TENANT_START = "LOAD_TENANT_START";
export const LOAD_TENANT_SUCCESS = "LOAD_TENANT_SUCCESS";
export const LOAD_TENANT_FAIL = "LOAD_TENANT_FAIL";

export const LOAD_APPLICATION_RIGHTS_START = "LOAD_APPLICATION_RIGHTS_START";
export const LOAD_APPLICATION_RIGHTS_SUCCESS = "LOAD_APPLICATION_RIGHTS_SUCCESS";
export const LOAD_APPLICATION_RIGHTS_FAIL = "LOAD_APPLICATION_RIGHTS_FAIL";

export const LOAD_DATA_ACCESS_START = "LOAD_DATA_ACCESS_START";
export const LOAD_DATA_ACCESS_SUCCESS = "LOAD_DATA_ACCESS_SUCCESS";
export const LOAD_DATA_ACCESS_FAIL = "LOAD_DATA_ACCESS_FAIL";

export const LOAD_MYSELF_START = "LOAD_MYSELF_START";
export const LOAD_MYSELF_SUCCESS = "LOAD_MYSELF_SUCCESS";
export const LOAD_MYSELF_FAIL = "LOAD_MYSELF_FAIL";

export const LOAD_AUTHENTICATION_PROVIDERS_START = "LOAD_AUTHENTICATION_PROVIDERS_START";
export const LOAD_AUTHENTICATION_PROVIDERS_SUCCESS = "LOAD_AUTHENTICATION_PROVIDERS_SUCCESS";
export const LOAD_AUTHENTICATION_PROVIDERS_FAIL = "LOAD_AUTHENTICATION_PROVIDERS_FAIL";

export const SILENT_AUTHENTICATION_START = "SILENT_AUTHENTICATION_START";
export const SILENT_AUTHENTICATION_FINISH = "SILENT_AUTHENTICATION_FINISH";

