import React from "react";
import "./../../styles/dashboard/dashboardItemStyle.css";

const DashboardItem = ({data}) => {
  let thumbnailData = <div />;
  if (data.ThumbnailImage != null) {
    thumbnailData = (
      <img
        src={"data:image/png;base64," + data.ThumbnailImage}
        alt="Thumbnail"
        className="dashboardThumbnailPreview"
      />
    );
  }
  return (
    <div className="container">
      <div className="row">
        <div className="col-6 dashboardTextInfo">
          <p className="dashboardTitle">{data.Title}</p>
          <p>{data.Description}</p>
        </div>
        <div className="col-6 dashboardThumbnail">{thumbnailData}</div>
      </div>
    </div>
  );
};

export default DashboardItem;
