export const getToday = () => {
  let today = new Date();
  today.setUTCHours(0, 0, 0, 0);

  return today;
};

export const addDays = (dateToChange, numberOfDays) => {
  if (dateToChange instanceof Date) {
    dateToChange.setDate(dateToChange.getDate() + numberOfDays);
    dateToChange.setUTCHours(0, 0, 0, 0);
    return dateToChange;
  } else {
    console.log("Invalid date param", dateToChange);
    return null;
  }
};