import React, { useState, useEffect } from "react";
import * as actions from "../../store/actions/serviceAccountActions";
import { connect } from "react-redux";
import { Column, Editing } from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import Popup from "devextreme-react/popup";
import { NumberBox } from "devextreme-react/number-box";
import { TextArea } from "devextreme-react/text-area";
import { GetNewId } from "../../shared/Utility/uidUtility";
import { Container } from "react-bootstrap";
import { PromineoGrid } from "../Common/Controls/ControlList";
import * as utility from "../../shared/Utility/commonUtility";
import { toast } from "react-toastify";

export function ServiceAccountSettingsComponent({
  serviceAccounts,
  generatedSasToken,
  loadServiceAccounts,
  saveServiceAccount,
  deleteServiceAccount,
  createSasToken,
  selectedTenant
}) {
  const [accounts, setAccounts] = useState([]);
  const [popupVisible, setPopupVisible] = useState(false);
  const [validTill, setValidTill] = useState(24);
  const [sasToken, setSasToken] = useState("");
  const [currentRow, setCurrentRow] = useState({});
  const [dataGridRef, setDataGridRef] = useState(React.createRef());
  const defaultTokenVailidityHour = 720;
  const maxTokenVailidityHour = 9000;

  useEffect(() => {
    loadServiceAccounts();
  }, []);

  useEffect(() => {
    setAccounts([...serviceAccounts]);
  }, [serviceAccounts]);

  useEffect(() => {
    setSasToken(generatedSasToken);
  }, [generatedSasToken]);

  const onSave = (evt) => {
    saveServiceAccount(evt.data);
  };

  const onRemove = (evt) => {
    deleteServiceAccount(evt.data);
    evt.cancel = true;
  };

  const onRefHandler = (data) => {
    setDataGridRef(data);
  };

  const generateKey = (evt) => {
    let serviceAccount = evt.row.data;
    dataGridRef.dataGrid.cellValue(evt.row.rowIndex, "primaryKey", GetNewId());
    dataGridRef.dataGrid.cellValue(
      evt.row.rowIndex,
      "secondaryKey",
      serviceAccount.primaryKey
    );
  };

  const onInitNewRow = (event) => {
    event.data.serviceLevel = 2; //default service level - Tenant Interface: 2
    event.data.primaryKey = GetNewId();
    event.data.secondaryKey = GetNewId();
    if (selectedTenant) {
      event.data.tenantId = selectedTenant.TenantId;
    }
  };

  const isKeyButtonVisible = (event) => {
    return event.row.isEditing;
  };

  const handleTokenPopup = (event) => {
    setCurrentRow(event.row.data);
    setValidTill(defaultTokenVailidityHour);
    setSasToken("");
    setPopupVisible(true);
  };

  const handleTokenCreate = () => {
    createSasToken(currentRow, validTill);
  };

  const getToolbar = () => {
    return {
      items: [
        {
          location: "before",
          widget: "dxButton",
          options: {
            text: "Add service account",
            icon: "plus",
            onClick: () => {
              dataGridRef.dataGrid.addRow()
            },
          },
        }
      ]
    }
  };

  const copyToClipBoard = () => {
    utility.copyTextToClipboard(sasToken);
    toast.success("Token copied to clipboard.");
  };

  return (
    <>
      <Container className="mt-3 mb-3">
        <PromineoGrid
          dataSource={accounts}
          keyExpression="id"
          enablePaging={true}
          showBorders={true}
          onRowInserting={onSave}
          onInitNewRow={onInitNewRow}
          onRowUpdated={onSave}
          onRowRemoving={onRemove}
          defaultPageSize={20}
          onRef={onRefHandler}
          toolbar={getToolbar()}
          displaySearchPanel={true}
        >
          <Editing
            mode="row"
            useIcons={true}
            allowUpdating={true}
            allowDeleting={true}
          />

          <Column dataField="serviceId" caption="Service Id" />
          <Column
            dataField="primaryKey"
            caption="Primary Key"
            allowEditing={false}
          />
          <Column
            dataField="secondaryKey"
            caption="Secondary Key"
            allowEditing={false}
          />
          <Column
            type="buttons"
            width={100}
            buttons={[
              "edit",
              "delete",
              {
                hint: "Token",
                icon: "refresh",
                onClick: handleTokenPopup,
              },
              {
                hint: "Key",
                icon: "key",
                visible: isKeyButtonVisible,
                onClick: (evt) => generateKey(evt),
              },
            ]}
          />
        </PromineoGrid>

        <Popup
          visible={popupVisible}
          onHiding={() => setPopupVisible(false)}
          dragEnabled={false}
          hideOnOutsideClick={true}
          showTitle={true}
          title="Token"
          width={450}
          height={220}
        >
          <Container>
            <div className="dx-field">
              <div className="dx-field-label">Valid hours</div>
              <div className="dx-field-value">
                <NumberBox
                  max={maxTokenVailidityHour}
                  min={0}
                  value={validTill}
                  className="mt-1"
                  showSpinButtons={true}
                  onValueChanged={(e) => setValidTill(e.value)}
                />
              </div>
            </div>
            <div className="dx-field">
              <div className="dx-field-label">Token</div>
              <div className="dx-field-value">
                <TextArea
                  stylingMode="filled"
                  readOnly={true}
                  value={sasToken}
                  height={90}
                ></TextArea>
              </div>
            </div>

            <div>
              <div>
                <Button
                  className={"ml-2 mt-2 float-right"}
                  text="Get Token"
                  type="success"
                  stylingMode="contained"
                  onClick={handleTokenCreate}
                />
              </div>
              <div>
                <Button
                  className={"mt-2 float-right"}
                  text="Copy to Clipboard"
                  disabled={sasToken.length === 0}
                  type="default"
                  stylingMode="contained"
                  onClick={copyToClipBoard}
                />
              </div>
            </div>
          </Container>
        </Popup>
      </Container>
    </>
  );
}

const mapStateToProps = (state, props) => {
  return {
    serviceAccounts: state.serviceAccountData.serviceAccounts,
    generatedSasToken: state.serviceAccountData.generatedSasToken,
    selectedTenant: state.authData.selectedTenant
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadServiceAccounts: () => dispatch(actions.loadServiceAccounts()),

    saveServiceAccount: (account) => {
      dispatch(actions.addEditServiceAccount(account));
    },
    deleteServiceAccount: (account) => {
      dispatch(actions.deleteServiceAccount(account));
    },
    createSasToken: (account, validTill) =>
      dispatch(actions.createSasToken(account, validTill)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceAccountSettingsComponent);
