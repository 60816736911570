import React from "react";
import StepWizard from "react-step-wizard";

class PromineoStepWizard extends React.Component {
  render() {
    return (
      <StepWizard isLazyMount {...this.props}>
        {this.props.children}
      </StepWizard>
    );
  }
}

export default PromineoStepWizard;
