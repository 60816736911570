import React from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/datasourceActions";
import ValidationSummary from "devextreme-react/validation-summary";
import ParameterDropdownGrid from "./ParameterDropdownGridComponent";
import "./../../styles/dashboard/filterParameterStyle.css";
import { ValidationGroup } from "devextreme-react/validation-group";
import moment from "moment";
import {
  PromineoTextBox,
  PromineoDateBox,
} from "../Common/Controls/ControlList";

class FilterParmeterDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (this.props.selectedFilterId && !this.props.displayForDashboardLink) {
      this.props.onLoadFilterParameterDefinition(this.props.selectedFilterId);
    }
  }

  handleInputChange = (id, value) => {
    if (!value) value = "";
    this.props.onParameterUpdate(id, value);
  };

  createTextInput = (definition, i) => {
    if (definition.value) {
      this.handleInputChange(definition.caption, definition.value);
    }
    return (
      <div className={"dx-field"} key={i}>
        <PromineoTextBox
          type="text"
          name={definition.caption}
          label={definition.caption}
          id={definition.caption}
          defaultValue={definition.value}
          placeholder={definition.columnName}
          required={
            definition.datasourceParameter &&
            definition.datasourceParameter.Required
          }
          readOnly={
            definition.datasourceParameter &&
            definition.datasourceParameter.ReadOnly
          }
          onValueChanged={this.handleInputChange}
        />
      </div>
    );
  };

  createDateInput = (definition, i) => {
    if (definition.value) {
      this.handleInputChange(definition.caption, definition.value);
    }
    return (
      <div className={"dx-field"} key={i}>
        <PromineoDateBox
          type={"date"}
          label={definition.caption}
          id={definition.caption}
          className={"filterParameterDateControl"}
          showClearButton={true}
          placeholder={definition.columnName}
          useMaskBehavior={true}
          displayFormat={"shortdate"}
          defaultValue={definition.value}
          readOnly={
            definition.datasourceParameter &&
            definition.datasourceParameter.ReadOnly
          }
          onValueChanged={this.handleInputChange}
        />
      </div>
    );
  };

  replaceCurrentUserFilterInfo(definition) {
    if (definition.value === "@@CurrentUserID") {
      definition.value = this.props.myselfData
        ? this.props.myselfData.PrimKey
        : "";
    } else if (definition.value === "@@CurrentUserLogin") {
      definition.value = this.props.myselfData
        ? this.props.myselfData.Login
        : "";
    } else if (definition.value === "@@PersonId") {
      definition.value = this.props.myselfData
        ? this.props.myselfData.PersonRef
        : "";
    }
  }

  formatDefinitionDateformat = (definition) => {
    if (!definition.value || !moment(definition.value).isValid()) return;
    definition.value = moment(definition.value)
      .startOf("day")
      .format("MM/DD/YYYY");
  };

  updateParameterDefinitionWithSelectedValue = (filterParameterDefinitions) => {
    filterParameterDefinitions.forEach((paramDef) => {
      var param = this.props.dashboardLinkFilterParameters[paramDef.caption];
      if (param) {
        paramDef.value = param;
      }
      return paramDef;
    });
  };

  getFilterParameterDefinitions = () => {
    return this.props.editableParametersOnly
      ? this.props.filterParameterDefinition.filter(
          (pd) =>
            pd.datasourceParameter && pd.datasourceParameter.ReadOnly === false
        )
      : this.props.filterParameterDefinition;
  };

  createFilterParameterInputs() {
    let filterParameterDefinitions = this.getFilterParameterDefinitions();

    if (this.props.displayForDashboardLink) {
      // Try to set filterParameters value(selected value while creating dashboard link) to filterParameterDefinitions
      this.updateParameterDefinitionWithSelectedValue(
        filterParameterDefinitions
      );
    }

    if (filterParameterDefinitions) {
      return (
        <ValidationGroup ref={this.props.validationGroupRef}>
          {filterParameterDefinitions.map((def, i) => {
            this.replaceCurrentUserFilterInfo(def);

            if (def.column) {
              if (def.column.LookupType) {
                return (
                  <div className={"dx-field"} key={i}>
                    <ParameterDropdownGrid
                      key={i}
                      label={def.caption}
                      id={def.caption}
                      columnRef={def.column.PrimKey}
                      allowMultiSelection={def.multiSelect}
                      defaultValue={
                        def.value
                          ? def.multiSelect
                            ? def.value.split(",")
                            : def.value
                          : null
                      }
                      readOnly={
                        def.datasourceParameter &&
                        def.datasourceParameter.ReadOnly
                      }
                      required={
                        def.datasourceParameter &&
                        def.datasourceParameter.Required
                      }
                      onValueChanged={(value) => {
                        const selectedValue =
                          value && def.multiSelect ? value.join() : value;
                        this.handleInputChange(def.caption, selectedValue);
                      }}
                    />
                  </div>
                );
              }

              if (
                def.column.DataType &&
                def.column.DataType === "System.DateTime"
              ) {
                this.formatDefinitionDateformat(def);
                return this.createDateInput(def, i);
              }
            }

            return this.createTextInput(def, i);
          })}
          <ValidationSummary id={"summary"} />
        </ValidationGroup>
      );
    } else {
      return;
    }
  }

  render() {
    return this.createFilterParameterInputs();
  }
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    filterParameterDefinition: state.dashboardData.filterParameterDefinition,
    dashboardLinkFilterParameters: state.dashboardData.dashboardLinkFilterParameters,
    myselfData: state.authData.myself,
    selectedFilterId: props.selectedFilterId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadFilterParameterDefinition: (selectedFilterId) =>
      dispatch(actions.loadFilterParameterDefinition(selectedFilterId)),
    onParameterUpdate: (id, value) =>
      dispatch(actions.updateParameter(id, value)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterParmeterDisplay);
