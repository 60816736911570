import React, { Component } from "react";
import SimpleMDE from "react-simplemde-editor";
import { Validator } from "devextreme-react/validator";
import TextBox from "devextreme-react/text-box";
import "easymde/dist/easymde.min.css";

class PromineoWikiEditor extends Component {
  constructor(props) {
    super(props);
    this.currentValue = "";
  }

  fireChangeEvent = value => {
    this.currentValue = value;

    if (this.props.onChange !== undefined) {
      this.props.onChange(this.getId(), value);
    }
  };

  wikiEditorAdapter = {
    getValue: () => {
      return this.currentValue;
    },
    validationRequestsCallbacks: []
  };

  getDefaultValue = () => {
    const defaultValue =
      this.props.defaultValue !== undefined ? this.props.defaultValue : "";
    this.currentValue = defaultValue;
    return defaultValue;
  };

  getId = () => {
    return this.props.id !== undefined ? this.props.id : null;
  };

  getLabel = () => {
    return this.props.label !== undefined ? this.props.label : "";
  };

  getRequired = () => {
    return this.props.required !== undefined ? this.props.required : true;
  };

  render() {
    const validationRules = [];

    if (this.getRequired()) {
      validationRules.push({
        type: "required",
        message: `${this.getLabel()} is required`
      });
    }

    return (
      <div className="form-group">
        <label htmlFor={this.getId()}>{this.getLabel()}</label>
        <SimpleMDE
          id={this.getId()}
          value={this.getDefaultValue()}
          onChange={this.fireChangeEvent}
          options={{
            spellChecker: true,
            toolbar: [
              "bold",
              "italic",
              "heading",
              "strikethrough",
              "horizontal-rule",
              "|",
              "quote",
              "unordered-list",
              "ordered-list",
              "|",
              "link",
              "image",
              "table",
              "|",
              "preview",
              "side-by-side",
              "fullscreen",
              "|",
              "guide"
            ]
          }}
        />
        {/* This hidden DevExtreme text box is basically used as a hack to validate the SimpleMDE editor */}
        <TextBox visible={false}>
          <Validator
            onValidated={this.onValidated}
            adapter={this.wikiEditorAdapter}
            validationRules={validationRules}
          />
        </TextBox>
      </div>
    );
  }
}

export default PromineoWikiEditor;
