import { updateObject } from "../../shared/Utility/commonUtility";
import * as actionTypes from "../actions/ActionType/communityActionTypes";
import * as uploadActionTypes from "../actions/ActionType/communityUploadActionTypes";

const initialState = {
  error: null,
  loading: false,
  communities: [],
  selectedCommunityForField: null,
  selectedCommunityForDataSource: null,
  selectedCommunityFields: [],
  selectedCommunityForDashboard: null,
  selectedFields: [],
  availableDataSources: [],
  selectedDataSources: [],
  availableDashboards: [],
  selectedDashboard: null,
  defaultCalendarForDataSourceId: null,
  cutOffTypeForDataSourceId: null,
  dashboardPeriodizerInfoModalDisplay: false,
  dataSourcePeriodizerInfoModalDisplay: false,
  tempDashboardDetail: null
};

// Communities
const loadCommunitiesBegin = state => {
  return updateObject(state, { error: null, loading: true });
};

const loadCommunitiesSucceeded = (state, action) => {
  return updateObject(state, {
    loading: false,
    communities: action.communities
  });
};

const loadCommunitiesFailed = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

// Community Selection
const fieldCommunitySelected = (state, action) => {
  return updateObject(state, {
    selectedCommunityForField: action.selectedCommunity
  });
};

const dataSourceCommunitySelected = (state, action) => {
  return updateObject(state, {
    selectedCommunityForDataSource: action.selectedCommunity
  });
};

const dashboardCommunitySelected = (state, action) => {
  return updateObject(state, {
    selectedCommunityForDashboard: action.selectedCommunity
  });
};

// Community Fields
const loadCommunityFieldsBegin = state => {
  return updateObject(state, { error: null, loading: true });
};

const loadCommunityFieldsSucceeded = (state, action) => {
  return updateObject(state, {
    loading: false,
    selectedCommunityFields: action.fields
  });
};

const loadCommunityFieldsFailed = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

// Field Selection
const fieldSelected = (state, action) => {
  return updateObject(state, {
    selectedFields: action.selectedFields
  });
};

// Field Download
const downloadCommunityFieldsBegin = state => {
  return updateObject(state, { error: null, loading: true });
};

const downloadCommunityFieldsSuccess = state => {
  return updateObject(state, { error: null, loading: false });
};

const downloadCommunityFieldsFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

// Community Data Sources
const loadCommunityDataSourcesBegin = state => {
  return updateObject(state, { error: null, loading: true });
};

const loadCommunityDataSourcesSucceeded = (state, action) => {
  return updateObject(state, {
    loading: false,
    availableDataSources: action.dataSources
  });
};

const loadCommunityDataSourcesFailed = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

// Data Source Selection
const dataSourceSelected = (state, action) => {
  return updateObject(state, {
    selectedDataSources: action.selectedDataSources
  });
};

// Data Source Download
const downloadCommunityDataSourcesBegin = state => {
  return updateObject(state, { error: null, loading: true });
};

const downloadCommunityDataSourcesSuccess = state => {
  return updateObject(state, { error: null, loading: false });
};

const downloadCommunityDataSourcesFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

// Calendar selection
const defaultCalendarSelectionChanges = (state, action) => {
  return updateObject(state, {
    defaultCalendarForDataSourceId: action.defaultCalendarId
  });
};

// Cut Off Type selection
const cutOffTypeSelectionChanges = (state, action) => {
  return updateObject(state, {
    cutOffTypeForDataSourceId: action.cutOffTypeId
  });
};

// Modal display config for datasource periodizer
const dataSourcePeriodizerInfoDisplayChanged = (state, action) => {
  return updateObject(state, {
    dataSourcePeriodizerInfoModalDisplay: action.display
  });
};

// Community Dasboards
const loadCommunityDashboardsBegin = state => {
  return updateObject(state, { error: null, loading: true });
};

const loadCommunityDashboardsSucceeded = (state, action) => {
  return updateObject(state, {
    loading: false,
    availableDashboards: action.dashboards
  });
};

const loadCommunityDashboardsFailed = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

// Dashboard Selection
const dashboardSelected = (state, action) => {
  return updateObject(state, {
    selectedDashboard: action.selectedDashboard
  });
};

// Clear community dependent data
const clearCommunityDependentData = state => {
  return updateObject(state, {
    selectedCommunityForField: null,
    selectedCommunityForDataSource: null,
    selectedCommunityFields: [],
    availableDataSources: [],
    selectedDataSources: [],
    defaultCalendarForDataSourceId: null,
    cutOffTypeForDataSourceId: null,
    selectedCommunityForDashboard: null,
    availableDashboards: [],
    selectedDashboard: null,
    tempDashboardDetail: null
  });
};

// Modal display config for dashboard periodizer
const dashboardPeriodizerInfoDisplayChanged = (state, action) => {
  return updateObject(state, {
    dashboardPeriodizerInfoModalDisplay: action.display
  });
};

// Store temporary dashboard detailed data
const storeTemporaryDashboardDetailed = (state, action) => {
  return updateObject(state, {
    tempDashboardDetail: action.dashboard
  });
};

// Data Source upload
const uploadDataSourceToCommunityStart = state => {
  return updateObject(state, { error: null, loading: true });
};

const uploadDataSourceToCommunitySuccess = state => {
  return updateObject(state, { error: null, loading: false });
};

const uploadDataSourceToCommunityFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

// Dashboard upload
const uploadDashboardToCommunityStart = state => {
  return updateObject(state, { error: null, loading: true });
};

const uploadDashboardToCommunitySuccess = state => {
  return updateObject(state, { error: null, loading: false });
};

const uploadDashboardToCommunityFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

// Community Fields Upload
const uploadFieldsToCommunityStart = state => {
  return updateObject(state, { error: null, loading: true });
};

const uploadFieldsToCommunitySuccess = state => {
  return updateObject(state, { error: null, loading: false });
};

const uploadFieldsToCommunityFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_COMMUNITY_START:
      return loadCommunitiesBegin(state);
    case actionTypes.FETCH_COMMUNITY_SUCCESS:
      return loadCommunitiesSucceeded(state, action);
    case actionTypes.FETCH_COMMUNITY_FAIL:
      return loadCommunitiesFailed(state, action);
    case actionTypes.FIELD_COMMUNITY_SELECTION_CHANGED:
      return fieldCommunitySelected(state, action);

    case actionTypes.FETCH_COMMUNITYFIELD_START:
      return loadCommunityFieldsBegin(state);
    case actionTypes.FETCH_COMMUNITYFIELD_SUCCESS:
      return loadCommunityFieldsSucceeded(state, action);
    case actionTypes.FETCH_COMMUNITYFIELD_FAIL:
      return loadCommunityFieldsFailed(state, action);
    case actionTypes.COMMUNITYFIELD_SELECTION_CHANGED:
      return fieldSelected(state, action);

    case actionTypes.DOWNLOAD_COMMUNITYFIELD_BEGIN:
      return downloadCommunityFieldsBegin(state, action);
    case actionTypes.DOWNLOAD_COMMUNITYFIELD_SUCCESS:
      return downloadCommunityFieldsSuccess(state, action);
    case actionTypes.DOWNLOAD_COMMUNITYFIELD_FAIL:
      return downloadCommunityFieldsFail(state, action);

    case actionTypes.FETCH_COMMUNITYDATASOURCE_START:
      return loadCommunityDataSourcesBegin(state, action);
    case actionTypes.FETCH_COMMUNITYDATASOURCE_SUCCESS:
      return loadCommunityDataSourcesSucceeded(state, action);
    case actionTypes.FETCH_COMMUNITYDATASOURCE_FAIL:
      return loadCommunityDataSourcesFailed(state, action);
    case actionTypes.DATASOURCE_COMMUNITY_SELECTION_CHANGED:
      return dataSourceCommunitySelected(state, action);
    case actionTypes.COMMUNITYDATASOURCE_SELECTION_CHANGED:
      return dataSourceSelected(state, action);
    case actionTypes.DOWNLOAD_COMMUNITYDATASOURCE_BEGIN:
      return downloadCommunityDataSourcesBegin(state, action);
    case actionTypes.DOWNLOAD_COMMUNITYDATASOURCE_SUCCESS:
      return downloadCommunityDataSourcesSuccess(state, action);
    case actionTypes.DOWNLOAD_COMMUNITYDATASOURCE_FAIL:
      return downloadCommunityDataSourcesFail(state, action);
    case actionTypes.DATASOURCE_COMMUNITY_PERIODIZERINFODISPLAY_CHANGED:
      return dataSourcePeriodizerInfoDisplayChanged(state, action);
    case actionTypes.DEFAULTCALENDAR_SELECTION_CHANGED:
      return defaultCalendarSelectionChanges(state, action);
    case actionTypes.CUTOFFTYPE_SELECTION_CHANGED:
      return cutOffTypeSelectionChanges(state, action);
    case actionTypes.CLEAR_COMMUNITY_DEPENDENT_DATA:
      return clearCommunityDependentData(state, action);

    case actionTypes.FETCH_COMMUNITYDASHBOARD_START:
      return loadCommunityDashboardsBegin(state, action);
    case actionTypes.FETCH_COMMUNITYDASHBOARD_SUCCESS:
      return loadCommunityDashboardsSucceeded(state, action);
    case actionTypes.FETCH_COMMUNITYDASHBOARD_FAIL:
      return loadCommunityDashboardsFailed(state, action);
    case actionTypes.DASHBOARD_COMMUNITY_SELECTION_CHANGED:
      return dashboardCommunitySelected(state, action);
    case actionTypes.COMMUNITYDASHBOARD_SELECTION_CHANGED:
      return dashboardSelected(state, action);
    case actionTypes.DASHBOARD_COMMUNITY_PERIODIZERINFODISPLAY_CHANGED:
      return dashboardPeriodizerInfoDisplayChanged(state, action);
    case actionTypes.DASHBOARD_COMMUNITY_DETAILED_TEMP:
      return storeTemporaryDashboardDetailed(state, action);
    case uploadActionTypes.UPLOAD_DATASOURCE_COMMUNITY_START:
      return uploadDataSourceToCommunityStart(state, action);
    case uploadActionTypes.UPLOAD_DATASOURCE_COMMUNITY_SUCCESS:
      return uploadDataSourceToCommunitySuccess(state, action);
    case uploadActionTypes.UPLOAD_DATASOURCE_COMMUNITY_FAIL:
      return uploadDataSourceToCommunityFail(state, action);

    case uploadActionTypes.UPLOAD_DASHBOARD_COMMUNITY_START:
      return uploadDashboardToCommunityStart(state, action);
    case uploadActionTypes.UPLOAD_DASHBOARD_COMMUNITY_SUCCESS:
      return uploadDashboardToCommunitySuccess(state, action);
    case uploadActionTypes.UPLOAD_DASHBOARD_COMMUNITY_FAIL:
      return uploadDashboardToCommunityFail(state, action);

    case uploadActionTypes.UPLOAD_FIELDS_COMMUNITY_START:
      return uploadFieldsToCommunityStart(state, action);
    case uploadActionTypes.UPLOAD_FIELDS_COMMUNITY_SUCCESS:
      return uploadFieldsToCommunitySuccess(state, action);
    case uploadActionTypes.UPLOAD_FIELDS_COMMUNITY_FAIL:
      return uploadFieldsToCommunityFail(state, action);
    default:
      return state;
  }
};

export default reducer;
