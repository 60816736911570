import React from "react";
import DataGrid, {
  Selection,
  FilterRow,
  Paging,
  SearchPanel
} from "devextreme-react/data-grid";

class PromineoGrid extends React.Component {
  constructor(props) {
    super(props);
    this.onGridInitialized = this.onGridInitialized.bind(this);
  }
  componentDidMount() {
    if (this.props.onRef !== undefined) {
      this.props.onRef(this);
    }
  }

  componentWillUnmount() {
    if (this.props.onRef !== undefined) {
      this.props.onRef(null);
    }
  }

  onGridInitialized = e => {
    this.dataGrid = e.component;
  };

  getSelectedRowKeys = () => {
    if (this.dataGrid !== undefined) {
      const selectedRowKeys = this.dataGrid.getSelectedRowKeys();
      return selectedRowKeys;
    }

    return [];
  };

  getSelectedRows = () => {
    if (this.dataGrid !== undefined) {
      const selectedRowData = this.dataGrid.getSelectedRowsData();
      return selectedRowData;
    }

    return [];
  };

  clearSelection = () => {
    if (this.dataGrid !== undefined) {
      this.dataGrid.clearSelection();
    }
  }

  getDataSource = () => {
    return this.props.dataSource !== undefined
      ? this.props.dataSource
      : this.employees;
  };

  getKeyExpression = () => {
    return this.props.keyExpression !== undefined
      ? this.props.keyExpression
      : "PrimKey";
  };

  getSelectionMode = () => {
    return this.props.allowMultiSelect !== undefined
      ? this.props.allowMultiSelect
        ? "multiple"
        : "single"
      : "single";
  };

  getDisplayFilterRow = () => {
    return this.props.displayFilterRow !== undefined
      ? this.props.displayFilterRow
      : false;
  };

  getDisplaySearchPanel = () => {
    return this.props.displaySearchPanel !== undefined 
      ? this.props.displaySearchPanel : false;
  }

  getDefaultPageSize = () => {
    return this.props.defaultPageSize !== undefined
      ? this.props.defaultPageSize
      : 20;
  };

  getIsPagingEnabled = () => {
    return this.props.enablePaging !== undefined
      ? this.props.enablePaging
      : false;
  };

  handleSelectionChanged = selectedRowsData => {
    if (this.props.onSelectionChanged !== undefined) {
      this.props.onSelectionChanged(selectedRowsData);
    }
  };

  handleOnRowInsert = event => {
    if(this.props.onRowInserting){
      this.props.onRowInserting(event);
    }
  }

  handleOnInitNewRow = event => {
    if(this.props.onInitNewRow){
      this.props.onInitNewRow(event);
    }
  }

  handleOnRowUpdate = event => {
    if(this.props.onRowUpdated){
      this.props.onRowUpdated(event);
    }
  }

  handleOnRowRemoving = event => {
    if(this.props.onRowRemoving){
      this.props.onRowRemoving(event);
    }
  }

  render() {
    return (
      <DataGrid
        dataSource={this.getDataSource()}
        showBorders={true}
        rowAlternationEnabled={true}
        keyExpr={this.getKeyExpression()}
        onInitialized={this.onGridInitialized}
        onSelectionChanged={this.handleSelectionChanged}
        onRowInserting={this.handleOnRowInsert}
        onInitNewRow ={this.handleOnInitNewRow}
        onRowUpdated={this.handleOnRowUpdate}
        onRowRemoving= {this.handleOnRowRemoving}
        toolbar={this.props.toolbar}
      >
        <Selection
          mode={this.getSelectionMode()}
          selectAllMode={"allPages"}
          showCheckBoxesMode={"always"}
        />
        <FilterRow visible={this.getDisplayFilterRow()} />
        <SearchPanel visible={this.getDisplaySearchPanel()}
            width={240}
            placeholder="Search..." />
        <Paging
          enabled={this.getIsPagingEnabled()}
          defaultPageSize={this.getDefaultPageSize()}
        />

        {this.props.children}
      </DataGrid>
    );
  }
}

export default PromineoGrid;
