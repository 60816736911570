import React from "react";
import './../../styles/dashboard/filterItemStyle.css'

const FilterItem = ({data}) => {
  return (

    <div className="container filterContainer">
      <div className="row">
        <div className="col-sm">
          <p className="filterName">{data.Name}</p>
          <p>{data.Description}</p>
        </div>
      </div>
    </div>
  );
};

export default FilterItem;
