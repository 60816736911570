import * as actionTypes from "./ActionType/communityActionTypes";
import * as request from "../../shared/request";
import {
  toastError,
  toastException,
  toastSuccess
} from "../../shared/Utility/toastUtility";
import { isSuccessApiResponse } from "../../shared/Utility/dataUtility";
import {
  getApiCompatibleUserFieldData,
  getApiCompatibleStructureListForUpsert
} from "../../shared/Utility/userFieldsUtility";
import {
  loadTenantStructures,
  getExistingUserFields
} from "./userFieldActions";
import { saveData } from "./commonApiActions";

// Load Community Fields
export const loadCommunityFields = selectedCommunity => {
  return (dispatch, getState) => {
    const state = getState();
    if (selectedCommunity && selectedCommunity.id) {
      dispatch(loadCommunityFieldsBegin());

      const communityFieldLoadRequest = request.portalRequest(
        {
          url: `/Community/${selectedCommunity.id}/Fields`,
          method: "GET"
        },
        state.authData
      );

      if (communityFieldLoadRequest) {
        communityFieldLoadRequest
          .then(response => {
            if (isSuccessApiResponse(response)) {
              dispatch(getDownloadableCommunityFields(response.data));
            }
          })
          .catch(error => {
            dispatch(loadCommunityFieldsFail(error));
          });
      } else {
        loadCommunityFieldsFail("Invalid community selection.");
      }
    }
  };
};

export const loadCommunityFieldsBegin = () => {
  return {
    type: actionTypes.FETCH_COMMUNITYFIELD_START
  };
};

export const loadCommunityFieldsSuccess = fields => {
  return {
    type: actionTypes.FETCH_COMMUNITYFIELD_SUCCESS,
    fields
  };
};

export const loadCommunityFieldsFail = error => {
  toastError("Failed to load community fields.");

  return {
    type: actionTypes.FETCH_COMMUNITYFIELD_FAIL,
    error
  };
};

const getDownloadableCommunityFields = availableCommunityFields => {
  return dispatch => {
    // Get current user fields fields from API
    // Filter out existing user fields from available community fields
    let existingUserFieldKeys = [];
    dispatch(getExistingUserFields(false))
      .then(userFieldResponse => {
        if (
          userFieldResponse &&
          userFieldResponse.fields &&
          userFieldResponse.fields.length > 0
        )
          existingUserFieldKeys = userFieldResponse.fields.map(uf => {
            return uf.PrimKey;
          });

        const downloadableFields = availableCommunityFields.filter(
          communityField => {
            return existingUserFieldKeys.indexOf(communityField.id) < 0;
          }
        );

        dispatch(loadCommunityFieldsSuccess(downloadableFields));
      })
      .catch(error => {
        console.log(error);
        dispatch(loadCommunityFieldsFail(error));
      });
  };
};

// Field Selection Changed
export const fieldSelectionChanged = selectedFields => {
  return {
    type: actionTypes.COMMUNITYFIELD_SELECTION_CHANGED,
    selectedFields: selectedFields
  };
};

// Download community fields
export const downloadCommunityFields = () => {
  return (dispatch, getState) => {
    dispatch(downloadCommunityFieldsBegin());

    const state = getState();
    const selectedCommunityFields = state.communityData.selectedFields;
    if (!selectedCommunityFields || selectedCommunityFields.length === 0) {
      dispatch(
        downloadCommunityFieldsFail(
          "Please select community fields to download"
        )
      );
    } else {
      const userFieldData = selectedCommunityFields.map(communityField => {
        return getApiCompatibleUserFieldData(communityField);
      });

      // Get related structure ids from the downloadable fields
      const requiredStructures = selectedCommunityFields.reduce(
        (result, field) => {
          if (field.structure) {
            result.push(field.structure);
          }
          return result;
        },
        []
      );

      if (requiredStructures && requiredStructures.length > 0) {
        const liveRevisionRef = state.projectData.currentRevision.PrimKey;
        // Get list of existing tenant structures
        dispatch(loadTenantStructures())
          .then(existingStructures => {
            const strctureAPIData = getApiCompatibleStructureListForUpsert(
              existingStructures,
              requiredStructures,
              liveRevisionRef
            );

            dispatch(saveData(strctureAPIData))
              .then(response => {
                if (isSuccessApiResponse(response)) {
                  dispatch(saveData(userFieldData))
                    .then(response => {
                      if (isSuccessApiResponse(response)) {
                        dispatch(downloadCommunityFieldsSuccess());
                        dispatch(
                          loadCommunityFields(
                            state.communityData.selectedCommunityForField
                          )
                        );
                      } else {
                        dispatch(
                          downloadCommunityFieldsFail(
                            "Failed to save community field."
                          )
                        );
                      }
                    })
                    .catch(error => {
                      console.log(error);
                      dispatch(downloadCommunityFieldsFail(error));
                    });
                } else {
                  dispatch(
                    downloadCommunityFieldsFail(
                      "Failed to save community field structure."
                    )
                  );
                }
              })
              .catch(error => {
                console.log(error);
                dispatch(downloadCommunityFieldsFail(error));
              });
          })
          .catch(error => {
            toastError(
              "Error occured while downloading community fields. Error - " +
                error
            );
          });
      }
    }
  };
};

export const downloadCommunityFieldsBegin = () => {
  return {
    type: actionTypes.DOWNLOAD_COMMUNITYFIELD_BEGIN
  };
};

export const downloadCommunityFieldsSuccess = () => {
  toastSuccess("Successfully downloaded community fields");
  return {
    type: actionTypes.DOWNLOAD_COMMUNITYFIELD_SUCCESS
  };
};

export const downloadCommunityFieldsFail = error => {
  toastException("Failed to download community fields. ", error);
  return {
    type: actionTypes.DOWNLOAD_COMMUNITYFIELD_FAIL,
    error
  };
};

// Community Selection
export const communitySelectionChanged = data => {
  return {
    type: actionTypes.FIELD_COMMUNITY_SELECTION_CHANGED,
    selectedCommunity: data.addedItems[0]
  };
};
