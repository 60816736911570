import React from "react";
import DashboadList from "./../DashboardListViewComponent";
import WithDashboardStepNavigation from "./../../hoc/WithDashboardStepNavigation";

const DashboardListStep = (props) => {
  const handleStepChange = () => {
    props.nextStep();
  };

  return <DashboadList onStepChange={handleStepChange}></DashboadList>;
};

export default WithDashboardStepNavigation(DashboardListStep);
