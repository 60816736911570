import { updateObject } from "../../shared/Utility/commonUtility";
import * as actionTypes from "../actions/ActionType/projectActionTypes";

const initialState = {
  loading: false,
  error: null,
  calendars: [],
  cutOffTypes: [],
  currentRevision: null,
  userFields: [],
  userFieldStructures: []
};

const loadCalendarsBegin = state => {
  return updateObject(state, { error: null, loading: true });
};

const loadCalendarsSuccess = (state, action) => {
  return updateObject(state, { loading: false, calendars: action.calendars });
};

const loadCalendarsFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

const loadCutOffTypesBegin = state => {
  return updateObject(state, { error: null, loading: true });
};

const loadCutOffTypesSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    cutOffTypes: action.cutOffTypes
  });
};

const loadCutOffTypesFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

// Load current revision
const loadCurrentRevisionBegin = state => {
  return updateObject(state, { error: null, loading: true });
};

const loadCurrentRevisionSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    currentRevision: action.currentRevision
  });
};

// Load User Fields
const loadUserFieldsBegin = state => {
  return updateObject(state, { error: null, loading: true });
};

const loadUserFieldsFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

const loadUserFieldsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    userFields: action.userFieldData.fields,
    userFieldStructures: action.userFieldData.structures
  });
};

const loadCurrentRevisionFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CALENDAR_BEGIN:
      return loadCalendarsBegin(state);
    case actionTypes.FETCH_CALENDAR_SUCCESS:
      return loadCalendarsSuccess(state, action);
    case actionTypes.FETCH_CALENDAR_FAIL:
      return loadCalendarsFail(state, action);
    case actionTypes.FETCH_CUTOFFTYPE_BEGIN:
      return loadCutOffTypesBegin(state, action);
    case actionTypes.FETCH_CUTOFFTYPE_SUCCESS:
      return loadCutOffTypesSuccess(state, action);
    case actionTypes.FETCH_CUTOFFTYPE_FAIL:
      return loadCutOffTypesFail(state, action);
    case actionTypes.FETCH_CURRENTREVISION_BEGIN:
      return loadCurrentRevisionBegin(state, action);
    case actionTypes.FETCH_CURRENTREVISION_SUCCESS:
      return loadCurrentRevisionSuccess(state, action);
    case actionTypes.FETCH_CURRENTREVISION_FAIL:
      return loadCurrentRevisionFail(state, action);
    case actionTypes.FETCH_USERFIELDS_BEGIN:
      return loadUserFieldsBegin(state, action);
    case actionTypes.FETCH_USERFIELDS_SUCCESS:
      return loadUserFieldsSuccess(state, action);
    case actionTypes.FETCH_USERFIELDS_FAIL:
      return loadUserFieldsFail(state, action);
    default:
      return state;
  }
};

export default projectReducer;
