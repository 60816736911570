import axios from "axios";
import * as actionTypes from "./ActionType/authActionTypes";
import { toastError } from "../../shared/Utility/toastUtility";
import * as request from "../../shared/request";
import * as criteriaBuilder from "../../shared/criteria/criteriaBuilder";
import AuthService from "../../services/auth.service";
import "../../config";
import config from "react-global-configuration";
import { loadCurrentRevision } from "./projectActions";
import { logActivity } from "./activityLogActions";
import { activityTypes } from "../../shared/Enums/activityTypes";
import { isSuccessApiResponse } from "../../shared/Utility/dataUtility";
import {
  checkIfAuthenticateWithOidc,
  getSavedAuthenticationProviderId,
} from "../../shared/Utility/authUtility";
import { authenticationProviderSupportedType } from "../../shared/Enums/authenticationProviderSupportedTypeEnum";

const PORTAL_READ_TOKEN_URL = config.get("portalReadTokenUrl");

export const silentAuthenticateStart = () => {
  return {
    type: actionTypes.SILENT_AUTHENTICATION_START,
  };
};

export const silentAuthenticationFinish = () => {
  return {
    type: actionTypes.SILENT_AUTHENTICATION_FINISH,
  };
};

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    // Auth loading is true so loading panel will be displayed
    dispatch(authStart());
    const authService = new AuthService();
    authService.getToken().then(
      (idToken) => {
        if (idToken) {
          const tenantId = localStorage.getItem("tenantId");
          dispatch(authenticateToPromineoPortal(idToken, tenantId));
        } else {
          // Authentication failed so login button will be rendered
          // Error is null so no toast will be displayed
          dispatch(authFail(null));
        }
      },
      (error) => {
        // Authentication failed so login button will be rendered
        // Error is null so no toast will be displayed
        dispatch(authFail(null));
      }
    );
  };
};

export const logoutAfterPromineoTokenGetsExpired = () => {
  // Add automatic logout when token expires.
  // Maybe we should do someting about this, warn the user to re- enter credentials so the session data is not lost.
  return (dispatch, getState) => {
    const state = getState();
    if (state.authData.expires) {
      const expiresOn = new Date(state.authData.expires);

      dispatch(
        checkAuthTimeout((expiresOn.getTime() - new Date().getTime()) / 1000)
      );
    }
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000);
  };
};

export const authSuccess = (authData) => {
  localStorage.setItem("tenantId", authData.tenantId);

  return {
    type: actionTypes.AUTH_SUCCESS,
    authData: authData,
  };
};

export const authFail = (error) => {
  if (error) {
    toastError("Authentication failed! ");
  }

  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const loginTenantStart = () => {
  return {
    type: actionTypes.LOGIN_TENANT_START,
  };
};

export const loginTenantSuccess = (authData) => {
  localStorage.setItem("tenantId", authData.tenantId);

  return {
    type: actionTypes.LOGIN_TENANT_SUCCESS,
    authData: authData,
  };
};

export const loginToTenant = (
  tenantId,
  tenantName,
  tenantApiUrl,
  tenantWebReportingUrl
) => {
  return (dispatch, getState) => {
    const state = getState();
    const azureAdToken = state.authData.idToken;

    if (azureAdToken) {
      dispatch(loginTenantStart());

      const bodyParameters = {
        azureAdToken: azureAdToken,
        clientName: "Promineo",
        clientVersion: "1.1.0.0",
        tenantId: tenantId,
      };

      const usingOIdcAuthentication = checkIfAuthenticateWithOidc();
      if (usingOIdcAuthentication) {
        bodyParameters.authenticationProviderId =
          getSavedAuthenticationProviderId();
      }

      axios
        .post(PORTAL_READ_TOKEN_URL, bodyParameters)
        .then((response) => {
          dispatch(
            loginTenantSuccess({
              tenantId: response.data.TenantId,
              tenantName: tenantName,
              tenantApiUrl: tenantApiUrl,
              tenantWebReportingUrl: tenantWebReportingUrl,
              promineoToken: response.data.Token,
              expires: response.data.Expires,
              userId: response.data.User,
            })
          );

          dispatch(logActivity(activityTypes.UserLogin, ""));
          dispatch(loadInformationAfterLogin());
          dispatch(logoutAfterPromineoTokenGetsExpired());
        })
        .catch((error) => {
          dispatch(loginTenantFail(error));
        });
    }
  };
};

export const loadApplicationRightsStart = () => {
  return {
    type: actionTypes.LOAD_APPLICATION_RIGHTS_START,
  };
};

export const loadApplicationRightsSuccess = (appRights) => {
  return {
    type: actionTypes.LOAD_APPLICATION_RIGHTS_SUCCESS,
    appRights,
  };
};

export const loadApplicationRightsFail = (error) => {
  toastError("Loading application rights failed! ");

  return {
    type: actionTypes.LOAD_APPLICATION_RIGHTS_FAIL,
    error: error,
  };
};

export const loadDataAccessStart = () => {
  return {
    type: actionTypes.LOAD_DATA_ACCESS_START,
  };
};

export const loadDataAccessSuccess = (dataAccess) => {
  return {
    type: actionTypes.LOAD_DATA_ACCESS_SUCCESS,
    dataAccess,
  };
};

export const loadDataAccessFail = (error) => {
  toastError("Loading user's data access failed! ");

  return {
    type: actionTypes.LOAD_DATA_ACCESS_FAIL,
    error: error,
  };
};

export const loadMyselfStart = () => {
  return {
    type: actionTypes.LOAD_MYSELF_START,
  };
};

export const loadMyselfSuccess = (myself) => {
  return {
    type: actionTypes.LOAD_MYSELF_SUCCESS,
    myself,
  };
};

export const loadMyselfFail = (error) => {
  toastError("Loading myself info failed! ");

  return {
    type: actionTypes.LOAD_MYSELF_FAIL,
    error: error,
  };
};

export const loadInformationAfterLogin = () => {
  return (dispatch, getState) => {
    const state = getState();

    dispatch(loadApplicationRightsStart());

    const getUserGroups = request.apiRequest(
      {
        url: "/select/vc_Security_MyGroups",
        method: "POST",
      },
      state.authData
    );

    if (getUserGroups) {
      getUserGroups
        .then((response) => {
          let userGroups = response.data[0].$values;
          let groupIds = userGroups.map((g) => {
            return g.PrimKey;
          });

          dispatch(loadApplicationRight(groupIds));
          dispatch(loadDataAccess(groupIds));
        })
        .catch((error) => {
          toastError("Error occurred");
          dispatch(loadApplicationRightsFail(error));
        });
    }

    dispatch(loadMyself());
    dispatch(loadCurrentRevision());
  };
};

export const loadMyself = () => {
  return (dispatch, getState) => {
    const state = getState();

    dispatch(loadMyselfStart());

    const getMyself = request.apiRequest(
      {
        url: "/select/vc_Security_MySelf",
        method: "POST",
      },
      state.authData
    );

    if (getMyself) {
      getMyself
        .then((response) => {
          let myself = response.data[0].$values[0];
          dispatch(loadMyselfSuccess(myself));
        })
        .catch((error) => {
          toastError("Error occurred");
          dispatch(loadMyselfFail(error));
        });
    }
  };
};

export const loadApplicationRight = (groupIds) => {
  return (dispatch, getState) => {
    const state = getState();

    let inCriteria = criteriaBuilder.getInCriteria(
      "System.Guid",
      "GroupRef",
      groupIds
    );

    const getApplicationRights = request.apiRequest(
      {
        url: "/select/v_Security_ApplicationRights",
        method: "POST",
        data: inCriteria,
      },
      state.authData
    );

    if (getApplicationRights) {
      getApplicationRights
        .then((response) => {
          let allAppRights = response.data[0].$values;

          let appRights = 0;

          allAppRights.forEach((v) => {
            appRights = appRights | v.Rights;
          });

          dispatch(loadApplicationRightsSuccess(appRights));
        })
        .catch((error) => {
          toastError("Error occurred");
          dispatch(loadApplicationRightsFail(error));
        });
    }
  };
};

export const loadDataAccess = (groupIds) => {
  return (dispatch, getState) => {
    const state = getState();

    let inCriteria = criteriaBuilder.getInCriteria(
      "System.Guid",
      "GroupRef",
      groupIds
    );

    const getDataAccess = request.apiRequest(
      {
        url: "/select/t_Security_DataAccess",
        method: "POST",
        data: inCriteria,
      },
      state.authData
    );

    dispatch(loadDataAccessStart());

    if (getDataAccess) {
      getDataAccess
        .then((response) => {
          const dataAccess = response.data[0].$values;
          dispatch(loadDataAccessSuccess(dataAccess));
        })
        .catch((error) => {
          toastError("Error occurred");
          dispatch(loadDataAccessFail(error));
        });
    }
  };
};

export const loginTenantFail = (error) => {
  toastError("Tenant login failed!");

  return {
    type: actionTypes.LOGIN_TENANT_FAIL,
    error: error,
  };
};

export const loadTenants = () => {
  return (dispatch, getState) => {
    const state = getState();
    const azureAdToken = state.authData.idToken;

    dispatch(loadTenantsStart());

    const bodyParameters = {
      azureAdToken: azureAdToken,
      clientName: "Promineo",
      clientVersion: "1.1.0.0",
    };

    const usingOIdcAuthentication = checkIfAuthenticateWithOidc();
    if (usingOIdcAuthentication) {
      bodyParameters.authenticationProviderId =
        getSavedAuthenticationProviderId();
    }

    axios
      .post(PORTAL_READ_TOKEN_URL, bodyParameters)
      .then((response) => {
        dispatch(loadTenantsSuccess(response.data.Tenants));
      })
      .catch((error) => {
        dispatch(loadTenantsFailed(error));
      });
  };
};

export const loadTenantsStart = () => {
  return {
    type: actionTypes.LOAD_TENANT_START,
  };
};

export const loadTenantsSuccess = (tenants) => {
  return {
    type: actionTypes.LOAD_TENANT_SUCCESS,
    tenants: tenants,
  };
};

export const loadTenantsFailed = (error) => {
  toastError("Failed to load tenants");

  return {
    type: actionTypes.LOAD_TENANT_FAIL,
    error: error,
  };
};

export const setAuthRedirectPath = (path) => {
  return {
    type: actionTypes.AUTH_SET_REDIRECT_PATH,
    path: path,
  };
};

export const authenticateToPromineoPortal = (idToken, tenantId) => {
  return (dispatch) => {
    dispatch(authStart());

    let bodyParameters = {
      azureAdToken: idToken,
      clientName: "Promineo",
      clientVersion: "1.1.0.0",
    };

    const usingOIdcAuthentication = checkIfAuthenticateWithOidc();
    if (usingOIdcAuthentication) {
      bodyParameters.authenticationProviderId =
        getSavedAuthenticationProviderId();
    }

    if (tenantId && tenantId !== "null") {
      bodyParameters.tenantId = tenantId;
    }
    axios
      .post(PORTAL_READ_TOKEN_URL, bodyParameters)
      .then((response) => {
        if (
          tenantId &&
          response.data.Tenants &&
          response.data.Tenants.length === 1
        ) {
          const tenant = response.data.Tenants[0];
          dispatch(
            authSuccess({
              idToken: idToken,
              promineoToken: response.data.Token,
              expires: response.data.Expires,
              isPortalAdmin: response.data.IsPortalAdmin,
              tenantId: tenant.TenantId,
              selectedTenant: tenant,
              userId: response.data.User,
            })
          );

          dispatch(loadInformationAfterLogin());
        } else {
          dispatch(
            authSuccess({
              idToken: idToken,
              promineoToken: response.data.Token,
              expires: response.data.Expires,
              isPortalAdmin: response.data.IsPortalAdmin,
              tenantId: null,
              selectedTenant: null,
              userId: null, // The portal does not provide user if multiple tenant is returned
            })
          );
        }

        dispatch(logoutAfterPromineoTokenGetsExpired());
      })
      .catch((error) => {
        dispatch(authFail(error));
      });
  };
};

export const logout = () => {
  // The tenant id is not removed intentionally, so that for further login we can directly login with the last selected tenant.
  const usingOIdcAuthentication = checkIfAuthenticateWithOidc();
  if (!usingOIdcAuthentication) {
    const authService = new AuthService();
    authService.logout();
  }

  return {
    type: actionTypes.AUTH_LOGOUT,
    forOidc: !!usingOIdcAuthentication,
  };
};

export const logOutFinish = () => {
  return {
    type: actionTypes.AUTH_LOGOUT_FINISH,
  };
};

const loadAuthenticationProviderBegin = () => {
  return {
    type: actionTypes.LOAD_AUTHENTICATION_PROVIDERS_START,
  };
};

const loadAuthenticationProviderSuccess = (providers) => {
  return {
    type: actionTypes.LOAD_AUTHENTICATION_PROVIDERS_SUCCESS,
    providers,
  };
};

const loadAuthenticationProviderFail = () => {
  return {
    type: actionTypes.LOAD_AUTHENTICATION_PROVIDERS_FAIL,
  };
};

export const loadAuthenticationProviders = () => {
  return (dispatch, getState) => {
    dispatch(loadAuthenticationProviderBegin());

    const authenticationProviderRequest = request.portalPublicRequest({
      url: `/Authentication/Providers?type=${authenticationProviderSupportedType.Oidc}`,
      method: "GET",
    });

    if (authenticationProviderRequest) {
      return authenticationProviderRequest
        .then((response) => {
          if (isSuccessApiResponse(response)) {
            dispatch(loadAuthenticationProviderSuccess(response.data));
          } else {
            dispatch(loadAuthenticationProviderFail());
          }
        })
        .catch((error) => {
          dispatch(loadAuthenticationProviderFail());
        });
    }
  };
};
