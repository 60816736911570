import React from "react";
import { connect } from "react-redux";
import CommunityList from "./CommunityListComponent";
import CommunityDataSourceGrid from "./CommunityDataSourceGridComponent";
import PromineoSelectBox from "./../Common/Controls/PromineoSelectBoxComponent";
import { Button } from "devextreme-react/button";
import * as actions from "../../store/actions/communityDataSourceActions";
import * as projectActions from "../../store/actions/projectActions";

class CommunityDataSourceSettings extends React.Component {
  componentDidMount() {
    this.props.loadCalendarsAndCutOffTypes();
  }

  onDownloadClicked = () => {
    this.props.downloadCommunityDataSources();
  };

  handleCalendarChanged = (controlName, value) => {
    this.props.updateCalendarSelection(value);
  };

  handleCutOffTypeChanged = (controlName, value) => {
    this.props.updateCutOffTypeSelection(value);
  };

  handleCommunitySelection = community => {
    this.props.onCommunitySelectionChanged(community);
  };

  render() {
    const { selectedCommunity, calendars, cutOffTypes } = this.props;
    return (
      <div className="container pt-3">
        <div className="row">
          <div className="col-sm-3">
            <PromineoSelectBox
              dataSource={calendars}
              id="calendars"
              label="Default Calendar"
              displayExpr="Title"
              valueExpr="PrimKey"
              onValueChanged={this.handleCalendarChanged}
            />
          </div>
          <div className="col-sm-3">
            <PromineoSelectBox
              dataSource={cutOffTypes}
              id="leaveType"
              label="Cut Off Type"
              displayExpr="Name"
              valueExpr="PrimKey"
              onValueChanged={this.handleCutOffTypeChanged}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3">
            <CommunityList onSelectionChanged={this.handleCommunitySelection} />
          </div>
          <div className="col-sm-9">
            <CommunityDataSourceGrid community={selectedCommunity} />
          </div>
        </div>
        <div className="row pt-3 pb-3">
          <div className="col text-center">
            <Button
              className="text-center"
              text="Download Selected Datasource(s)"
              type="default"
              onClick={this.onDownloadClicked}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedCommunity: state.communityData.selectedCommunityForDataSource,
    calendars: state.projectData.calendars,
    cutOffTypes: state.projectData.cutOffTypes
  };
};

const mapDispatchToProps = dispatch => {
  return {
    downloadCommunityDataSources: () =>
      dispatch(actions.downloadOnlySelectedCommunityDataSources()),
    loadCalendarsAndCutOffTypes: () => {
      dispatch(projectActions.loadCutOffTypes());
      dispatch(projectActions.loadCalendars());
    },
    updateCalendarSelection: selectedCalendarId => {
      dispatch(actions.updateCalendarSelection(selectedCalendarId));
    },
    updateCutOffTypeSelection: selectedCutOffTypeId => {
      dispatch(actions.updateCutOffTypeSelection(selectedCutOffTypeId));
    },
    onCommunitySelectionChanged: data => {
      dispatch(actions.communitySelectionChanged(data));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunityDataSourceSettings);
