import React from "react";
import StepWizardNavigation from "../Common/Controls/StepWizard/StepWizardNavigationComponent";

const WithDashboardStepNavigation = (WrappedComponent) => {
  class HOC extends React.Component {
    render() {
      return (
        <>
          <StepWizardNavigation
            displayGoBackToFirstStep
            displayContinueButton={false}
            displayFinish={false}
            firstStepCaption="Dashboard Selection"
            {...this.props}
          ></StepWizardNavigation>
          <WrappedComponent {...this.props} />
        </>
      );
    }
  }

  return HOC;
};

export default WithDashboardStepNavigation;
