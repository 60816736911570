import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import * as actions from "./../../store/actions/authActions";

class Logout extends Component {
  componentDidMount() {
    this.props.onLogout();
  }

  render() {
    const { token, promineoToken } = this.props;
    if (!token && !promineoToken) {
      return <Redirect to={"/"} />;
    }
    return (
      <div>
        <p>Logging out..</p>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.authData.idToken,
    promineoToken: state.authData.promineoToken
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLogout: () => dispatch(actions.logout())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Logout);
