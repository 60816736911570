import React, { useState, useEffect, useRef } from "react";
import { UserManager, WebStorageStateStore } from "oidc-client";
export const AuthContext = React.createContext();

const hasCodeInUrl = (location) => {
  const searchParams = new URLSearchParams(location.search);
  const hashParams = new URLSearchParams(location.hash.replace("#", "?"));

  return Boolean(
    searchParams.get("code") ||
      searchParams.get("id_token") ||
      searchParams.get("session_state") ||
      hashParams.get("code") ||
      hashParams.get("id_token") ||
      hashParams.get("session_state")
  );
};

export const initUserManager = (props) => {
  if (props.userManager) return props.userManager;
  const {
    authority,
    clientId,
    clientSecret,
    redirectUri,
    silentRedirectUri,
    postLogoutRedirectUri,
    responseType,
    scope,
    automaticSilentRenew,
    loadUserInfo,
    popupWindowFeatures,
    popupRedirectUri,
    popupWindowTarget,
  } = props;
  let userManager = new UserManager({
    authority,
    client_id: clientId,
    client_secret: clientSecret,
    redirect_uri: redirectUri,
    silent_redirect_uri: silentRedirectUri || redirectUri,
    post_logout_redirect_uri: postLogoutRedirectUri || redirectUri,
    response_type: responseType || "code",
    scope: scope || "openid",
    loadUserInfo: loadUserInfo !== undefined ? loadUserInfo : true,
    popupWindowFeatures: popupWindowFeatures,
    popup_redirect_uri: popupRedirectUri,
    popupWindowTarget: popupWindowTarget,
    automaticSilentRenew,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    stateStore: new WebStorageStateStore({ store: window.localStorage }),
  });

  const checkIfProviderIsAzureAd = () => {
    return authority && authority.includes("login.microsoftonline.com");
  };

  if (checkIfProviderIsAzureAd()) {
    const authorityWithoutTenantId = authority.substring(
      0,
      authority.lastIndexOf("/")
    );
    const version = authority.substring(authority.lastIndexOf("/") + 1);

    userManager.metadata = {
      token_endpoint: `${authorityWithoutTenantId}/oauth2/${version}/token`,
      issuer: `${authorityWithoutTenantId}/${version}`,
      jwks_uri: `${authorityWithoutTenantId}/discovery/keys`,
      end_session_endpoint: `${authorityWithoutTenantId}/oauth2/logout`,
      authorization_endpoint: `${authorityWithoutTenantId}/oauth2/authorize`,
    };
  }

  return userManager;
};

export const AuthProvider = (props) => {
  const {
    children,
    onSignIn,
    onSignOut,
    onSilentSignIn,
    silentSignInErrorCallback,
    location = window.location,
  } = props;
  const [userManager] = useState(() => initUserManager(props));

  const signInPopupHooks = async () => {
    const userFromPopup = await userManager.signinPopup();
    onSignIn && onSignIn(userFromPopup);
    await userManager.signinPopupCallback();
  };

  const isMountedRef = useRef(true);
  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    const getUser = async () => {
      if (hasCodeInUrl(location)) {
        const user = await userManager.signinCallback();
        onSignIn && onSignIn(user);
      }
    };
    getUser();
  }, [location, userManager, onSignIn]);

  const clearUserFromStorage = async () => {
    const keys = Object.keys(window.localStorage);
    const userInfoKey = keys.find((k, v) => k.includes("oidc.user:"));
    if (userInfoKey) {
      window.localStorage.removeItem(userInfoKey);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        signInPopup: async () => {
          await signInPopupHooks();
        },
        signOut: async () => {
          clearUserFromStorage();
          await userManager.signoutRedirect();
          onSignOut && onSignOut();
        },
        silentSignIn: async () => {
          try {
            const response = await userManager.signinSilent();
            onSilentSignIn(response);
          } catch {
            silentSignInErrorCallback && silentSignInErrorCallback();
          }
        },
        userManager,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
