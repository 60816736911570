export const create = (leftOperand, rightOperand, operator) => {
    let criteria = {
      __type: "PData.Sql.",
      data: {
        c: [
            leftOperand,
            rightOperand
        ],
        o: operator
      }
    };
  
    switch (operator) {
      case "AND":
        criteria.__type = criteria.__type + "AndCriteria";
        break;
      case "OR":
        criteria.__type = criteria.__type + "OrCriteria";
        break;
      default:
        break;
    }
  
    return criteria;
  };