import { updateObject } from "../../shared/Utility/commonUtility";
import * as actionTypes from "../actions/ActionType/serviceAccountActionTypes";

const initialState = {
    loading: false,
    error: null,
    serviceAccounts: [],
    generatedSasToken: ''
};

const loadServiceAccountBegin = (state,action) => {
    return updateObject(state, { error: null, loading: true });
}

const loadServiceAccountSuccess = (state,action) => {
    return updateObject(state, { loading: false, serviceAccounts: action.serviceAccounts });
}

const loadServiceAccountFail = (state,action) => {
    return updateObject(state, { error: action.error, loading: false });
}

const addEditServiceAccountBegin = (state,action) => {
    return updateObject(state, { error: null, loading: true });
}

const addEditServiceAccountSuccess = (state, action) => {
    if(action.serviceAccountData && action.serviceAccountData.isNew){
        return updateObject(state, { error: null, loading: false, serviceAccounts: [...state.serviceAccounts, action.serviceAccountData.serviceAccount]});
    }
    return updateObject(state, { error: null, loading: false});
}

const addEditServiceAccountFail = (state,action) => {
    return updateObject(state, { error: action.error, loading: false });
}

const deleteServiceAccountBegin = (state,action) => {
    return updateObject(state, { error: null, loading: true });
}

const deleteServiceAccountSuccess = (state,action) => {
    return updateObject(state, { error: null, loading: false});
}

const deleteServiceAccountFail = (state,action) => {
    return updateObject(state, { error: action.error, loading: false });
}

const createSasTokenBegin = (state,action) => {
    return updateObject(state, { error: null, loading: true });
}

const createSasTokenSuccess = (state,action) => {
    return updateObject(state, { error: null, loading: false, generatedSasToken: action.sasToken});
}

const createSasTokenFail = (state,action) => {
    return updateObject(state, { error: action.error, loading: false });
}

const serviceAccountReducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.LOAD_SERVICEACCOUNT_BEGIN:
            return loadServiceAccountBegin(state,action);

        case actionTypes.LOAD_SERVICEACCOUNT_SUCCESS:
            return loadServiceAccountSuccess(state,action);

        case actionTypes.LOAD_SERVICEACCOUNT_FAIL:
            return loadServiceAccountFail(state,action);

        case actionTypes.ADDEDIT_SERVICEACCOUNT_BEGIN:
            return addEditServiceAccountBegin(state,action);

        case actionTypes.ADDEDIT_SERVICEACCOUNT_SUCCESS:
            return addEditServiceAccountSuccess(state,action);

        case actionTypes.ADDEDIT_SERVICEACCOUNT_FAIL:
            return addEditServiceAccountFail(state,action);
            
        case actionTypes.DELETE_SERVICEACCOUNT_BEGIN:
            return deleteServiceAccountBegin(state,action);
            
        case actionTypes.DELETE_SERVICEACCOUNT_SUCCESS:
            return deleteServiceAccountSuccess(state,action);
            
        case actionTypes.DELETE_SERVICEACCOUNT_FAIL:
            return deleteServiceAccountFail(state,action);
            
        case actionTypes.CREATE_SASTOKEN_BEGIN:
            return createSasTokenBegin(state,action);
            
        case actionTypes.CREATE_SASTOKEN_SUCCESS:
            return createSasTokenSuccess(state,action);
            
        case actionTypes.CREATE_SASTOKEN_FAIL:
            return createSasTokenFail(state,action);

        default:
            return state;
    }
}

export default serviceAccountReducer;
  