import React from "react";
import { connect } from "react-redux";
import { Column } from "devextreme-react/data-grid";
import PromineoGrid from "../Common/Controls/PromineoGridComponent";
import * as actions from "../../store/actions/communityDashboardActions";

class CommunityDashboardGrid extends React.Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.community &&
      (!prevProps.community ||
        this.props.community.id !== prevProps.community.id)
    ) {
      this.props.onLoadCommunityDashboards(this.props.community);
    }
  }

  handleSelectionChanged = selectedData => {
    if (selectedData && selectedData.selectedRowsData &&  selectedData.selectedRowsData.length > 0) {
      this.props.handleDashboardSelectionChanged(selectedData.selectedRowsData[0]);
    }
  };

  render() {
    const { dashboards } = this.props;
    return (
      <PromineoGrid
        allowMultiSelect={false}
        keyExpression={"id"}
        enablePaging={true}
        dataSource={dashboards}
        displayFilterRow={true}
        onSelectionChanged={this.handleSelectionChanged}
      >
        <Column dataField={"title"} width={"30%"} />
        <Column dataField={"description"} width={"70%"} />
      </PromineoGrid>
    );
  }
}

const mapStateToProps = state => {
  return {
    dashboards: state.communityData.availableDashboards,
    displayModalForPeriodizer: state.communityData.dashboardPeriodizerInfoModalDisplay
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLoadCommunityDashboards: community =>
      dispatch(actions.loadCommunityDashboards(community)),
    handleDashboardSelectionChanged: selectedDashboards =>
      dispatch(actions.dashboardSelectionChanged(selectedDashboards))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunityDashboardGrid);
