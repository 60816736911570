import React from "react";
import { Offset, Position } from "devextreme-react/popup";
import PromineoPopover from "../../Common/PromineoPopover";
import { withRouter } from "react-router-dom";

export function SubNavMenuPopover(props) {
  const handleMenuItemClick = (selectedItem) => {
    if (selectedItem.route) {
      props.history.push(selectedItem.route);
    }

    if (props.onSelect) {
      props.onSelect(selectedItem);
    }
  };

  const handleSubMenueHiding = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  const Content = () => {
    return (
      <>
        {(props.menuItems || []).map((item) => {
          return (
            <div
              className="block cursor-pointer py-1 pl-2 pr-16 font-medium hover:bg-gray-4 rounded-sm"
              onClick={() => handleMenuItemClick(item)}
              key={item.itemId}
            >
              {item.text}
            </div>
          );
        })}
      </>
    );
  };

  return (
    <PromineoPopover
      width={"auto"}
      height={"auto"}
      visible={true}
      hideOnOutsideClick={true}
      onHiding={handleSubMenueHiding}
      target={props.target}
    >
      <Position my={{ x: "center", y: "top" }}>
        <Offset y={props.yOffset} x={0} />
      </Position>
      <Content />
    </PromineoPopover>
  );
}

export default withRouter(SubNavMenuPopover);
