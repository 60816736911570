import React from "react";
import { connect } from "react-redux";
import WikiViewer from "./WikiViewerComponent";
import * as actions from "../../store/actions/wikiActions";

class WikiLink extends React.Component {
  componentDidMount() {
    this.props.onLoadWikiLink();
  }

  render() {
    const {loadingLinkInfoDone, wiki} = this.props;

    return (
      <div>{loadingLinkInfoDone ? <WikiViewer wiki={wiki}/> : <div>Loading...</div>}</div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loadingLinkInfoDone: state.wikiData.loadingLinkInfoDone,
    wiki: state.wikiData.wikiForLink
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onLoadWikiLink: () =>
      dispatch(actions.loadWikiLink(props.match.params.articleId))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WikiLink);
