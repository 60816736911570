export const ADDEDIT_LEAVE_BEGIN = "ADDEDIT_LEAVE_BEGIN";
export const ADDEDIT_LEAVE_SUCCESS = "ADDEDIT_LEAVE_SUCCESS";
export const ADDEDIT_LEAVE_FAIL = "ADDEDIT_LEAVE_FAIL";

export const LOAD_LEAVE_TYPES_BEGIN = "LOAD_LEAVE_TYPES_BEGIN";
export const LOAD_LEAVE_TYPES_SUCCESS = "LOAD_LEAVE_TYPES_SUCCESS";
export const LOAD_LEAVE_TYPES_FAIL = "LOAD_LEAVE_TYPES_FAIL";

export const LOAD_ACTIVE_LEAVE_REQUESTS_BEGIN = "LOAD_ACTIVE_LEAVE_REQUESTS_BEGIN";
export const LOAD_ACTIVE_LEAVE_REQUESTS_SUCCESS = "LOAD_ACTIVE_LEAVE_REQUESTS_SUCCESS";
export const LOAD_ACTIVE_LEAVE_REQUESTS_FAIL = "LOAD_ACTIVE_LEAVE_REQUESTS_FAIL";

export const DELETE_LEAVE_REQUEST_BEGIN = "DELETE_LEAVE_REQUEST_BEGIN";
export const DELETE_LEAVE_REQUEST_SUCCESS = "DELETE_LEAVE_REQUEST_SUCCESS";
export const DELETE_LEAVE_REQUEST_FAIL = "DELETE_LEAVE_REQUEST_FAIL";

export const LOAD_POSITIONS_BEGIN = "LOAD_POSITIONS_BEGIN";
export const LOAD_POSITIONS_SUCCESS = "LOAD_POSITIONS_SUCCESS";
export const LOAD_POSITIONS_FAIL = "LOAD_POSITIONS_FAIL";