import * as request from "../../shared/request";
import { toastError, toastSuccess } from "../../shared/Utility/toastUtility";
import * as actionTypes from "./ActionType/postProcessorActionTypes";

// Load TenantPostProcessor
export const loadTenantPostProcessorBegin = () => {
  return {
    type: actionTypes.LOAD_TENANT_POSTPROCESSOR_START,
  };
};

export const loadTenantPostProcessorFailed = (error) => {
  return {
    type: actionTypes.LOAD_TENANT_POSTPROCESSOR_FAILED,
    error,
  };
};

export const loadTenantPostProcessorSuccess = (data) => {
  return {
    type: actionTypes.LOAD_TENANT_POSTPROCESSOR_SUCCESS,
    payload: data,
  };
};

export const loadTenantsPostProcessors = () => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(loadTenantPostProcessorBegin());

    const loadRequest = request.apiRequest(
      {
        url: "/postprocessors",
        method: "GET",
      },
      state.authData
    );

    if (loadRequest) {
      return loadRequest
        .then((response) => {
          dispatch(loadTenantPostProcessorSuccess(response.data));
        })
        .catch((error) => {
          dispatch(loadTenantPostProcessorFailed(error));
          throw error;
        });
    }
  };
};

// Load PortalPostProcessor

export const loadPortalPostProcessorBegin = () => {
  return {
    type: actionTypes.LOAD_PORTAL_POSTPROCESSOR_START,
  };
};

export const loadPortalPostProcessorFailed = (error) => {
  return {
    type: actionTypes.LOAD_PORTAL_POSTPROCESSOR_FAILED,
    error,
  };
};

export const loadPortalPostProcessorSuccess = (data) => {
  return {
    type: actionTypes.LOAD_PORTAL_POSTPROCESSOR_SUCCESS,
    payload: data,
  };
};

export const loadPortalsPostProcessors = () => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(loadPortalPostProcessorBegin());

    const loadRequest = request.portalRequest(
      {
        url: "/postprocessors",
        method: "GET",
      },
      state.authData
    );

    if (loadRequest) {
      return loadRequest
        .then((response) => {
          dispatch(loadPortalPostProcessorSuccess(response.data));
        })
        .catch((error) => {
          dispatch(loadPortalPostProcessorFailed(error));
          throw error;
        });
    }
  };
};

// Add Postprocessor to tenant

export const addPostProcessorBegin = () => {
  return {
    type: actionTypes.ADD_POSTPROCESSOR_START,
  };
};

export const addPostProcessorFailed = (error) => {
  return {
    type: actionTypes.ADD_POSTPROCESSOR_FAILED,
    error,
  };
};

export const addPostProcessorSuccess = (data) => {
  return {
    type: actionTypes.ADD_POSTPROCESSOR_SUCCESS,
    payload: data,
  };
};

export const addPostProcessor = (postProcessorToAdd) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(addPostProcessorBegin());

    const postRequest = request.apiRequest(
      {
        url: "/postprocessors",
        method: "POST",
        data: postProcessorToAdd,
      },
      state.authData
    );

    if (postRequest) {
      return postRequest
        .then((response) => {
          dispatch(addPostProcessorSuccess(response.data));
          toastSuccess("Added Post processor successfully.");
        })
        .catch((error) => {
          dispatch(addPostProcessorFailed(error));
          toastError("Failed to add Post processor.");
          throw error;
        });
    }
  };
};

// Update Postprocessor fom tenant

export const updatePostProcessorBegin = () => {
  return {
    type: actionTypes.UPDATE_POSTPROCESSOR_START,
  };
};

export const updatePostProcessorFailed = (error) => {
  return {
    type: actionTypes.UPDATE_POSTPROCESSOR_FAILED,
    error,
  };
};

export const updatePostProcessorSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_POSTPROCESSOR_SUCCESS,
    payload: data,
  };
};

export const updatePostProcessor = (postProcessorId, postProcessorToUpdate) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(addPostProcessorBegin());

    const putRequest = request.apiRequest(
      {
        url: `/postprocessors/${postProcessorId}`,
        method: "PUT",
        data: postProcessorToUpdate,
      },
      state.authData
    );

    if (putRequest) {
      return putRequest
        .then((response) => {
          dispatch(updatePostProcessorSuccess(response.data));
          toastSuccess("Updated Post processor successfully.");
        })
        .catch((error) => {
          dispatch(updatePostProcessorFailed(error));
          toastError("Failed to update Post processor");
          throw error;
        });
    }
  };
};

// Delete Postprocessor fom tenant

export const deletePostProcessorBegin = () => {
  return {
    type: actionTypes.DELETE_POSTPROCESSOR_START,
  };
};

export const deletePostProcessorFailed = (error) => {
  return {
    type: actionTypes.DELETE_POSTPROCESSOR_FAILED,
    error,
  };
};

export const deletePostProcessorSuccess = (data) => {
  return {
    type: actionTypes.DELETE_POSTPROCESSOR_SUCCESS,
    payload: data,
  };
};

export const deletePostProcessor = (postProcessorId) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(deletePostProcessorBegin());

    const deleteRequest = request.apiRequest(
      {
        url: `/postprocessors/${postProcessorId}`,
        method: "DELETE",
      },
      state.authData
    );

    if (deleteRequest) {
      return deleteRequest
        .then((response) => {
          toastSuccess("Deleted Post processor successfully.");
          return dispatch(deletePostProcessorSuccess(postProcessorId));
        })
        .catch((error) => {
          toastError(`Error: ${error.data}`);
          dispatch(deletePostProcessorFailed(error));
          throw error;
        });
    }
  };
};
