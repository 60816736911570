import React from "react";
import PromineoTab from "../Common/Controls/PromineoTabComponent";
import { Item } from "devextreme-react/tab-panel";
import CommunityFieldUploadComponent from "./CommunityFieldUploadComponent";
import CommunityDataSourceUpload from "./CommunityDataSourceUploadComponent";
import CommunityDashboardUpload from "./CommunityDashboardUploadComponent";

const CommunityUploads = () => (
  <div className="container pt-3">
    <div className="row">
      <div className="col">
        <PromineoTab>
          <Item title={"Fields"} component={CommunityFieldUploadComponent} />
          <Item title={"Data Sources"} component={CommunityDataSourceUpload} />
          <Item title={"Dashboards"} component={CommunityDashboardUpload} />
        </PromineoTab>
      </div>
    </div>
  </div>
);

export default CommunityUploads;
