import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ListItemAction from "../Common/ListItemActionComponent";
import { getApiCompatibleModelType } from "../../shared/Utility/dataUtility";
import * as actions from "../../store/actions/wikiActions";
import { hasDataAccess } from "../../shared/Utility/commonUtility";
import { accessBits } from "../../shared/Enums/accessBits";

const EditTag = (props) => {
  var tag = props.data;
  props.history.push({
    pathname: "/tagEditor",
    state: {
      tag: {
        id: tag.PrimKey,
        title: tag.Title,
        description: tag.Description,
        tagType: tag.TagType,
      },
      mode: "E",
    },
  });
};

const DeleteTag = (data) => {
  const tagDeleteData = [
    {
      $type: getApiCompatibleModelType("Support", "Tag"),
      PrimKey: data.data.PrimKey,
      RecordState: 3,
    },
  ];

  if (window.confirm("Are you sure to delete this tag?")) {
    data.onDeleteTag(tagDeleteData);
  }
};

const hasRightsForPublicTags = (data) => {
  let hasAccess = true;
  if (data.TagType === "Public") {
    hasAccess = data.isPortalAdmin;
  }

  return hasAccess;
};

const TagListItem = (data) => {
  const dataItem = data.data;
  return (
    <div className="container">
      <div className="row border-bottom">
        <div className="col-sm-10">
          <h6>{dataItem.Title}</h6>
          <p>{dataItem.Description}</p>
        </div>
        <div className="col-sm-2 float-right">
          <ListItemAction
            displayEditButton={
              hasDataAccess(data.dataAccess, "Wiki", accessBits.Update) &&
              hasRightsForPublicTags(data)
            }
            displayDeleteButton={
              hasDataAccess(data.dataAccess, "Wiki", accessBits.Delete) &&
              hasRightsForPublicTags(data)
            }
            onEdit={() => {
              EditTag(data);
            }}
            onDelete={() => {
              DeleteTag(data);
            }}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataAccess: state.authData.dataAccess,
    isPortalAdmin: state.authData.isPortalAdmin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteTag: (tag) => dispatch(actions.deleteTag(tag)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TagListItem)
);
