import * as BinaryOperatorCriteria from "./binaryOperatorCriteria";
import * as BinaryCriteria from "./binaryCriteria";
import * as ExistsCriteria from "./existsCriteria";
import * as InCriteria from "./inCriteria";

export const getEqualsCriteria = (fieldName, compareValue) => {
  return BinaryOperatorCriteria.create(fieldName, compareValue, "=");
};

export const getBinaryOperatorCriteria = (leftOperand, rightOperand, operator) => {
  return BinaryOperatorCriteria.create(leftOperand, rightOperand, operator);
};

export const getLikeCriteria = (fieldName, compareValue) => {
  return BinaryOperatorCriteria.create(fieldName, compareValue, "LIKE");
};

export const getAndCriteria = (leftOperand, rightOperand) => {
  return BinaryCriteria.create(leftOperand, rightOperand, "AND");
};

export const getOrCriteria = (leftOperand, rightOperand) => {
  return BinaryCriteria.create(leftOperand, rightOperand, "OR");
};

export const getExistCriteria = (tableInfo, foreignKeyField, primaryKeyField, condition) => {
 return ExistsCriteria.create(tableInfo, foreignKeyField, primaryKeyField, condition)
};

export const getInCriteria = (dataType, fieldName, values) => {
  return InCriteria.create(dataType, fieldName, values)
}

export const getGreaterThanOrEqualCriteria = (fieldName, compareValue) => {
  return getOrCriteria(
    getBinaryOperatorCriteria(
      fieldName,
      compareValue,
      ">"
    ),
    getEqualsCriteria(
      fieldName,
      compareValue
    )
  );
}