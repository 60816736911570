import * as dataUtility from "../Utility/dataUtility";

export const create = (fieldName, compareValue, operator) => {
    let criteria = {
      __type: "PData.Sql.",
      data: {
        f: fieldName,
        o: operator,
        c: {
          __type: dataUtility.getApiCompatibleDataType(compareValue),
          data: compareValue
        }
      }
    };
  
    switch (operator) {
      case "=":
        criteria.__type = criteria.__type + "EqualsCriteria";
        break;
      case ">":
      case "<":
        criteria.__type = criteria.__type + "BinaryOperandCriteria";
        break;
      case "LIKE":
        criteria.__type = criteria.__type + "LikeCriteria";
        break;
      default:
        break;
    }
  
    return criteria;
  };