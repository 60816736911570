import React, { useEffect } from "react";
import { connect } from "react-redux";
import { TextBox } from "devextreme-react/text-box";
import * as actions from "../../store/actions/wikiActions";

const WikiSearch = props => {
  const { searchText, loadWikis, setSearchText } = props;

  useEffect(() => {
    loadWikis(searchText);
  });

  return (
    <div className="container">
      <div className="row">
        <div className="col-2">
          <h4>Search </h4>
        </div>
        <div className="col-10">
          <TextBox value={searchText} onValueChanged={setSearchText} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    searchText: state.wikiData.searchText
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSearchText: e => dispatch(actions.setSearchText(e.value)),
    loadWikis: searchText => dispatch(actions.loadWikis(searchText))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WikiSearch);
